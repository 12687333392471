// src/config/apiConfig.js
const BASE_URL = 'https://api.lodgeic.io/v1';
// const BASE_URL = 'http://localhost:7071/api';

const API_ENDPOINTS = {
    GET_ORG_CONFIG: `${BASE_URL}/orgConfig`,
    GET_REPORTS: (workspaceId) => `${BASE_URL}/pbiReports?workspaceId=${workspaceId}`,
    GET_REPORT_PAGES: (reportId) => `${BASE_URL}/pbiReportPages?reportId=${reportId}`,
    GET_DATASETS: (workspaceId) => `${BASE_URL}/pbiDatasets?workspaceId=${workspaceId}`,
    GET_EMBED_TOKEN: `${BASE_URL}/pbiEmbedToken`,
    GET_DASHBOARDS: (workspaceId) => `${BASE_URL}/pbiDashboards?workspaceId=${workspaceId}`,
    GET_CHARTOFACCOUNTS: `${BASE_URL}/chartOfAccounts`,
    GET_PDF_PROXY: `${BASE_URL}/pbiPdfProxy`,
    GET_USER_SETTINGS: (email) => `${BASE_URL}/userSettings?id=${email}`,
    GET_SENDBIRD_TOKEN: `${BASE_URL}/sendbirdToken`,
    UPSERT_SENDBIRD_USERS: `${BASE_URL}/sendbirdUpsertUsers`,
    PBI_FILTER_STATE: `${BASE_URL}/pbiFilterState`,
    GET_PBI_FILTER_STATE: (stateId) => `${BASE_URL}/pbiFilterState/${stateId}`
    // GET_USER
};

export default API_ENDPOINTS;
export { BASE_URL };
