// UserManagementTest.js
// This file contains tests to verify that the sendbirdUpsertUsers API is properly called
// when adding or syncing users in the admin portal.

import { saveUserSettings, syncAllUsersWithSendbird, upsertSendbirdUser } from '../../utils/api';

/**
 * Test function to verify that adding a new user properly calls the sendbirdUpsertUsers API
 */
async function testAddNewUser() {
  console.log('=== Testing Add New User ===');
  
  // Create a test user
  const testUser = {
    email: `test-user-${Date.now()}@lodgeic.com`,
    firstName: 'Test',
    lastName: 'User',
    isAdmin: 0,
    isDeleted: 0,
    orgAccess: {
      'test-org-id': {
        reportIds: {},
        dashboardIds: [],
        entityIds: [],
        dashboards: [],
        reports: [],
        entities: []
      }
    }
  };
  
  try {
    // Save the user
    console.log(`Saving test user: ${testUser.email}`);
    const savedUser = await saveUserSettings(testUser);
    console.log('User saved successfully:', savedUser);
    
    // Verify that the user was saved and the Sendbird API was called
    if (savedUser) {
      console.log('✅ User was saved successfully');
      console.log('✅ upsertSendbirdUser should have been called internally by saveUserSettings');
    } else {
      console.error('❌ Failed to save user');
    }
  } catch (error) {
    console.error('❌ Error saving user:', error);
  }
}

/**
 * Test function to verify that syncing all users properly calls the sendbirdUpsertUsers API
 */
async function testSyncAllUsers() {
  console.log('\n=== Testing Sync All Users ===');
  
  try {
    // Sync all users
    console.log('Syncing all users with Sendbird');
    const result = await syncAllUsersWithSendbird();
    
    // Verify that the sync was successful
    if (result) {
      console.log('✅ Sync all users was successful');
      console.log('Sync result:', result);
    } else {
      console.error('❌ Failed to sync all users');
    }
  } catch (error) {
    console.error('❌ Error syncing all users:', error);
  }
}

/**
 * Test function to verify that directly upserting a user properly calls the sendbirdUpsertUsers API
 */
async function testDirectUpsertUser() {
  console.log('\n=== Testing Direct Upsert User ===');
  
  // Create a test user
  const testUser = {
    email: `direct-test-user-${Date.now()}@lodgeic.com`,
    firstName: 'Direct',
    lastName: 'Test',
    isAdmin: 0,
    isDeleted: 0
  };
  
  try {
    // Directly upsert the user in Sendbird
    console.log(`Directly upserting test user: ${testUser.email}`);
    const result = await upsertSendbirdUser(testUser);
    
    // Verify that the upsert was successful
    if (result) {
      console.log('✅ Direct upsert was successful');
      console.log('Upsert result:', result);
    } else {
      console.error('❌ Failed to directly upsert user');
    }
  } catch (error) {
    console.error('❌ Error directly upserting user:', error);
  }
}

/**
 * Run all tests
 */
async function runAllTests() {
  console.log('Starting Sendbird API integration tests...');
  
  await testAddNewUser();
  await testSyncAllUsers();
  await testDirectUpsertUser();
  
  console.log('\nAll tests completed.');
}

// Export the test functions
export {
  testAddNewUser,
  testSyncAllUsers,
  testDirectUpsertUser,
  runAllTests
};

export default runAllTests; 