import React, { useState, useEffect } from 'react';
import { Mail, Save } from 'lucide-react';
import './settings.css';

const EmailSettings = () => {
  const [emailPreferences, setEmailPreferences] = useState({
    dailyDigest: true,
    weeklyReport: true,
    securityAlerts: true,
    marketingEmails: false
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Check if device is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (key) => {
    setEmailPreferences(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  // Mobile-optimized descriptions
  const descriptions = {
    dailyDigest: "Receive daily digest notifications",
    weeklyReport: "Receive weekly report notifications",
    securityAlerts: "Receive security alert notifications",
    marketingEmails: "Receive marketing email notifications"
  };

  return (
    <div className="settings-container">
      {!isMobile && (
        <div className="settings-header">
          <div className="settings-header-icon bg-orange-500/10">
            <Mail className="w-6 h-6 text-orange-500" />
          </div>
          <div>
            <h1 className="settings-title">Email Preferences</h1>
            <p className="settings-subtitle">Manage how you receive email notifications</p>
          </div>
        </div>
      )}

      <div className="settings-card">
        {Object.entries(emailPreferences).map(([key, value]) => (
          <div key={key} className="flex items-center justify-between py-3">
            <div>
              <h3 className="text-gray-900 dark:text-white font-medium capitalize text-sm md:text-base">
                {key.replace(/([A-Z])/g, ' $1').trim()}
              </h3>
              <p className="text-gray-500 dark:text-gray-400 text-xs md:text-sm">
                {descriptions[key]}
              </p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={value}
                onChange={() => handleChange(key)}
              />
              <div className="w-10 md:w-11 h-5 md:h-6 bg-gray-200 dark:bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange-500/20 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 md:after:h-5 after:w-4 md:after:w-5 after:transition-all peer-checked:bg-orange-500"></div>
            </label>
          </div>
        ))}

        {!isMobile && (
          <div className="settings-divider">
            <button className="settings-button">
              <Save className="w-4 h-4" />
              Save Changes
            </button>
          </div>
        )}
      </div>
      
      {isMobile && (
        <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200">
          <button className="w-full py-3 rounded-xl font-semibold text-white transition-all shadow-lg bg-orange-500">
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default EmailSettings; 