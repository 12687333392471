import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import * as pbi from 'powerbi-client';
import { getReports, getOrgConfig, getEmbedToken, fetchPdfFromBlob } from "../utils/api";
import { useContainerSize } from "../hooks/useContainerSize";
import "./Reports.css";
import { useLoading } from "../context/LoadingContext";
import { useUserSettingsContext } from "../context/UserSettingsContext";
import PDFModal from "./PDFModal";
import ReportFilterShare from "./Report/ReportFilterShare";
import { applyReportFilterState } from "../utils/pbiFilterShare";

function Report() {
  const { reportId, pageName: urlPageName } = useParams();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [powerbi, setPowerbi] = useState(null);
  const [reportInstance, setReportInstance] = useState(null);
  const [workspaceId, setWorkspaceId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const { height, width } = useContainerSize();
  // const apiUrl = process.env.REACT_APP_API_BASE_URL || 'https://lodgeic-azf01.azurewebsites.net/api';
  const [isLoaded, setIsLoaded] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  const [startY, setStartY] = useState(null);
  const [pullDistance, setPullDistance] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [refreshError, setRefreshError] = useState(null);
  const [toolbarPosition, setToolbarPosition] = useState({ x: 'right', y: 'top' });
  const [isDragging, setIsDragging] = useState(false);
  const refreshThreshold = 100; // pixels to pull before refresh triggers
  const reportContainerRef = useRef(null);
  const toolbarRef = useRef(null);
  const refreshTimeoutRef = useRef(null);
  const errorTimeoutRef = useRef(null);
  const dragStartRef = useRef({ x: 0, y: 0 });
  const offsetRef = useRef({ x: 0, y: 0 });
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [pdfError, setPdfError] = useState(null);
  const [currentEmbedToken, setCurrentEmbedToken] = useState(null);
  const { userSettings } = useUserSettingsContext();
  const [reportName, setReportName] = useState('');
  const [filterStateId, setFilterStateId] = useState(null);

  const isMobileDevice = () => {
    return window.innerWidth <= 768; // You can adjust this breakpoint
  };

  useEffect(() => {
    const loadId = 'report-load';
    startLoading(loadId);
    
    const pbiService = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    setPowerbi(pbiService);

    const fetchWorkspaceId = async () => {
      try {
        // Get the current organization ID and workspaceId from localStorage
        const currentOrgId = localStorage.getItem('currentOrgId');
        let workspaceId = localStorage.getItem('currentOrgWorkspaceId');
        
        if (!currentOrgId || !userSettings || !userSettings.orgAccess) {
          console.error('No current organization ID or user settings available');
          stopLoading(loadId);
          setError("No organization selected or user settings not available");
          return;
        }
        
        // Check if the current org exists in the user's orgAccess
        if (!userSettings.orgAccess[currentOrgId]) {
          console.error('Current organization not found in user access');
          stopLoading(loadId);
          setError("Current organization not found in user access");
          return;
        }
        
        // Get the organization's access details
        const orgAccess = userSettings.orgAccess[currentOrgId];
        
        // Check if the user has access to the current report
        if (!reportId || !orgAccess.reportIds || !orgAccess.reportIds[reportId]) {
          console.error('No access to this report');
          stopLoading(loadId);
          setError("You don't have access to this report");
          return;
        }
        
        // If workspaceId is not in localStorage, fetch it from orgConfig
        if (!workspaceId) {
          const orgConfig = await getOrgConfig();
          
          // Find the matching organization in orgConfig using orgId field
          const selectedOrgConfig = orgConfig.find(config => config.orgId === currentOrgId);
          
          if (!selectedOrgConfig) {
            console.error(`Organization ${currentOrgId} not found in orgConfig`);
            stopLoading(loadId);
            setError(`Organization ${currentOrgId} not found in configuration`);
            return;
          }
          
          workspaceId = selectedOrgConfig.workspaceId;
          
          // Store the workspaceId for future use
          localStorage.setItem('currentOrgWorkspaceId', workspaceId);
        }
        
        setWorkspaceId(workspaceId);
      } catch (error) {
        console.error('Error fetching workspace ID:', error);
        stopLoading(loadId);
      }
    };

    fetchWorkspaceId();
    
    return () => stopLoading(loadId);
  }, [reportId, userSettings]);

  // Function to handle report refresh using the correct Power BI method
  const handleRefresh = async () => {
    if (!reportInstance || isRefreshing) return;
    
    // Clear any existing error
    setRefreshError(null);
    
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
      errorTimeoutRef.current = null;
    }
    
    setIsRefreshing(true);
    console.log('Refreshing report data...');
    
    try {
      // Use the correct Power BI refresh method
      await reportInstance.refresh();
      console.log('Report refresh completed');
      
      // Reset refreshing state after a short delay to show the animation
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
      
      refreshTimeoutRef.current = setTimeout(() => {
        setIsRefreshing(false);
      }, 1000);
      
    } catch (error) {
      console.error('Error refreshing report:', error);
      setIsRefreshing(false);
      
      // Display the error to the user
      const errorMessage = error.detailedMessage || error.message || 'An error occurred while refreshing the report';
      setRefreshError(errorMessage);
      
      // Auto-dismiss error after 5 seconds
      errorTimeoutRef.current = setTimeout(() => {
        setRefreshError(null);
      }, 5000);
    }
  };

  // Completely rewritten drag handling logic for more reliable operation
  const handleDragStart = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!toolbarRef.current || !reportContainerRef.current) return;
    
    // Get the initial position
    const clientX = e.clientX || (e.touches && e.touches[0].clientX);
    const clientY = e.clientY || (e.touches && e.touches[0].clientY);
    
    if (!clientX || !clientY) return;
    
    // Store initial mouse/touch position
    dragStartRef.current = { x: clientX, y: clientY };
    
    // Calculate the offset from the mouse position to the toolbar's top-left corner
    const toolbarRect = toolbarRef.current.getBoundingClientRect();
    offsetRef.current = {
      x: clientX - toolbarRect.left,
      y: clientY - toolbarRect.top
    };
    
    // Add dragging class for visual feedback
    toolbarRef.current.classList.add('dragging');
    
    // Set up event handlers for move and end
    if (e.type === 'mousedown') {
      document.addEventListener('mousemove', handleDragMove);
      document.addEventListener('mouseup', handleDragEnd);
    } else if (e.type === 'touchstart') {
      document.addEventListener('touchmove', handleDragMove, { passive: false });
      document.addEventListener('touchend', handleDragEnd);
    }
    
    setIsDragging(true);
  }, []);

  const handleDragMove = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!toolbarRef.current || !reportContainerRef.current || !isDragging) return;
    
    // Get current mouse/touch position
    const clientX = e.clientX || (e.touches && e.touches[0].clientX);
    const clientY = e.clientY || (e.touches && e.touches[0].clientY);
    
    if (!clientX || !clientY) return;
    
    // Get container dimensions
    const containerRect = reportContainerRef.current.getBoundingClientRect();
    const toolbarRect = toolbarRef.current.getBoundingClientRect();
    
    // Calculate new position (accounting for the offset within the toolbar)
    let newX = clientX - offsetRef.current.x;
    let newY = clientY - offsetRef.current.y;
    
    // Constrain to container boundaries with some padding
    const padding = 8;
    newX = Math.max(containerRect.left + padding, Math.min(newX, containerRect.right - toolbarRect.width - padding));
    newY = Math.max(containerRect.top + padding, Math.min(newY, containerRect.bottom - toolbarRect.height - padding));
    
    // Apply the new position directly to the DOM for smoother movement
    toolbarRef.current.style.position = 'absolute';
    toolbarRef.current.style.left = `${newX - containerRect.left}px`;
    toolbarRef.current.style.top = `${newY - containerRect.top}px`;
    toolbarRef.current.style.right = 'auto';
    toolbarRef.current.style.bottom = 'auto';
    
    // Remove position classes that might interfere
    toolbarRef.current.classList.remove('left-top', 'right-top', 'left-bottom', 'right-bottom');
  }, [isDragging]);

  const handleDragEnd = useCallback((e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (!toolbarRef.current || !reportContainerRef.current) {
      setIsDragging(false);
      return;
    }
    
    // Remove dragging class
    toolbarRef.current.classList.remove('dragging');
    
    // Get container and toolbar dimensions
    const containerRect = reportContainerRef.current.getBoundingClientRect();
    const toolbarRect = toolbarRef.current.getBoundingClientRect();
    
    // Calculate the center position of the toolbar
    const toolbarCenterX = toolbarRect.left + toolbarRect.width / 2;
    const toolbarCenterY = toolbarRect.top + toolbarRect.height / 2;
    
    // Determine which edge to snap to
    const distToLeft = toolbarCenterX - containerRect.left;
    const distToRight = containerRect.right - toolbarCenterX;
    const distToTop = toolbarCenterY - containerRect.top;
    const distToBottom = containerRect.bottom - toolbarCenterY;
    
    // Find the closest edge
    const minHorizDist = Math.min(distToLeft, distToRight);
    const minVertDist = Math.min(distToTop, distToBottom);
    
    // Determine final position
    let newPosition = { x: 'left', y: 'top' };
    
    if (minHorizDist === distToRight) {
      newPosition.x = 'right';
    }
    
    if (minVertDist === distToBottom) {
      newPosition.y = 'bottom';
    }
    
    // Reset inline styles
    toolbarRef.current.style.position = '';
    toolbarRef.current.style.left = '';
    toolbarRef.current.style.top = '';
    toolbarRef.current.style.right = '';
    toolbarRef.current.style.bottom = '';
    
    // Apply the new position class
    setToolbarPosition(newPosition);
    
    // Remove event listeners
    document.removeEventListener('mousemove', handleDragMove);
    document.removeEventListener('mouseup', handleDragEnd);
    document.removeEventListener('touchmove', handleDragMove);
    document.removeEventListener('touchend', handleDragEnd);
    
    setIsDragging(false);
  }, [isDragging]);

  // Clean up timeouts and event listeners on unmount
  useEffect(() => {
    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragEnd);
    };
  }, [handleDragMove, handleDragEnd]);

  useEffect(() => {
    async function bootstrapReport() {
      if (!workspaceId || !reportId || !powerbi) return;

      setIsLoaded(false);

      try {
        const reports = await getReports(workspaceId);
        const report = reports.value.find(r => r.id === reportId);
        
        if (!report) {
          throw new Error(`Report with ID ${reportId} not found`);
        }

        const reportContainer = document.getElementById('reportContainer');
        if (reportContainer) {
          powerbi.reset(reportContainer);
          
          const bootstrapConfig = {
            type: 'report',
            id: report.id,
            embedUrl: report.embedUrl,
            accessToken: undefined,
            settings: {
              navContentPaneEnabled: false,
              filterPaneEnabled: true,
              ...(isMobileDevice() && {
                layoutType: pbi.models.LayoutType.MobilePortrait,
                mobileLayout: true
              })
            }
          };

          console.log('Bootstrapping report...');
          const bootstrappedReport = powerbi.bootstrap(reportContainer, bootstrapConfig);
          setReportInstance(bootstrappedReport);

          const embedTokenResponse = await getEmbedToken(report.id, workspaceId, {
            embedDetails: { accessLevel: 'View' },
            reportId: report.id,
            workspaceId: workspaceId,
            type: 'reports'
          });

          const embedToken = await embedTokenResponse;
          setCurrentEmbedToken(embedToken);

          const embedConfig = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: embedToken,
            embedUrl: report.embedUrl,
            pageView: "fitToWidth",
            id: report.id,
            pageName: urlPageName,
            permissions: pbi.models.Permissions.All,
            viewMode: pbi.models.ViewMode.View,
            settings: {
              DisplayOption: pbi.models.DisplayOption.FitToWidth,
              ...(isMobileDevice() && {
                layoutType: pbi.models.LayoutType.MobilePortrait
              }),
              filterPaneEnabled: true,
              navContentPaneEnabled: false,
              hyperlinkClickBehavior: 2  // 2 represents "Prevent" in Power BI's enum
            }
          };

          const newReport = powerbi.embed(reportContainer, embedConfig);
          setReportInstance(newReport);

          newReport.on('loaded', () => {
            console.log('Report loaded successfully');
            setIsLoaded(true);
            setIsRefreshing(false);
            
            // Update settings with the numeric enum value
            newReport.updateSettings({
              hyperlinkClickBehavior: 2  // 2 represents "Prevent" in Power BI's enum
            }).then(() => {
              console.log('Hyperlink settings updated successfully');
            }).catch(error => {
              console.error('Error updating hyperlink settings:', error);
            });

            newReport.getPages().then((pages) => {
              console.log('Report pages:', pages);
            });
          });

          newReport.on('dataHyperlinkClicked', async (event) => {
            try {
              console.log('Hyperlink event triggered:', {
                event: event,
                detail: event.detail,
                url: event?.detail?.url,
                timestamp: new Date().toISOString()
              });
              
              if (!event || !event.detail) {
                console.warn('Invalid hyperlink event received');
                return;
              }
              
              const url = event.detail.url;
              console.log('Processing hyperlink URL:', url);
              
              if (url && url.toLowerCase().includes('.pdf')) {
                console.log('PDF link detected, attempting to show in modal');
                setIsLoadingPdf(true);
                setPdfError(null);
                
                try {
                  // Pass the embed token to the fetch function
                  const pdfBlobUrl = await fetchPdfFromBlob(url, currentEmbedToken);
                  setPdfUrl(pdfBlobUrl);
                  event.handled = true;
                  return true;
                } catch (error) {
                  console.error('Error fetching PDF:', error);
                  setPdfError('Unable to display PDF in modal. Opening in new tab...');
                  return false;
                } finally {
                  setIsLoadingPdf(false);
                }
              } else {
                console.log('Non-PDF link, allowing default behavior');
                return false;
              }
            } catch (error) {
              console.error('Error in hyperlink handler:', error);
              setIsLoadingPdf(false);
              setPdfError('An unexpected error occurred.');
              return false;
            }
          });

          newReport.on('error', (event) => {
            console.error('Error loading report:', event.detail);
            console.log(`Error loading report: ${event.detail.message}`);
            setIsRefreshing(false);
          });
        }

        // Set report name
        setReportName(report.name);
      } catch (error) {
        console.error("Error embedding Power BI report:", error);
        console.log(error.message);
        setIsRefreshing(false);
      }
    }

    bootstrapReport();
  }, [reportId, workspaceId, powerbi]);

  useEffect(() => {
    if (!reportInstance || !urlPageName) return;

    async function navigateToPage() {
      try {
        console.log('Navigating to page:', urlPageName);
        await reportInstance.setPage(urlPageName);
        console.log('Successfully navigated to page:', urlPageName);
      } catch (error) {
        console.error('Error navigating to page:', error);
        console.log(`Failed to navigate to page: ${urlPageName}`);
      }
    }

    navigateToPage();
  }, [urlPageName, reportInstance]);

  useEffect(() => {
    return () => {
      if (reportInstance) {
        reportInstance.off('loaded');
        reportInstance.off('error');
      }
    };
  }, [reportInstance]);

  useEffect(() => {
    if (isLoaded) {
      stopLoading('report-load');
    }
  }, [isLoaded]);

  useEffect(() => {
    if (!reportInstance) return;

    let startY = 0;
    const refreshThreshold = 100;

    reportInstance.on('swipeStart', (event) => {
      console.log('PowerBI SwipeStart:', {
        fullEvent: event,
        detail: event.detail,
        timestamp: new Date().toISOString()
      });
      startY = event.detail?.swipeEvent?.startPosition?.y;
    });

    reportInstance.on('swipeEnd', (event) => {
      console.log('PowerBI SwipeEnd:', {
        fullEvent: event,
        detail: event.detail,
        timestamp: new Date().toISOString()
      });
      
      const endY = event.detail?.swipeEvent?.currentPosition?.y;
      if (startY !== undefined && endY !== undefined) {
        const pullDistance = endY - startY;
        
        console.log('Swipe Distance:', {
          endY,
          startY,
          pullDistance,
          willRefresh: pullDistance >= refreshThreshold
        });

        if (pullDistance >= refreshThreshold) {
          console.log('Triggering report refresh...');
          handleRefresh();
        }
      }
    });

    return () => {
      reportInstance.off('swipeStart');
      reportInstance.off('swipeEnd');
    };
  }, [reportInstance]);

  // Check URL for filter state ID
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const stateId = searchParams.get('filterState');
    if (stateId) {
      setFilterStateId(stateId);
    }
  }, [location.search]);
  
  // Apply filter state if present in URL
  useEffect(() => {
    if (filterStateId && reportInstance && isLoaded) {
      const applyFilters = async () => {
        try {
          console.log(`Attempting to apply filter state: ${filterStateId} to report ${reportId}`);
          
          // In case there's an in-progress refresh, wait a bit
          if (isRefreshing) {
            console.log('Report is currently refreshing, waiting before applying filters...');
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
          
          // Make sure we don't attempt this too early
          await new Promise(resolve => setTimeout(resolve, 1000));
          
          try {
            // Apply the filter state
            const filterState = await applyReportFilterState(reportInstance, filterStateId);
            console.log('Filter state applied successfully:', filterState);
            
            // Show a success notification
            const successEl = document.createElement('div');
            successEl.className = 'fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded z-50';
            successEl.innerHTML = `
              <p class="font-semibold">Filters applied successfully</p>
              <p class="text-sm">Viewing report with shared filters</p>
            `;
            document.body.appendChild(successEl);
            
            // Remove success message after 3 seconds
            setTimeout(() => {
              if (document.body.contains(successEl)) {
                document.body.removeChild(successEl);
              }
            }, 3000);
          } catch (filterError) {
            console.error('Error applying filter state:', filterError);
            
            // Try an alternative approach - just refresh the report
            try {
              console.log('Trying to refresh report after filter application failure');
              await reportInstance.refresh();
              console.log('Report refreshed successfully');
            } catch (refreshError) {
              console.error('Error refreshing report after filter failure:', refreshError);
            }
            
            // Show error notification
            const errorEl = document.createElement('div');
            errorEl.className = 'fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded z-50';
            errorEl.innerHTML = `
              <p class="font-semibold">Error applying filters</p>
              <p class="text-sm">${filterError.message || 'Unknown error occurred'}</p>
            `;
            document.body.appendChild(errorEl);
            
            // Remove error message after 5 seconds
            setTimeout(() => {
              if (document.body.contains(errorEl)) {
                document.body.removeChild(errorEl);
              }
            }, 5000);
          }
          
          // Remove the filter state from URL after applying, regardless of success
          const newUrl = new URL(window.location.href);
          newUrl.searchParams.delete('filterState');
          window.history.replaceState({}, '', newUrl);
          
          // Clear the filter state ID
          setFilterStateId(null);
        } catch (error) {
          console.error('Unexpected error in filter application:', error);
        }
      };
      
      applyFilters();
    }
  }, [filterStateId, reportInstance, isLoaded, reportId, isRefreshing]);

  if (error) {
    return (
      <div className="content">
        <h3>Error loading report</h3>
        <p>{error}</p>
      </div>
    );
  }

  const reportContainerStyle = {
    position: 'relative',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    visibility: isLoaded ? 'visible' : 'hidden',
    transform: `translateY(${pullDistance}px)`,
    transition: pullDistance ? 'none' : 'transform 0.2s ease-out',
    touchAction: 'pan-x pan-y' // Allow scrolling but prevent zooming by default
  };

  // Determine toolbar class based on position
  const toolbarClass = `report-controls ${toolbarPosition.x}-${toolbarPosition.y} ${isDragging ? 'dragging' : ''}`;

  return (
    <>
      {isLoadingPdf && (
        <div className="fixed inset-0 bg-black/75 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent mx-auto"></div>
            <p className="mt-4 text-center text-gray-600">Loading PDF...</p>
          </div>
        </div>
      )}

      {pdfError && (
        <div className="fixed inset-x-0 top-4 flex justify-center z-50">
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{pdfError}</span>
            <button 
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => setPdfError(null)}
            >
              <span className="sr-only">Close</span>
              <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
              </svg>
            </button>
          </div>
        </div>
      )}

      {pdfUrl && (
        <PDFModal 
          url={pdfUrl} 
          onClose={() => {
            setPdfUrl(null);
            // Clean up the blob URL when closing the modal
            if (pdfUrl.startsWith('blob:')) {
              URL.revokeObjectURL(pdfUrl);
            }
          }} 
        />
      )}
      
      {pullDistance > 0 && (
        <div 
          style={{ 
            position: 'absolute', 
            top: '0', 
            left: '0', 
            right: '0',
            textAlign: 'center',
            padding: '10px',
            color: '#666',
            zIndex: 1000
          }}
        >
          {pullDistance >= refreshThreshold ? 'Release to refresh' : 'Pull down to refresh'}
        </div>
      )}
      
      {refreshError && (
        <div className="toast-alert">
          {refreshError}
        </div>
      )}
      
      <div 
        ref={reportContainerRef}
        id="reportContainer" 
        className="report-container report-content"
        style={reportContainerStyle}
      >
        {isLoaded && (
          <div 
            ref={toolbarRef}
            className={toolbarClass}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
            data-testid="report-toolbar"
            style={{ touchAction: 'none' }} // Prevent all touch actions on toolbar
          >
            <div className="drag-handle" title="Drag to reposition">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <button 
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering drag when clicking the button
                handleRefresh();
              }} 
              className={`report-refresh-icon ${isRefreshing ? 'refreshing' : ''}`}
              disabled={isRefreshing}
              title="Refresh data"
              aria-label="Refresh report data"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z"/>
                <path d="M12 5V2L8 6l4 4V7c3.31 0 6 2.69 6 6 0 2.97-2.17 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93 0-4.42-3.58-8-8-8zm-6 8c0-1.65.67-3.15 1.76-4.24L6.34 7.34A8.014 8.014 0 0 0 4 13c0 4.08 3.05 7.44 7 7.93v-2.02c-2.83-.48-5-2.94-5-5.91z"/>
              </svg>
            </button>
            {isLoaded && reportInstance && (
              <ReportFilterShare reportInstance={reportInstance} reportName={reportName} />
            )}
          </div>
        )}
      </div>

      <style jsx global>{`
        /* Prevent zooming on the entire app except for specific elements */
        html {
          touch-action: pan-x pan-y;
        }
        
        /* Allow zooming in Power BI iframe */
        #reportContainer iframe {
          touch-action: manipulation pinch-zoom;
          -webkit-overflow-scrolling: touch;
        }
        
        /* Prevent zooming on toolbar */
        .report-controls {
          touch-action: none;
        }
      `}</style>
    </>
  );
}

export default Report;