import React, { useState, useEffect } from 'react';
import { useSendbird } from '../../context/SendbirdContext';
import { getSendbirdToken, getUserSettings } from '../../utils/api';

const SendbirdDebug = () => {
  const { chatClient, loading, error, userId, initializeChat } = useSendbird();
  const [tokenResponse, setTokenResponse] = useState(null);
  const [tokenError, setTokenError] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [debugInfo, setDebugInfo] = useState({});

  useEffect(() => {
    // Get the current user from localStorage
    const lastAuthUserKey = Object.keys(localStorage).find(key => key.endsWith('.LastAuthUser'));
    if (lastAuthUserKey) {
      const userId = localStorage.getItem(lastAuthUserKey);
      setCurrentUserId(userId);
      
      // Try to get user email
      const fetchUserEmail = async () => {
        try {
          const userSettings = await getUserSettings(userId);
          if (userSettings && userSettings.email) {
            setUserEmail(userSettings.email);
          }
        } catch (error) {
          console.warn('Could not fetch user email:', error);
        }
      };
      
      fetchUserEmail();
    }
  }, []);

  useEffect(() => {
    if (chatClient) {
      setDebugInfo({
        appId: chatClient.appInfo?.appId,
        userId: chatClient.currentUser?.userId,
        connectionState: chatClient.connectionState,
        isConnected: chatClient.connectionState === 'OPEN',
        modules: Object.keys(chatClient).filter(key => 
          typeof chatClient[key] === 'object' && 
          chatClient[key] !== null && 
          !Array.isArray(chatClient[key])
        )
      });
    }
  }, [chatClient]);

  const handleTestToken = async () => {
    try {
      setTokenError(null);
      setTokenResponse(null);
      
      const idToUse = userEmail || currentUserId;
      if (!idToUse) {
        throw new Error('No user ID available');
      }
      
      console.log('Testing token generation for:', idToUse);
      const response = await getSendbirdToken(idToUse);
      setTokenResponse(response);
    } catch (err) {
      console.error('Token test error:', err);
      setTokenError(err.message || 'Unknown error');
    }
  };

  const handleConnect = () => {
    const idToUse = userEmail || currentUserId;
    if (idToUse) {
      console.log('Initializing chat with:', idToUse);
      initializeChat(idToUse);
    } else {
      setTokenError('No user ID available');
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Sendbird Debug</h1>
      
      <div className="mb-6 p-4 bg-gray-100 rounded-lg">
        <h2 className="text-lg font-semibold mb-2">User Information</h2>
        <div className="space-y-2">
          <p><strong>Current User ID:</strong> {currentUserId || 'Not found'}</p>
          <p><strong>User Email:</strong> {userEmail || 'Not found'}</p>
        </div>
      </div>
      
      <div className="mb-6 p-4 bg-gray-100 rounded-lg">
        <h2 className="text-lg font-semibold mb-2">Connection Status</h2>
        <div className="space-y-2">
          <p><strong>Loading:</strong> {loading ? 'Yes' : 'No'}</p>
          <p><strong>Connected:</strong> {chatClient ? 'Yes' : 'No'}</p>
          <p><strong>Sendbird User ID:</strong> {userId || 'Not connected'}</p>
          {error && (
            <p className="text-red-600"><strong>Error:</strong> {error.message}</p>
          )}
        </div>
      </div>
      
      <div className="mb-6 flex space-x-4">
        <button 
          onClick={handleTestToken}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Test Token API
        </button>
        
        <button 
          onClick={handleConnect}
          className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
        >
          Connect to Sendbird
        </button>
      </div>
      
      {tokenError && (
        <div className="mb-6 p-4 bg-red-100 text-red-800 rounded-lg">
          <h2 className="text-lg font-semibold mb-2">Token Error</h2>
          <p>{tokenError}</p>
        </div>
      )}
      
      {tokenResponse && (
        <div className="mb-6 p-4 bg-green-100 text-green-800 rounded-lg">
          <h2 className="text-lg font-semibold mb-2">Token Response</h2>
          <pre className="whitespace-pre-wrap overflow-auto">
            {JSON.stringify(tokenResponse, null, 2)}
          </pre>
        </div>
      )}
      
      {chatClient && (
        <div className="mb-6 p-4 bg-blue-100 text-blue-800 rounded-lg">
          <h2 className="text-lg font-semibold mb-2">Chat Client Info</h2>
          <pre className="whitespace-pre-wrap overflow-auto">
            {JSON.stringify(debugInfo, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default SendbirdDebug; 