import React from 'react';
import { X, ZoomIn, ZoomOut } from 'lucide-react';

const PDFModal = ({ url, onClose }) => {
  return (
    <div 
      className="fixed inset-0 bg-black/75 z-50 flex justify-center items-center"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div className="relative w-[90%] h-[90%] bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="absolute top-0 right-0 flex gap-2 p-2 bg-white/80 backdrop-blur rounded-bl-lg z-10">
          <button
            onClick={onClose}
            className="p-2 text-gray-600 hover:text-gray-900 focus:outline-none rounded-full hover:bg-gray-100"
            aria-label="Close"
          >
            <X size={20} />
          </button>
        </div>
        
        <iframe
          src={url}
          className="w-full h-full border-none touch-auto"
          style={{
            touchAction: 'manipulation pinch-zoom', // Enable pinch zoom
            WebkitOverflowScrolling: 'touch', // Smooth scrolling on iOS
          }}
          title="PDF Viewer"
        />
      </div>
    </div>
  );
};

export default PDFModal; 