import React, { useState } from 'react';
import { useSendbird } from '../../context/SendbirdContext';
import { getSendbirdToken } from '../../utils/api';
import SendbirdChat from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';

const TestConnectionButton = () => {
  const { chatClient, loading, error } = useSendbird();
  const [testStatus, setTestStatus] = useState(null);
  const [testError, setTestError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const addLog = (message, type = 'info') => {
    setLogs(prev => [...prev, { message, type, timestamp: new Date() }]);
  };

  const testConnection = async () => {
    setIsLoading(true);
    setTestStatus(null);
    setTestError(null);
    setLogs([]);
    
    try {
      addLog('Starting Sendbird connection test...');
      
      // Use a test user ID
      const userId = 'test_user';
      
      // Get token from backend
      addLog(`Requesting token for ${userId}`);
      const response = await getSendbirdToken(userId);
      addLog('Token response received', 'success');
      addLog(`App ID: ${response.appId}`);
      addLog(`User ID: ${response.userId}`);
      
      const { token, appId, userId: chatUserId } = response;
      
      // Validate token and appId
      if (!token || !appId || !chatUserId) {
        throw new Error('Invalid token response from server');
      }
      
      // Initialize Sendbird
      addLog('Initializing Sendbird...');
      const sb = SendbirdChat.init({
        appId: appId,
        modules: [new GroupChannelModule()]
      });
      
      // Connect to Sendbird
      addLog(`Connecting user: ${chatUserId}`);
      const user = await sb.connect(chatUserId, token);
      addLog('Successfully connected to Sendbird', 'success');
      addLog(`Connected user: ${user.userId}`);
      
      // Disconnect
      await sb.disconnect();
      addLog('Successfully disconnected from Sendbird');
      
      setTestStatus('success');
      addLog('Test completed successfully!', 'success');
    } catch (error) {
      setTestError(error);
      addLog(`Error: ${error.message}`, 'error');
      
      if (error.code) {
        addLog(`Error code: ${error.code}`, 'error');
      }
      
      if (error.response) {
        addLog(`Error response: ${JSON.stringify(error.response)}`, 'error');
      }
      
      setTestStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <h2>Sendbird Connection Test</h2>
      
      <div style={{ marginBottom: '20px' }}>
        <button 
          onClick={testConnection} 
          disabled={isLoading}
          style={{
            padding: '10px 20px',
            backgroundColor: isLoading ? '#ccc' : '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: isLoading ? 'not-allowed' : 'pointer'
          }}
        >
          {isLoading ? 'Testing...' : 'Test Connection'}
        </button>
      </div>
      
      {testStatus === 'success' && (
        <div style={{ 
          padding: '10px', 
          backgroundColor: '#dff0d8', 
          color: '#3c763d',
          borderRadius: '4px',
          marginBottom: '20px'
        }}>
          Connection test successful!
        </div>
      )}
      
      {testStatus === 'error' && (
        <div style={{ 
          padding: '10px', 
          backgroundColor: '#f2dede', 
          color: '#a94442',
          borderRadius: '4px',
          marginBottom: '20px'
        }}>
          Connection test failed. See logs for details.
        </div>
      )}
      
      <div style={{ 
        backgroundColor: '#f5f5f5', 
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '10px',
        height: '300px',
        overflowY: 'auto',
        fontFamily: 'monospace',
        fontSize: '14px'
      }}>
        {logs.map((log, index) => (
          <div 
            key={index} 
            style={{ 
              color: log.type === 'error' ? 'red' : log.type === 'success' ? 'green' : 'black',
              marginBottom: '5px'
            }}
          >
            [{log.timestamp.toLocaleTimeString()}] {log.message}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestConnectionButton; 