import React, { useEffect, useState } from 'react';
import { useSendbird } from '../../context/SendbirdContext';
import { getSendbirdToken } from '../../utils/api';

const SendbirdTest = () => {
  const { chatClient, loading, error, userId, initializeChat } = useSendbird();
  const [tokenResponse, setTokenResponse] = useState(null);
  const [testError, setTestError] = useState(null);

  useEffect(() => {
    // Get the current user from localStorage
    const lastAuthUserKey = Object.keys(localStorage).find(key => key.endsWith('.LastAuthUser'));
    if (lastAuthUserKey) {
      const currentUserId = localStorage.getItem(lastAuthUserKey);
      if (currentUserId) {
        initializeChat(currentUserId);
      }
    }
  }, [initializeChat]);

  const handleTestToken = async () => {
    try {
      setTestError(null);
      const lastAuthUserKey = Object.keys(localStorage).find(key => key.endsWith('.LastAuthUser'));
      if (!lastAuthUserKey) {
        throw new Error('No authenticated user found');
      }
      
      const currentUserId = localStorage.getItem(lastAuthUserKey);
      if (!currentUserId) {
        throw new Error('No user ID found');
      }
      
      const response = await getSendbirdToken(currentUserId);
      setTokenResponse(response);
    } catch (err) {
      console.error('Test error:', err);
      setTestError(err.message);
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Sendbird Integration Test</h1>
      
      <div className="mb-6 p-4 bg-gray-100 rounded-lg">
        <h2 className="text-lg font-semibold mb-2">Connection Status</h2>
        <div className="space-y-2">
          <p><strong>Loading:</strong> {loading ? 'Yes' : 'No'}</p>
          <p><strong>Connected:</strong> {chatClient ? 'Yes' : 'No'}</p>
          <p><strong>User ID:</strong> {userId || 'Not connected'}</p>
          {error && (
            <p className="text-red-600"><strong>Error:</strong> {error.message}</p>
          )}
        </div>
      </div>
      
      <div className="mb-6">
        <button 
          onClick={handleTestToken}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Test Token API
        </button>
      </div>
      
      {testError && (
        <div className="mb-6 p-4 bg-red-100 text-red-800 rounded-lg">
          <h2 className="text-lg font-semibold mb-2">Test Error</h2>
          <p>{testError}</p>
        </div>
      )}
      
      {tokenResponse && (
        <div className="mb-6 p-4 bg-green-100 text-green-800 rounded-lg">
          <h2 className="text-lg font-semibold mb-2">Token Response</h2>
          <pre className="whitespace-pre-wrap overflow-auto">
            {JSON.stringify(tokenResponse, null, 2)}
          </pre>
        </div>
      )}
      
      {chatClient && (
        <div className="mb-6 p-4 bg-blue-100 text-blue-800 rounded-lg">
          <h2 className="text-lg font-semibold mb-2">Chat Client Info</h2>
          <p><strong>App ID:</strong> {chatClient.appInfo.appId}</p>
          <p><strong>Current User ID:</strong> {chatClient.currentUser?.userId}</p>
          <p><strong>Connected:</strong> {chatClient.connectionState === 'OPEN' ? 'Yes' : 'No'}</p>
        </div>
      )}
    </div>
  );
};

export default SendbirdTest; 