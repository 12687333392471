import React, { useState } from 'react';
import { upsertSendbirdUser } from '../../utils/api';

const SendbirdSingleUserTest = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [organizationId, setOrganizationId] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResult(null);

    try {
      // Create a test user object
      const testUser = {
        email,
        firstName,
        lastName,
        isAdmin: isAdmin ? 1 : 0,
        isDeleted: isDeleted ? 1 : 0,
        organizationId
      };

      console.log('Upserting test user:', testUser);
      const response = await upsertSendbirdUser(testUser);
      setResult(response);
    } catch (err) {
      console.error('Error in test:', err);
      setError(err.message || 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Test Sendbird Single User Upsert</h1>
      
      <form onSubmit={handleSubmit} className="mb-6 space-y-4 bg-white p-6 rounded-lg shadow">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Email (required)
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            placeholder="user@example.com"
          />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              First Name
            </label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="John"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Last Name
            </label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Doe"
            />
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="isAdmin"
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
              className="h-4 w-4 text-orange-600 border-gray-300 rounded"
            />
            <label htmlFor="isAdmin" className="ml-2 block text-sm text-gray-700">
              Is Admin
            </label>
          </div>
          
          <div className="flex items-center">
            <input
              type="checkbox"
              id="isDeleted"
              checked={isDeleted}
              onChange={(e) => setIsDeleted(e.target.checked)}
              className="h-4 w-4 text-orange-600 border-gray-300 rounded"
            />
            <label htmlFor="isDeleted" className="ml-2 block text-sm text-gray-700">
              Is Deleted
            </label>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Organization ID
            </label>
            <input
              type="text"
              value={organizationId}
              onChange={(e) => setOrganizationId(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="org-123"
            />
          </div>
        </div>
        
        <div className="pt-4">
          <button
            type="submit"
            disabled={loading || !email}
            className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 disabled:opacity-50"
          >
            {loading ? 'Testing...' : 'Test Upsert User'}
          </button>
        </div>
      </form>
      
      {error && (
        <div className="mb-6 p-4 bg-red-50 text-red-700 rounded-md">
          <h2 className="text-lg font-semibold mb-2">Error</h2>
          <pre className="whitespace-pre-wrap">{error}</pre>
        </div>
      )}
      
      {result && (
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">Result</h2>
          <div className="bg-gray-900 text-white p-4 rounded-md overflow-auto max-h-96">
            <pre className="whitespace-pre-wrap">{JSON.stringify(result, null, 2)}</pre>
          </div>
        </div>
      )}
      
      <div className="bg-yellow-50 p-4 rounded-md">
        <h2 className="text-lg font-semibold mb-2 text-yellow-800">Important Notes</h2>
        <ul className="list-disc pl-5 space-y-1 text-yellow-700">
          <li>Sendbird has a limit of 5 metadata fields per user</li>
          <li>The updated code prioritizes these metadata fields: isAdmin, organizationId, isDeleted, role, and originalId</li>
          <li>Email addresses are sanitized for Sendbird by replacing @ and . with _</li>
        </ul>
      </div>
    </div>
  );
};

export default SendbirdSingleUserTest; 