import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import SendbirdChat from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import { getSendbirdToken, getUserSettings } from '../utils/api';
import api from '../utils/api';
import { checkPushSubscription } from '../utils/notificationService';
import { BASE_URL } from '../config/apiConfig';

const SendbirdContext = createContext();

export const useSendbird = () => useContext(SendbirdContext);

export const SendbirdProvider = ({ children }) => {
  const [chatClient, setChatClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const initTimeoutRef = useRef(null);
  const retryCountRef = useRef(0);
  const MAX_RETRIES = 5;
  const debounceTimerRef = useRef(null);
  const connectionCheckIntervalRef = useRef(null);

  // Make the Sendbird client globally available for components that need direct access
  useEffect(() => {
    if (chatClient) {
      window.sendbirdClient = chatClient;
    }
    return () => {
      // Clean up global reference when component unmounts
      if (window.sendbirdClient === chatClient) {
        window.sendbirdClient = null;
      }
    };
  }, [chatClient]);

  // Set up a connection check interval
  useEffect(() => {
    if (chatClient) {
      // Check connection status every 30 seconds
      connectionCheckIntervalRef.current = setInterval(() => {
        try {
          const connectionState = chatClient.connectionState;
          console.log(`[SendbirdContext] Connection check: ${connectionState}`);
          
          // If not connected and not already trying to reconnect
          if (connectionState !== 'OPEN' && !loading && userId) {
            console.log('[SendbirdContext] Not connected. Attempting reconnection...');
            // Attempt reconnection
            chatClient.reconnect()
              .then(() => {
                console.log('[SendbirdContext] Reconnection successful');
                setError(null);
              })
              .catch(err => {
                console.error('[SendbirdContext] Reconnection failed:', err);
                // Only trigger full reinitialization if reconnect failed
                if (retryCountRef.current < MAX_RETRIES) {
                  console.log('[SendbirdContext] Attempting full reinitialization');
                  retryCountRef.current++;
                  initializeChat(userId);
                } else {
                  setError(new Error('Failed to reconnect after multiple attempts'));
                }
              });
          }
        } catch (error) {
          console.error('[SendbirdContext] Error checking connection:', error);
        }
      }, 30000);
    }
    
    return () => {
      if (connectionCheckIntervalRef.current) {
        clearInterval(connectionCheckIntervalRef.current);
      }
    };
  }, [chatClient, loading, userId]);

  // The actual connection logic with retry mechanism
  const connectChat = async (userId, retryCount = 0) => {
    console.log(`[SendbirdContext] connectChat called with userId: ${userId}, retry: ${retryCount}`);
    
    if (retryCount > MAX_RETRIES) {
      console.error(`[SendbirdContext] Max retries (${MAX_RETRIES}) reached for connecting chat`);
      setError(new Error(`Failed to connect to chat after ${MAX_RETRIES} attempts. Please refresh the page or try again later.`));
      setLoading(false);
      return;
    }

    try {
      // If there's an existing chat client, disconnect it first
      if (chatClient) {
        console.log('[SendbirdContext] Disconnecting existing chat client');
        try {
          await chatClient.disconnect();
          console.log('[SendbirdContext] Successfully disconnected existing chat client');
        } catch (disconnectError) {
          console.warn('[SendbirdContext] Error disconnecting chat client:', disconnectError);
        }
      }

      // Fetch user settings to get display name and email
      let userSettings = {};
      try {
        console.log('[SendbirdContext] Fetching user settings');
        userSettings = await getUserSettings();
        console.log('[SendbirdContext] User settings fetched:', userSettings);
      } catch (error) {
        console.warn('[SendbirdContext] Error fetching user settings:', error);
        // Continue with default values
      }
      
      // Get display name
      let userDisplayName = userId;
      let userEmail = null;
      
      if (userSettings) {
        if (userSettings.firstName && userSettings.lastName) {
          userDisplayName = `${userSettings.firstName} ${userSettings.lastName}`;
          console.log('Using display name:', userDisplayName);
        }
        
        if (userSettings.email) {
          userEmail = userSettings.email;
          console.log('Using email for Sendbird:', userEmail);
        }
      }
      
      // Get token from backend
      console.log(`[SendbirdContext] Requesting token for ${userEmail || userId}`);
      const response = await getSendbirdToken(userEmail || userId);
      console.log('[SendbirdContext] Token response:', response);
      
      const { token, appId, userId: chatUserId } = response;
      
      try {
        console.log('Fetching user settings for:', userId);
        userSettings = await getUserSettings(userId);
        console.log('User settings retrieved:', userSettings);
        
        if (userSettings) {
          // Get display name
          if (userSettings.firstName && userSettings.lastName) {
            userDisplayName = `${userSettings.firstName} ${userSettings.lastName}`;
            console.log('Using display name:', userDisplayName);
          }
          
          // Get email - this is what we'll use for Sendbird
          if (userSettings.email) {
            userEmail = userSettings.email;
            console.log('Using email for Sendbird:', userEmail);
          }
        }
      } catch (userSettingsError) {
        console.warn('Could not fetch user settings:', userSettingsError);
        // Continue with default values if user settings can't be fetched
      }
      
      // Check if we have a valid token response
      if (!token || !appId || !chatUserId) {
        console.error('[SendbirdContext] Invalid token response:', { token: !!token, appId: !!appId, chatUserId: !!chatUserId });
        throw new Error('Invalid token response from server');
      }
      
      // Validate token format (should be a JWT token)
      if (!token.match(/^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+$/)) {
        console.error('[SendbirdContext] Invalid token format:', token);
        throw new Error('Invalid token format. Expected JWT token.');
      }
      
      // Validate appId format (should be a UUID)
      if (!appId.match(/^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i)) {
        console.error('[SendbirdContext] Invalid appId format:', appId);
        throw new Error('Invalid appId format. Expected UUID format.');
      }
      
      // Initialize Sendbird
      console.log('[SendbirdContext] Initializing Sendbird...');
      console.log('[SendbirdContext] Using appId:', appId);
      const sb = SendbirdChat.init({
        appId: appId,
        modules: [new GroupChannelModule()]
      });

      // Connect user with simple approach
      console.log(`[SendbirdContext] Connecting user: ${chatUserId} with token length: ${token ? token.length : 0}`);
      try {
        // Simple direct connection
        const user = await sb.connect(chatUserId, token);
        console.log('[SendbirdContext] Successfully connected to Sendbird');
        console.log('[SendbirdContext] Connected user:', user);
        
        // Set the chat client in state
        setChatClient(sb);
        setLoading(false);
        setUserId(chatUserId);
        
        // Setup notification handlers
        await setupNotificationHandlers(sb, chatUserId);
      } catch (connectError) {
        console.error('[SendbirdContext] Connection error:', connectError);
        console.error('[SendbirdContext] Error details:', {
          message: connectError.message,
          code: connectError.code,
          response: connectError.response
        });
        throw connectError;
      }
      
      // Update user's information if needed
      try {
        await sb.updateCurrentUserInfo({
          nickname: userDisplayName
        });
        console.log('Updated user nickname to:', userDisplayName);
      } catch (updateError) {
        console.warn('Could not update user nickname:', updateError);
        // Continue anyway, this is not critical
      }
      
      // Set up connection event handlers to handle reconnection
      sb.addConnectionHandler('main', {
        onReconnectStarted: () => {
          console.log('Sendbird reconnection started');
        },
        onReconnectSucceeded: () => {
          console.log('Sendbird reconnection succeeded');
        },
        onReconnectFailed: () => {
          console.error('Sendbird reconnection failed');
          setError(new Error('Failed to reconnect to chat. Please try again.'));
        }
      });
      
    } catch (err) {
      console.error('Error initializing Sendbird:', err);
      console.error('Error details:', {
        message: err.message,
        code: err.code,
        statusCode: err.statusCode,
        response: err.response?.data
      });
      
      // Handle rate limiting with exponential backoff
      if (err.message?.includes('rate limit') || err.status === 429) {
        const nextRetryCount = retryCount + 1;
        if (nextRetryCount <= MAX_RETRIES) {
          const backoffTime = 1000 * Math.pow(2, retryCount);
          console.log(`Rate limited. Retrying in ${backoffTime}ms... (attempt ${nextRetryCount} of ${MAX_RETRIES})`);
          
          setTimeout(() => {
            connectChat(userId, nextRetryCount);
          }, backoffTime);
          
          return; // Don't set error state when we're going to retry
        } else {
          setError(new Error('Rate limited. Please try again later.'));
        }
      } else {
        setError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  // Initialize chat with debounce to prevent multiple calls
  const initializeChat = useCallback((userId) => {
    console.log(`[SendbirdContext] initializeChat called with userId: ${userId}`);
    
    // If we're already loading, don't start another connection
    if (loading) {
      console.log('[SendbirdContext] Already loading, not starting another connection');
      return;
    }
    
    // If we already have a connected chat client, don't reconnect
    if (chatClient && chatClient.connectionState === 'OPEN') {
      console.log('[SendbirdContext] Chat client already connected, not reconnecting');
      return;
    }
    
    // Set loading state
    setLoading(true);
    setError(null);
    
    // Clear any existing debounce timer
    if (debounceTimerRef.current) {
      console.log('[SendbirdContext] Clearing existing debounce timer');
      clearTimeout(debounceTimerRef.current);
    }
    
    console.log('[SendbirdContext] Setting up debounced connection');
    
    // Debounce the initialization to prevent multiple rapid connection attempts
    debounceTimerRef.current = setTimeout(async () => {
      console.log('[SendbirdContext] Debounce timer expired, connecting...');
      
      try {
        // Get token from backend
        console.log('[SendbirdContext] Getting token from backend...');
        const response = await getSendbirdToken(userId);
        console.log('[SendbirdContext] Token response:', response);
        
        const { token, appId, userId: chatUserId } = response;
        
        // Initialize Sendbird
        console.log('[SendbirdContext] Initializing Sendbird...');
        console.log('[SendbirdContext] Using appId:', appId);
        const sb = SendbirdChat.init({
          appId: appId,
          modules: [new GroupChannelModule()]
        });
        
        // Connect to Sendbird
        console.log('[SendbirdContext] Connecting to Sendbird...');
        const user = await sb.connect(chatUserId, token);
        console.log('[SendbirdContext] Connected successfully:', user);
        
        // Set the chat client in state
        setChatClient(sb);
        setUserId(chatUserId);
        setLoading(false);
        
        // Update user's information if needed
        try {
          await sb.updateCurrentUserInfo({
            nickname: user.nickname || userId
          });
          console.log('[SendbirdContext] Updated user nickname');
        } catch (updateError) {
          console.warn('[SendbirdContext] Error updating user nickname:', updateError);
        }
        
        // Setup notification handlers
        await setupNotificationHandlers(sb, chatUserId);
      } catch (error) {
        console.error('[SendbirdContext] Error initializing chat:', error);
        console.error('[SendbirdContext] Error details:', {
          message: error.message,
          code: error.code,
          response: error.response
        });
        setError(error);
        setLoading(false);
      }
    }, 300);
  }, []);

  // Reset the connection
  const resetConnection = useCallback(async () => {
    console.log('[SendbirdContext] Resetting connection');
    
    // Clear any pending timeouts
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    
    // Disconnect the client if it exists
    if (chatClient) {
      try {
        console.log('[SendbirdContext] Disconnecting existing chat client');
        await chatClient.disconnect();
        console.log('[SendbirdContext] Successfully disconnected existing chat client');
      } catch (error) {
        console.warn('[SendbirdContext] Error disconnecting chat client:', error);
      }
    }
    
    // Reset state
    setChatClient(null);
    setLoading(false);
    setError(null);
    setUserId(null);
    retryCountRef.current = 0;
    
    console.log('[SendbirdContext] Connection reset complete');
  }, [chatClient]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Clear any intervals or timeouts
      if (initTimeoutRef.current) {
        clearTimeout(initTimeoutRef.current);
      }
      
      if (connectionCheckIntervalRef.current) {
        clearInterval(connectionCheckIntervalRef.current);
      }
      
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      
      if (chatClient) {
        try {
          // Remove connection handlers
          try {
            chatClient.removeConnectionHandler('main');
          } catch (handlerError) {
            console.warn('[SendbirdContext] Error removing connection handler:', handlerError);
          }
          
          // Disconnect the client
          // Use a Promise with timeout to ensure this doesn't hang
          const disconnectWithTimeout = () => {
            const timeoutPromise = new Promise((_, reject) => {
              setTimeout(() => reject(new Error('Disconnect timeout')), 3000);
            });
            
            return Promise.race([
              chatClient.disconnect().then(() => {
                console.log('[SendbirdContext] Successfully disconnected from Sendbird');
                return true;
              }),
              timeoutPromise
            ]).catch(error => {
              console.warn('[SendbirdContext] Error disconnecting from Sendbird:', error);
              return false;
            }).finally(() => {
              setChatClient(null);
              // Ensure global reference is cleared
              if (window.sendbirdClient === chatClient) {
                window.sendbirdClient = null;
              }
            });
          };
          
          disconnectWithTimeout();
        } catch (error) {
          console.warn('[SendbirdContext] Error during cleanup:', error);
          setChatClient(null);
          // Ensure global reference is cleared
          if (window.sendbirdClient === chatClient) {
            window.sendbirdClient = null;
          }
        }
      }
    };
  }, [chatClient]);

  const value = {
    chatClient,
    loading,
    error,
    userId,
    initializeChat,
    resetConnection,
  };

  return (
    <SendbirdContext.Provider value={value}>
      {children}
    </SendbirdContext.Provider>
  );
};

// Add this after the connectChat function but before the initializeChat function
const setupNotificationHandlers = async (sb, userId) => {
  console.log('[SendbirdContext] Setting up notification handlers');
  
  // Check if browser is in focus
  let isWindowFocused = document.hasFocus();
  
  // Update focus state on window focus/blur
  window.addEventListener('focus', () => {
    isWindowFocused = true;
  });
  
  window.addEventListener('blur', () => {
    isWindowFocused = false;
  });
  
  // Check if push notifications are enabled
  const isPushEnabled = await checkPushSubscription();
  
  if (!isPushEnabled) {
    console.log('[SendbirdContext] Push notifications not enabled, skipping notification handler setup');
    return;
  }
  
  // Handler for new messages
  const messageHandler = async (channel, message) => {
    // Don't send notifications for messages from the current user
    if (message.sender && message.sender.userId === userId) {
      return;
    }
    
    // Only send notification if window is not focused
    if (!isWindowFocused) {
      console.log('[SendbirdContext] Window not focused, sending push notification');
      
      try {
        // Get sender name
        const senderName = message.sender ? message.sender.nickname || message.sender.userId : 'Someone';
        
        // Get channel name
        const channelName = channel.name || 'a chat';
        
        // Format message body
        let messageBody = '';
        if (message.message) {
          messageBody = message.message.length > 50 
            ? message.message.substring(0, 50) + '...' 
            : message.message;
        } else if (message.type === 'file') {
          messageBody = 'Sent a file';
        } else {
          messageBody = 'Sent a message';
        }
        
        // Call the backend API to send a push notification using our API with auth interceptors
        await api.post(`${BASE_URL}/push/send`, {
          userId: userId,
          title: `New message from ${senderName}`,
          body: `${senderName} in ${channelName}: ${messageBody}`,
          data: {
            type: 'chat_message',
            channelUrl: channel.url,
            messageId: message.messageId
          }
        });
        
        console.log('[SendbirdContext] Push notification request sent');
      } catch (error) {
        console.error('[SendbirdContext] Error sending push notification:', error);
        console.error('[SendbirdContext] Error details:', error.response?.data || error.message);
      }
    }
  };
  
  // Register channel event handler
  // In Sendbird SDK v4, we need to use the GroupChannelHandler from the GroupChannelModule
  try {
    const { GroupChannelHandler } = sb.groupChannel;
    if (!GroupChannelHandler) {
      console.error('[SendbirdContext] GroupChannelHandler not found in Sendbird SDK');
      return;
    }
    
    const channelHandler = new GroupChannelHandler();
    channelHandler.onMessageReceived = messageHandler;
    
    // Add the handler to the client
    sb.groupChannel.addGroupChannelHandler('PUSH_NOTIFICATIONS', channelHandler);
    
    console.log('[SendbirdContext] Notification handlers set up successfully');
  } catch (error) {
    console.error('[SendbirdContext] Error setting up notification handlers:', error);
    console.error('[SendbirdContext] Error details:', {
      message: error.message,
      stack: error.stack
    });
  }
};

export default SendbirdContext; 