import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import { 
  User, Bell, Shield, Globe, 
  Wallet, Building2, Users, Mail,
  LogOut, Smartphone, Keyboard, ChevronDown
} from 'lucide-react';
import { useUserSettingsContext } from '../context/UserSettingsContext';
import { getOrgConfig } from '../utils/api';

function Settings() {
  const navigate = useNavigate();
  const location = useLocation();
  const { organizations } = useUserSettingsContext();
  const [currentOrg, setCurrentOrg] = useState(null);
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Check if device is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Update current org when organizations change
  useEffect(() => {
    if (organizations && organizations.length > 0) {
      // Set current org to the one from localStorage or the first one
      const savedOrgId = localStorage.getItem('currentOrgId');
      const defaultOrg = savedOrgId 
        ? organizations.find(org => org.id === savedOrgId) 
        : organizations[0];
      
      if (defaultOrg) {
        setCurrentOrg(defaultOrg);
      }
    }
  }, [organizations]);

  const handleOrgChange = async (org) => {
    try {
      // Get organization details from orgConfig
      const orgConfig = await getOrgConfig();
      
      // Find the matching organization in orgConfig using orgId field
      const selectedOrgConfig = orgConfig.find(config => config.orgId === org.id);
      
      if (!selectedOrgConfig) {
        console.error(`Organization ${org.id} not found in orgConfig`);
        return;
      }
      
      // Store the workspaceId along with the organization ID
      localStorage.setItem('currentOrgId', org.id);
      localStorage.setItem('currentOrgWorkspaceId', selectedOrgConfig.workspaceId);
      
      // Update current org state
      setCurrentOrg(org);
      setShowOrgDropdown(false);
      
      // Check if already on dashboard
      if (location.pathname === '/dashboard') {
        // If already on dashboard, reload the page to refresh data
        window.location.reload();
      } else {
        // Otherwise navigate to dashboard
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error changing organization:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Header */}
      <div className="p-4 md:p-8">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-4">
          <h1 className="text-xl md:text-2xl font-semibold text-white mb-2 md:mb-0">Account Settings</h1>
          <button
            onClick={handleLogout}
            className="flex items-center gap-2 px-4 py-2 bg-red-500/10 text-red-400 rounded-lg hover:bg-red-500/20 transition-colors self-start md:self-auto"
          >
            <LogOut size={18} />
            <span>Sign Out</span>
          </button>
        </div>
        <p className="text-gray-400 text-sm md:text-base">Manage your account preferences and settings</p>

        {/* Mobile Organization Selector */}
        {isMobile && (
          <div className="mt-6 mb-4 rounded-xl bg-gradient-to-br from-[#1a2535]/90 to-[#0f172a]/90 backdrop-blur-xl border border-white/10 p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-full bg-blue-500/10 flex items-center justify-center">
                  <Building2 className="w-5 h-5 text-blue-400" />
                </div>
                <div>
                  <h2 className="text-white font-medium text-sm">Current Organization</h2>
                  <p className="text-gray-400 text-xs">Switch between organizations</p>
                </div>
              </div>
            </div>
            
            {/* Organization Dropdown */}
            <div className="mt-4 relative">
              <button
                onClick={() => setShowOrgDropdown(!showOrgDropdown)}
                className="w-full flex items-center justify-between p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors"
              >
                <span>{currentOrg?.name || 'Select Organization'}</span>
                <ChevronDown className="w-4 h-4 text-gray-400" />
              </button>
              
              {showOrgDropdown && (
                <div className="absolute top-full left-0 right-0 mt-1 bg-gray-800 border border-gray-700 rounded-lg shadow-xl z-10 max-h-60 overflow-y-auto">
                  {organizations?.map((org) => (
                    <button
                      key={org.id}
                      onClick={() => handleOrgChange(org)}
                      className={`w-full text-left p-3 hover:bg-gray-700 transition-colors ${
                        currentOrg?.id === org.id ? 'bg-gray-700 text-white' : 'text-gray-300'
                      }`}
                    >
                      {org.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Settings Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mt-6">
          {/* Profile Settings Card */}
          <div className="rounded-xl bg-gradient-to-br from-[#2a2a2a]/90 to-[#1a1a1a]/90 backdrop-blur-xl border border-white/10 p-4 md:p-6">
            <div className="flex items-center gap-3 md:gap-4 mb-4 md:mb-6">
              <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-orange-500/10 flex items-center justify-center">
                <User className="w-5 h-5 md:w-6 md:h-6 text-orange-400" />
              </div>
              <div>
                <h2 className="text-white font-medium text-sm md:text-base">Profile Settings</h2>
                <p className="text-gray-400 text-xs md:text-sm">Update your personal information</p>
              </div>
            </div>
            <div className="space-y-2 md:space-y-3">
              <button className="w-full text-left p-2 md:p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors text-sm md:text-base">
                <Mail className="inline-block w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-gray-400" />
                Email Preferences
              </button>
              <button className="w-full text-left p-2 md:p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors text-sm md:text-base">
                <Bell className="inline-block w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-gray-400" />
                Notification Settings
              </button>
              <button className="w-full text-left p-2 md:p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors text-sm md:text-base">
                <Shield className="inline-block w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-gray-400" />
                Privacy & Security
              </button>
            </div>
          </div>

          {/* Organization Card - Hidden on mobile since we have the dropdown above */}
          <div className="hidden md:block rounded-xl bg-gradient-to-br from-[#1a2535]/90 to-[#0f172a]/90 backdrop-blur-xl border border-white/10 p-6">
            <div className="flex items-center gap-4 mb-6">
              <div className="w-12 h-12 rounded-full bg-blue-500/10 flex items-center justify-center">
                <Building2 className="w-6 h-6 text-blue-400" />
              </div>
              <div>
                <h2 className="text-white font-medium">Organization</h2>
                <p className="text-gray-400 text-sm">Manage organization settings</p>
              </div>
            </div>
            <div className="space-y-3">
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Users className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Team Management
              </button>
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Wallet className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Billing & Plans
              </button>
              <button className="w-full text-left p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors">
                <Globe className="inline-block w-5 h-5 mr-3 text-gray-400" />
                Workspace Settings
              </button>
            </div>
          </div>

          {/* System Card */}
          <div className="rounded-xl bg-gradient-to-br from-[#2a1f3f]/90 to-[#1a1a2f]/90 backdrop-blur-xl border border-white/10 p-4 md:p-6">
            <div className="flex items-center gap-3 md:gap-4 mb-4 md:mb-6">
              <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-purple-500/10 flex items-center justify-center">
                <Smartphone className="w-5 h-5 md:w-6 md:h-6 text-purple-400" />
              </div>
              <div>
                <h2 className="text-white font-medium text-sm md:text-base">System</h2>
                <p className="text-gray-400 text-xs md:text-sm">System preferences</p>
              </div>
            </div>
            <div className="space-y-2 md:space-y-3">
              <button className="w-full text-left p-2 md:p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors text-sm md:text-base">
                <Smartphone className="inline-block w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-gray-400" />
                Device Settings
              </button>
              <button className="w-full text-left p-2 md:p-3 rounded-lg bg-black/20 hover:bg-black/40 text-gray-300 transition-colors text-sm md:text-base">
                <Keyboard className="inline-block w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-3 text-gray-400" />
                Keyboard Shortcuts
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings; 