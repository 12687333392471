import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { getUserSettings } from '../utils/api';
import { useUserSettingsContext } from '../context/UserSettingsContext';

function AdminProtectedRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userSettings } = useUserSettingsContext();

  useEffect(() => {
    checkAuth();
  }, []);

  async function checkAuth() {
    try {
      // First check if user is authenticated
      await fetchUserAttributes();
      setIsAuthenticated(true);
      
      // Then check if user is an admin
      if (userSettings) {
        // If userSettings is already loaded from context
        setIsAdmin(userSettings.isAdmin === 1);
        setIsLoading(false);
      } else {
        // Otherwise fetch user settings directly
        const attributes = await fetchUserAttributes();
        const email = attributes.email;
        
        if (email) {
          const settings = await getUserSettings(email);
          setIsAdmin(settings && settings.isAdmin === 1);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      setIsAuthenticated(false);
      setIsAdmin(false);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    // Show loading state
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-orange-500"></div>
        <p className="ml-4 text-gray-600 dark:text-gray-400">Checking permissions...</p>
      </div>
    );
  }

  if (!isAuthenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" replace />;
  }

  if (!isAdmin) {
    // Redirect to dashboard if authenticated but not an admin
    return <Navigate to="/dashboard" replace />;
  }

  // If authenticated and admin, render the children
  return children;
}

export default AdminProtectedRoute; 