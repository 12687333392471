import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useSendbird } from '../context/SendbirdContext';
import { useUserSettingsContext } from '../context/UserSettingsContext';

// Import custom components from ChatBox
import ChatBox from '../components/Chat/ChatBox';

const Chat = () => {
  const { chatClient, loading, error, initializeChat } = useSendbird();
  const { userSettings } = useUserSettingsContext();
  const [reconnectAttempt, setReconnectAttempt] = useState(0);
  
  // Add effect to disable zooming for app-like feel
  useEffect(() => {
    // Save the original viewport meta tag content
    const originalViewport = document.querySelector('meta[name="viewport"]')?.content;
    
    // Update viewport to prevent zooming
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
    }
    
    // Add mobile web app capable meta tags
    const appleMobileWebAppCapable = document.createElement('meta');
    appleMobileWebAppCapable.setAttribute('name', 'apple-mobile-web-app-capable');
    appleMobileWebAppCapable.setAttribute('content', 'yes');
    
    const mobileWebAppCapable = document.createElement('meta');
    mobileWebAppCapable.setAttribute('name', 'mobile-web-app-capable');
    mobileWebAppCapable.setAttribute('content', 'yes');
    
    document.head.appendChild(appleMobileWebAppCapable);
    document.head.appendChild(mobileWebAppCapable);
    
    // Cleanup function to restore original viewport settings and remove added meta tags
    return () => {
      if (viewportMeta && originalViewport) {
        viewportMeta.setAttribute('content', originalViewport);
      }
      
      const appleMeta = document.querySelector('meta[name="apple-mobile-web-app-capable"]');
      const mobileMeta = document.querySelector('meta[name="mobile-web-app-capable"]');
      
      if (appleMeta) document.head.removeChild(appleMeta);
      if (mobileMeta) document.head.removeChild(mobileMeta);
    };
  }, []);
  
  // Get current user's ID for chat (preferring Cognito ID if available)
  const getUserIdForChat = useCallback(() => {
    // First try to get the cognitoId from userSettings if available
    if (userSettings) {
      // If the user has a cognitoId in their settings, use that
      if (userSettings.cognitoId) {
        console.log('Using cognitoId from userSettings:', userSettings.cognitoId);
        return userSettings.cognitoId;
      }
      
      // If they have cognitoUsername but no cognitoId, use that
      if (userSettings.cognitoUsername) {
        console.log('Using cognitoUsername from userSettings:', userSettings.cognitoUsername);
        return userSettings.cognitoUsername;
      }
      
      // Fallback to email
      if (userSettings.email) {
        console.log('Falling back to email from userSettings:', userSettings.email);
        return userSettings.email;
      }
    }
    
    // Find user ID from local storage as fallback
    const userPoolId = localStorage.getItem('amplify-signin-with-webui');
    if (userPoolId) {
      try {
        const userData = JSON.parse(localStorage.getItem(userPoolId));
        if (userData && userData.username) {
          console.log('Using username from localStorage:', userData.username);
          return userData.username;
        }
      } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
      }
    }
    
    // Last resort fallback to userId in localStorage
    const userId = localStorage.getItem('userId');
    if (userId) {
      console.log('Using userId from localStorage:', userId);
      return userId;
    }
    
    console.log('No user ID found for chat');
    return null;
  }, [userSettings]);
  
  // Memoize the userId to prevent recalculation on every render
  const userId = useMemo(() => getUserIdForChat(), [getUserIdForChat]);
  
  // Initialize chat on component mount
  useEffect(() => {
    if (userId && (!chatClient || chatClient.connectionState !== 'OPEN') && !loading) {
      console.log(`[Chat] Initializing chat with userId: ${userId}`);
      initializeChat(userId);
    }
  }, [userId, chatClient, loading, initializeChat]);

  // Add auto-reconnection logic
  useEffect(() => {
    let reconnectTimer;
    
    // If we encountered an error, attempt to reconnect
    if (error && userId && reconnectAttempt < 5) {
      console.log(`[Chat] Connection error detected, scheduling reconnect attempt ${reconnectAttempt + 1}`);
      
      // Use exponential backoff for reconnection attempts
      const backoff = Math.min(30000, 1000 * Math.pow(2, reconnectAttempt));
      
      reconnectTimer = setTimeout(() => {
        console.log(`[Chat] Attempting reconnection #${reconnectAttempt + 1}`);
        setReconnectAttempt(prev => prev + 1);
        initializeChat(userId);
      }, backoff);
    }
    
    return () => {
      if (reconnectTimer) clearTimeout(reconnectTimer);
    };
  }, [error, userId, reconnectAttempt, initializeChat]);

  // Don't use any modal-specific props for ChatBox
  return (
    <div className="bg-white fixed inset-0 top-16 bottom-16 z-10">
      <div className="h-full w-full">
        {loading ? (
          <div className="flex items-center justify-center h-full p-8">
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-orange-500"></div>
              <p className="mt-4 text-gray-600">Connecting to chat...</p>
            </div>
          </div>
        ) : error ? (
          <div className="flex flex-col items-center justify-center h-full p-6">
            <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
              <p>Could not connect to chat: {error?.message || 'Unknown error'}</p>
              <p className="text-sm mt-2">Attempt {reconnectAttempt}/5 - {reconnectAttempt < 5 ? 'Reconnecting soon...' : 'Maximum attempts reached.'}</p>
            </div>
            <button
              onClick={() => {
                if (userId) {
                  setReconnectAttempt(0); // Reset reconnect counter
                  initializeChat(userId);
                }
              }}
              className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors"
            >
              Retry Connection
            </button>
          </div>
        ) : (
          <div className="absolute inset-0">
            <ChatBox isOpen={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat; 