import React, { useState, useEffect, useRef } from 'react';
import { Search, HelpCircle, Bell, LogOut, MessageSquare, ChevronDown, Settings, X } from 'react-feather';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchUserAttributes, signOut } from '@aws-amplify/auth';
import { getReports, getOrgConfig, getReportPages } from '../../utils/api';
import { useUserSettingsContext } from '../../context/UserSettingsContext';
import ChatBox from '../Chat/ChatBox';
import { useSendbird } from '../../context/SendbirdContext';

const Navbar = ({ isSidebarCollapsed, toggleChat }) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const profileRef = useRef(null);
  const orgDropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { userSettings, organizations, isLoading: isLoadingSettings } = useUserSettingsContext();
  const [currentOrg, setCurrentOrg] = useState(null);
  const isAdmin = userSettings && userSettings.isAdmin === 1;
  const { chatClient } = useSendbird();
  
  // Log user admin status in development environment
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && userSettings && userSettings.isAdmin !== undefined) {
      console.log('User isAdmin:', userSettings.isAdmin);
    }
  }, [userSettings?.isAdmin]);

  // Add state for organization search
  const [orgSearchTerm, setOrgSearchTerm] = useState('');
  const [filteredOrgs, setFilteredOrgs] = useState([]);
  const orgSearchInputRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const searchRef = useRef(null);

  // Get unread message count
  useEffect(() => {
    if (chatClient && chatClient.connectionState === 'OPEN') {
      // Initial count
      const getTotalUnreadCount = async () => {
        try {
          // Get all channels first
          const groupChannelQuery = chatClient.groupChannel.createMyGroupChannelListQuery();
          groupChannelQuery.limit = 100;
          
          const channels = await groupChannelQuery.next();
          
          // Calculate total unread count from all channels
          let totalCount = 0;
          channels.forEach(channel => {
            totalCount += channel.unreadMessageCount;
          });
          
          setUnreadMessageCount(totalCount);
          console.log(`[Navbar] Total unread messages: ${totalCount}`);
        } catch (error) {
          console.error('Error getting unread message count:', error);
        }
      };
      
      // Get initial count
      getTotalUnreadCount();
      
      // Set up polling to check for new messages every 5 seconds
      const intervalId = setInterval(() => {
        getTotalUnreadCount();
      }, 5000);
      
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [chatClient]);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const attributes = await fetchUserAttributes();
        setUserEmail(attributes.email);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };
    getUserInfo();
  }, []);

  // Update current org when organizations change
  useEffect(() => {
    if (organizations && organizations.length > 0) {
      // Set current org to the one from localStorage or the first one
      const savedOrgId = localStorage.getItem('currentOrgId');
      const defaultOrg = savedOrgId 
        ? organizations.find(org => org.id === savedOrgId) 
        : organizations[0];
      
      if (defaultOrg) {
        setCurrentOrg(defaultOrg);
        localStorage.setItem('currentOrgId', defaultOrg.id);
      }
      
      // Initialize filtered orgs
      setFilteredOrgs(organizations);
    }
  }, [organizations]);

  // Filter organizations when search term changes
  useEffect(() => {
    if (!organizations) return;
    
    if (orgSearchTerm.trim() === '') {
      setFilteredOrgs(organizations);
    } else {
      const filtered = organizations.filter(org => 
        org.name.toLowerCase().includes(orgSearchTerm.toLowerCase())
      );
      setFilteredOrgs(filtered);
    }
  }, [orgSearchTerm, organizations]);
  
  // Focus search input when dropdown opens
  useEffect(() => {
    if (showOrgDropdown && orgSearchInputRef.current) {
      setTimeout(() => {
        orgSearchInputRef.current.focus();
      }, 100);
    } else {
      // Reset search when dropdown closes
      setOrgSearchTerm('');
    }
  }, [showOrgDropdown]);

  const handleOrgChange = async (org) => {
    try {
      // Set loading state if needed
      
      // Get organization details from orgConfig
      const orgConfig = await getOrgConfig();
      
      // Find the matching organization in orgConfig using orgId field
      const selectedOrgConfig = orgConfig.find(config => config.orgId === org.id);
      
      if (!selectedOrgConfig) {
        console.error(`Organization ${org.id} not found in orgConfig`);
        return;
      }
      
      // Store the workspaceId along with the organization ID
      localStorage.setItem('currentOrgId', org.id);
      localStorage.setItem('currentOrgWorkspaceId', selectedOrgConfig.workspaceId);
      
      // Update current org state
      setCurrentOrg(org);
      setShowOrgDropdown(false);
      
      // Update user settings in localStorage to ensure consistent schema
      const userSettingsStr = localStorage.getItem('userSettings');
      if (userSettingsStr) {
        try {
          const userSettings = JSON.parse(userSettingsStr);
          
          // Ensure the current organization has the correct schema
          if (userSettings.orgAccess && userSettings.orgAccess[org.id]) {
            const orgAccess = userSettings.orgAccess[org.id];
            
            // Ensure all required properties exist
            userSettings.orgAccess[org.id] = {
              reportIds: orgAccess.reportIds || {},
              dashboardIds: orgAccess.dashboardIds || [],
              entityIds: orgAccess.entityIds || [],
              dashboards: orgAccess.dashboards || [],
              reports: orgAccess.reports || [],
              entities: orgAccess.entities || []
            };
            
            // Ensure all reports have entries in reportIds
            if (orgAccess.reports && orgAccess.reports.length > 0) {
              orgAccess.reports.forEach(reportId => {
                if (!userSettings.orgAccess[org.id].reportIds[reportId]) {
                  userSettings.orgAccess[org.id].reportIds[reportId] = { Pages: [] };
                }
              });
            }
            
            // Ensure all dashboards have entries in dashboardIds
            if (orgAccess.dashboards && orgAccess.dashboards.length > 0) {
              orgAccess.dashboards.forEach(dashboardId => {
                if (!userSettings.orgAccess[org.id].dashboardIds.includes(dashboardId)) {
                  userSettings.orgAccess[org.id].dashboardIds.push(dashboardId);
                }
              });
            }
            
            // Save updated user settings
            localStorage.setItem('userSettings', JSON.stringify(userSettings));
          }
        } catch (error) {
          console.error('Error updating user settings:', error);
        }
      }
      
      // Check if already on dashboard
      if (location.pathname === '/dashboard') {
        // If already on dashboard, reload the page to refresh data
        window.location.reload();
      } else {
        // Otherwise navigate to dashboard
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error changing organization:', error);
      // Handle error (show toast notification, etc.)
    }
    
    // Reset org search term
    setOrgSearchTerm('');
  };

  const clearOrgSearch = () => {
    setOrgSearchTerm('');
    if (orgSearchInputRef.current) {
      orgSearchInputRef.current.focus();
    }
  };

  useEffect(() => {
    // Handle click outside of organization dropdown
    const handleClickOutsideOrg = (event) => {
      if (orgDropdownRef.current && !orgDropdownRef.current.contains(event.target)) {
        setShowOrgDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideOrg);
    return () => document.removeEventListener('mousedown', handleClickOutsideOrg);
  }, []);

  useEffect(() => {
    const fetchSearchItems = async () => {
      try {
        const orgConfig = await getOrgConfig();
        const wsId = orgConfig[0].workspaceId;
        
        const reportsResponse = await getReports(wsId);
        
        // Get pages for all reports and flatten into searchable items
        const items = await Promise.all(
          reportsResponse.value.map(async (report) => {
            try {
              const pagesResponse = await getReportPages(report.id, wsId);
              const pages = pagesResponse.value.map(page => ({
                id: `${report.id}-${page.name}`,
                type: 'page',
                name: page.displayName,
                reportName: report.name,
                path: `/report/${report.id}/page/${page.name}`,
                reportId: report.id
              }));

              // Add report as a searchable item
              return [
                {
                  id: report.id,
                  type: 'report',
                  name: report.name,
                  path: `/report/${report.id}/page/${pagesResponse.value[0]?.name}`,
                },
                ...pages
              ];
            } catch (error) {
              console.error(`Error fetching pages for report ${report.id}:`, error);
              return [{
                id: report.id,
                type: 'report',
                name: report.name,
                path: `/report/${report.id}`,
              }];
            }
          })
        );

        setAllItems(items.flat());
      } catch (error) {
        console.error('Error fetching search items:', error);
      }
    };

    fetchSearchItems();
  }, []);

  useEffect(() => {
    // Handle click outside of search results
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Reset search when route changes
  useEffect(() => {
    setSearchTerm('');
    setSearchResults([]);
    setShowResults(false);
  }, [location.pathname]);

  const handleSearch = (value) => {
    setSearchTerm(value);
    if (value.trim() === '') {
      setSearchResults([]);
      return;
    }

    const filtered = allItems.filter(item => 
      item.name.toLowerCase().includes(value.toLowerCase()) ||
      (item.type === 'page' && item.reportName.toLowerCase().includes(value.toLowerCase()))
    );

    setSearchResults(filtered);
    setShowResults(true);
  };

  const handleResultClick = (item) => {
    navigate(item.path);
    setSearchTerm('');
    setShowResults(false);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className={`fixed top-0 h-16 bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm border-b border-gray-200/50 dark:border-gray-700/50 flex items-center justify-between px-6 z-10 transition-all duration-300
      ${isSidebarCollapsed ? 'left-20' : 'left-64'} right-0`}>
      <div className="flex items-center flex-1">
        {/* Organization Dropdown - Only show if user has more than one organization and not on settings page */}
        {organizations.length > 1 && !location.pathname.startsWith('/settings') && (
          <div className="relative mr-4" ref={orgDropdownRef}>
            <button
              onClick={() => setShowOrgDropdown(!showOrgDropdown)}
              className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
            >
              {currentOrg?.name || 'Select Organization'}
              <ChevronDown size={16} className="ml-2" />
            </button>
            
            {showOrgDropdown && (
              <div className="absolute left-0 mt-2 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 z-20 max-h-80 flex flex-col">
                {/* Search input */}
                <div className="p-2 border-b border-gray-200 dark:border-gray-700 sticky top-0 bg-white dark:bg-gray-800">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                    <input
                      ref={orgSearchInputRef}
                      type="text"
                      value={orgSearchTerm}
                      onChange={(e) => setOrgSearchTerm(e.target.value)}
                      placeholder="Search organizations..."
                      className="w-full pl-9 pr-8 py-2 border border-gray-300 dark:border-gray-600 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 dark:bg-gray-700 dark:text-gray-200"
                    />
                    {orgSearchTerm && (
                      <button 
                        onClick={clearOrgSearch}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      >
                        <X className="h-4 w-4 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300" />
                      </button>
                    )}
                  </div>
                </div>
                
                {/* Organizations list */}
                <div className="overflow-y-auto flex-1 max-h-60">
                  {filteredOrgs.length > 0 ? (
                    filteredOrgs.map((org) => (
                      <button
                        key={org.id}
                        onClick={() => handleOrgChange(org)}
                        className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors
                          ${currentOrg?.id === org.id ? 'bg-orange-50 dark:bg-orange-900/20 text-orange-600 dark:text-orange-400' : 'text-gray-700 dark:text-gray-300'}`}
                      >
                        {org.name}
                      </button>
                    ))
                  ) : (
                    <div className="p-3 text-center text-gray-500 dark:text-gray-400">
                      No organizations found
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        
        <div className="max-w-md w-full" ref={searchRef}>
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              onFocus={() => setShowResults(true)}
              placeholder="Search reports and pages..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500"
            />
            
            {/* Search Results Dropdown */}
            {showResults && searchResults.length > 0 && (
              <div className="absolute top-full left-0 right-0 mt-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-y-auto">
                {searchResults.map((item) => (
                  <div
                    key={item.id}
                    onClick={() => handleResultClick(item)}
                    className="px-4 py-2 hover:bg-gray-50 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <Search size={16} className="text-gray-400 mr-2" />
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {item.name}
                        </div>
                        {item.type === 'page' && (
                          <div className="text-xs text-gray-500">
                            in {item.reportName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div className="flex items-center space-x-4">
        <button className="p-2 hover:bg-gray-100 rounded-lg">
          <HelpCircle size={20} className="text-gray-600" />
        </button>
        <button 
          className="p-2 hover:bg-gray-100 rounded-lg relative"
          onClick={toggleChat}
        >
          <MessageSquare size={20} className="text-gray-600" />
          {unreadMessageCount > 0 && (
            <span className="absolute -top-1 -right-1 flex items-center justify-center min-w-[18px] h-[18px] px-1 bg-orange-500 text-white text-xs font-medium rounded-full">
              {unreadMessageCount > 99 ? '99+' : unreadMessageCount}
            </span>
          )}
        </button>
        <button className="p-2 hover:bg-gray-100 rounded-lg relative">
          <Bell size={20} className="text-gray-600" />
          <span className="absolute top-1.5 right-1.5 w-2 h-2 bg-orange-500 rounded-full"></span>
        </button>
        <div className="relative" ref={profileRef}>
          <button 
            className="h-8 w-8 bg-gray-200 rounded-full cursor-pointer hover:ring-2 hover:ring-orange-500 hover:ring-offset-2"
            onClick={() => setShowProfileMenu(!showProfileMenu)}
          />
          
          {showProfileMenu && (
            <div className="absolute right-0 mt-2 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 py-2">
              {userEmail && (
                <div className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400 border-b border-gray-100 dark:border-gray-700">
                  {userEmail}
                </div>
              )}
              {isAdmin && (
                <button
                  onClick={() => navigate('/admin')}
                  className="w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100/80 dark:hover:bg-white/5 flex items-center transition-colors duration-150"
                >
                  <Settings size={16} className="mr-2 text-gray-600 dark:text-gray-400" />
                  Admin Console
                </button>
              )}
              <button
                onClick={handleLogout}
                className="w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100/80 dark:hover:bg-white/5 flex items-center transition-colors duration-150"
              >
                <LogOut size={16} className="mr-2 text-gray-600 dark:text-gray-400" />
                Sign out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar; 