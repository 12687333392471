import { useState, useEffect } from 'react';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { getUserSettings, getOrgConfig } from '../utils/api';

const USER_SETTINGS_KEY = 'userSettings';

export const useUserSettings = () => {
  const [userSettings, setUserSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [organizations, setOrganizations] = useState([]);

  // Load settings from localStorage
  useEffect(() => {
    const loadSettings = async () => {
      const storedSettings = localStorage.getItem(USER_SETTINGS_KEY);
      if (storedSettings) {
        try {
          const parsedSettings = JSON.parse(storedSettings);
          
          // Ensure isAdmin is properly set (default to 0 if not present)
          parsedSettings.isAdmin = parsedSettings.isAdmin || 0;
          
          // Ensure the orgAccess structure is correct for each organization
          if (parsedSettings && parsedSettings.orgAccess) {
            Object.keys(parsedSettings.orgAccess).forEach(orgId => {
              const org = parsedSettings.orgAccess[orgId];
              
              // Ensure all required properties exist
              parsedSettings.orgAccess[orgId] = {
                reportIds: org.reportIds || {},
                dashboardIds: org.dashboardIds || [],
                entityIds: org.entityIds || [],
                dashboards: org.dashboards || [],
                reports: org.reports || [],
                entities: org.entities || []
              };
              
              // Ensure all reports have entries in reportIds
              if (org.reports && org.reports.length > 0) {
                org.reports.forEach(reportId => {
                  if (!parsedSettings.orgAccess[orgId].reportIds[reportId]) {
                    parsedSettings.orgAccess[orgId].reportIds[reportId] = { Pages: [] };
                  }
                });
              }
              
              // Ensure all dashboards have entries in dashboardIds
              if (org.dashboards && org.dashboards.length > 0) {
                org.dashboards.forEach(dashboardId => {
                  if (!parsedSettings.orgAccess[orgId].dashboardIds.includes(dashboardId)) {
                    parsedSettings.orgAccess[orgId].dashboardIds.push(dashboardId);
                  }
                });
              }
            });
          }
          
          setUserSettings(parsedSettings);
          
          // Extract organizations from orgAccess
          if (parsedSettings && parsedSettings.orgAccess) {
            // Get current org ID from localStorage
            const currentOrgId = localStorage.getItem('currentOrgId');
            
            // Get org details from orgConfig to include workspaceId
            try {
              const orgConfig = await getOrgConfig();
              
              const orgs = Object.keys(parsedSettings.orgAccess).map(orgId => {
                // Find matching org in orgConfig to get workspaceId
                const orgConfigItem = orgConfig.find(config => config.orgId === orgId);
                
                return {
                  id: orgId,
                  name: orgId, // Using orgId as name since we don't have a separate name field
                  access: parsedSettings.orgAccess[orgId],
                  workspaceId: orgConfigItem ? orgConfigItem.workspaceId : null
                };
              });
              
              setOrganizations(orgs);
              
              // If we have a current org, ensure its workspaceId is stored
              if (currentOrgId) {
                const currentOrg = orgs.find(org => org.id === currentOrgId);
                if (currentOrg && currentOrg.workspaceId) {
                  localStorage.setItem('currentOrgWorkspaceId', currentOrg.workspaceId);
                }
              }
            } catch (error) {
              console.error('Error fetching org config:', error);
            }
          }
        } catch (err) {
          console.error('Error parsing stored user settings:', err);
        }
      }
      setIsLoading(false);
    };
    
    loadSettings();
  }, []);

  // Function to fetch user settings from API
  const fetchSettings = async (forceRefresh = false) => {
    setIsLoading(true);
    setError(null);
    
    try {
      // Get user email from Cognito
      const attributes = await fetchUserAttributes();
      const userEmail = attributes.email;
      
      if (!userEmail) {
        throw new Error('User email not found');
      }
      
      // Fetch user settings from API using email
      const settings = await getUserSettings(userEmail);
      
      // Ensure isAdmin is properly set (default to 0 if not present)
      settings.isAdmin = settings.isAdmin || 0;
      
      // Extract organizations from orgAccess and get workspaceIds from orgConfig
      if (settings && settings.orgAccess) {
        try {
          // Get org details from orgConfig
          const orgConfig = await getOrgConfig();
          
          // Ensure the orgAccess structure is correct for each organization
          Object.keys(settings.orgAccess).forEach(orgId => {
            const org = settings.orgAccess[orgId];
            
            // Ensure all required properties exist
            settings.orgAccess[orgId] = {
              reportIds: org.reportIds || {},
              dashboardIds: org.dashboardIds || [],
              entityIds: org.entityIds || [],
              dashboards: org.dashboards || [],
              reports: org.reports || [],
              entities: org.entities || []
            };
            
            // Ensure all reports have entries in reportIds
            if (org.reports && org.reports.length > 0) {
              org.reports.forEach(reportId => {
                if (!settings.orgAccess[orgId].reportIds[reportId]) {
                  settings.orgAccess[orgId].reportIds[reportId] = { Pages: [] };
                }
              });
            }
            
            // Ensure all dashboards have entries in dashboardIds
            if (org.dashboards && org.dashboards.length > 0) {
              org.dashboards.forEach(dashboardId => {
                if (!settings.orgAccess[orgId].dashboardIds.includes(dashboardId)) {
                  settings.orgAccess[orgId].dashboardIds.push(dashboardId);
                }
              });
            }
          });
          
          const orgs = Object.keys(settings.orgAccess).map(orgId => {
            // Find matching org in orgConfig to get workspaceId
            const orgConfigItem = orgConfig.find(config => config.orgId === orgId);
            
            return {
              id: orgId,
              name: orgId, // Using orgId as name since we don't have a separate name field
              access: settings.orgAccess[orgId],
              workspaceId: orgConfigItem ? orgConfigItem.workspaceId : null
            };
          });
          
          setOrganizations(orgs);
          
          // Store in state and localStorage
          setUserSettings(settings);
          localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(settings));
          
          // Also store current organization ID and workspaceId for easy access
          if (orgs.length > 0) {
            // Use the first organization as default if none is selected
            const currentOrgId = localStorage.getItem('currentOrgId') || orgs[0].id;
            const currentOrg = orgs.find(org => org.id === currentOrgId) || orgs[0];
            
            localStorage.setItem('currentOrgId', currentOrg.id);
            
            // Store workspaceId if available
            if (currentOrg.workspaceId) {
              localStorage.setItem('currentOrgWorkspaceId', currentOrg.workspaceId);
            }
          }
        } catch (error) {
          console.error('Error fetching org config:', error);
        }
      }
      
      return settings;
    } catch (err) {
      console.error('Error fetching user settings:', err);
      setError(err.message || 'Failed to fetch user settings');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // Function to update user settings
  const updateSettings = async (newSettings) => {
    setIsLoading(true);
    setError(null);
    
    try {
      // Merge with existing settings
      const updatedSettings = { ...userSettings, ...newSettings };
      
      // Ensure isAdmin is properly set (default to 0 if not present)
      updatedSettings.isAdmin = updatedSettings.isAdmin || 0;
      
      // Ensure the orgAccess structure is correct for each organization
      if (updatedSettings.orgAccess) {
        Object.keys(updatedSettings.orgAccess).forEach(orgId => {
          const org = updatedSettings.orgAccess[orgId];
          
          // Ensure all required properties exist
          updatedSettings.orgAccess[orgId] = {
            reportIds: org.reportIds || {},
            dashboardIds: org.dashboardIds || [],
            entityIds: org.entityIds || [],
            dashboards: org.dashboards || [],
            reports: org.reports || [],
            entities: org.entities || []
          };
          
          // Ensure all reports have entries in reportIds
          if (org.reports && org.reports.length > 0) {
            org.reports.forEach(reportId => {
              if (!updatedSettings.orgAccess[orgId].reportIds[reportId]) {
                updatedSettings.orgAccess[orgId].reportIds[reportId] = { Pages: [] };
              }
            });
          }
          
          // Ensure all dashboards have entries in dashboardIds
          if (org.dashboards && org.dashboards.length > 0) {
            org.dashboards.forEach(dashboardId => {
              if (!updatedSettings.orgAccess[orgId].dashboardIds.includes(dashboardId)) {
                updatedSettings.orgAccess[orgId].dashboardIds.push(dashboardId);
              }
            });
          }
        });
      }
      
      // Store in state and localStorage
      setUserSettings(updatedSettings);
      localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(updatedSettings));
      
      // Update organizations if orgAccess changed
      if (newSettings.orgAccess) {
        try {
          // Get org details from orgConfig
          const orgConfig = await getOrgConfig();
          
          const orgs = Object.keys(updatedSettings.orgAccess).map(orgId => {
            // Find matching org in orgConfig to get workspaceId
            const orgConfigItem = orgConfig.find(config => config.orgId === orgId);
            
            return {
              id: orgId,
              name: orgId,
              access: updatedSettings.orgAccess[orgId],
              workspaceId: orgConfigItem ? orgConfigItem.workspaceId : null
            };
          });
          
          setOrganizations(orgs);
          
          // Update current org workspaceId if needed
          const currentOrgId = localStorage.getItem('currentOrgId');
          if (currentOrgId) {
            const currentOrg = orgs.find(org => org.id === currentOrgId);
            if (currentOrg && currentOrg.workspaceId) {
              localStorage.setItem('currentOrgWorkspaceId', currentOrg.workspaceId);
            }
          }
        } catch (error) {
          console.error('Error fetching org config:', error);
        }
      }
      
      return updatedSettings;
    } catch (err) {
      console.error('Error updating user settings:', err);
      setError(err.message || 'Failed to update user settings');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    userSettings,
    organizations,
    isLoading,
    error,
    fetchSettings,
    updateSettings
  };
}; 