import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useLocation, Link, useNavigate } from 'react-router-dom';
import { User, Bell, Shield, Users, Wallet, Globe, Smartphone, Keyboard, Moon, ChevronLeft, ChevronRight, LogOut, Search, X, Settings } from 'lucide-react';
import { useUserSettingsContext } from '../../context/UserSettingsContext';
import { getOrgConfig } from '../../utils/api';
import { signOut } from '@aws-amplify/auth';

const SettingsLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { organizations, userSettings } = useUserSettingsContext();
  const [currentOrg, setCurrentOrg] = useState(null);
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOrgs, setFilteredOrgs] = useState([]);
  const searchInputRef = useRef(null);
  
  // Check if user is admin
  const isAdmin = userSettings && userSettings.isAdmin === 1;

  // Check if device is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Update current org when organizations change
  useEffect(() => {
    if (organizations && organizations.length > 0) {
      // Set current org to the one from localStorage or the first one
      const savedOrgId = localStorage.getItem('currentOrgId');
      const defaultOrg = savedOrgId 
        ? organizations.find(org => org.id === savedOrgId) 
        : organizations[0];
      
      if (defaultOrg) {
        setCurrentOrg(defaultOrg);
      }
      
      // Initialize filtered orgs
      setFilteredOrgs(organizations);
    }
  }, [organizations]);
  
  // Filter organizations when search term changes
  useEffect(() => {
    if (!organizations) return;
    
    if (searchTerm.trim() === '') {
      setFilteredOrgs(organizations);
    } else {
      const filtered = organizations.filter(org => 
        org.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOrgs(filtered);
    }
  }, [searchTerm, organizations]);
  
  // Focus search input when dropdown opens
  useEffect(() => {
    if (showOrgDropdown && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 100);
    }
  }, [showOrgDropdown]);

  const handleOrgChange = async (org) => {
    try {
      // Get organization details from orgConfig
      const orgConfig = await getOrgConfig();
      
      // Find the matching organization in orgConfig using orgId field
      const selectedOrgConfig = orgConfig.find(config => config.orgId === org.id);
      
      if (!selectedOrgConfig) {
        console.error(`Organization ${org.id} not found in orgConfig`);
        return;
      }
      
      // Store the workspaceId along with the organization ID
      localStorage.setItem('currentOrgId', org.id);
      localStorage.setItem('currentOrgWorkspaceId', selectedOrgConfig.workspaceId);
      
      // Update current org state
      setCurrentOrg(org);
      setShowOrgDropdown(false);
      setSearchTerm('');
      
      // Check if already on dashboard
      if (location.pathname === '/dashboard') {
        // If already on dashboard, reload the page to refresh data
        window.location.reload();
      } else {
        // Otherwise navigate to dashboard
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error changing organization:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  
  const clearSearch = () => {
    setSearchTerm('');
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  
  const menuItems = [
    {
      section: "PROFILE",
      items: [
        { icon: User, label: "Email Preferences", path: "/settings/email" },
        { icon: Bell, label: "Notification Settings", path: "/settings/notifications" },
        { icon: Shield, label: "Privacy & Security", path: "/settings/privacy" }
      ]
    },
    {
      section: "APPEARANCE",
      items: [
        { icon: Moon, label: "Theme Settings", path: "/settings/appearance" }
      ]
    },
    {
      section: "ORGANIZATION",
      items: [
        { icon: Users, label: "Team Management", path: "/settings/team" },
        { icon: Wallet, label: "Billing & Plans", path: "/settings/billing" },
        { icon: Globe, label: "Workspace Settings", path: "/settings/workspace" }
      ]
    },
    {
      section: "SYSTEM",
      items: [
        { icon: Smartphone, label: "Device Settings", path: "/settings/devices" },
        { icon: Keyboard, label: "Keyboard Shortcuts", path: "/settings/shortcuts" }
      ]
    }
  ];

  // Find the current section and item for mobile breadcrumb
  const currentPath = location.pathname;

  // Organization dropdown with search for both mobile and desktop
  const OrganizationDropdown = () => (
    <div className="absolute top-full left-0 right-0 mt-1 bg-white border border-gray-200 rounded-lg shadow-lg z-10 max-h-80 flex flex-col">
      {/* Search input */}
      <div className="p-2 border-b border-gray-200 sticky top-0 bg-white">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
          <input
            ref={searchInputRef}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search organizations..."
            className="w-full pl-9 pr-8 py-2 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
          />
          {searchTerm && (
            <button 
              onClick={clearSearch}
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              <X className="h-4 w-4 text-gray-400 hover:text-gray-600" />
            </button>
          )}
        </div>
      </div>
      
      {/* Organizations list */}
      <div className="overflow-y-auto flex-1 max-h-60">
        {filteredOrgs.length > 0 ? (
          filteredOrgs.map((org) => (
            <button
              key={org.id}
              onClick={() => handleOrgChange(org)}
              className={`w-full text-left p-3 hover:bg-gray-100 transition-colors ${
                currentOrg?.id === org.id ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
              }`}
            >
              {org.name}
            </button>
          ))
        ) : (
          <div className="p-3 text-center text-gray-500">
            No organizations found
          </div>
        )}
      </div>
    </div>
  );

  // Mobile Layout
  if (isMobile) {
    return (
      <div className="min-h-screen bg-gray-50">
        {/* Mobile Header - Only show on settings index */}
        {currentPath === '/settings' && (
          <div className="px-4 py-6">
            <h1 className="text-2xl font-bold text-gray-900">Settings</h1>
            
            {/* Organization Selector for Mobile */}
            {organizations && organizations.length > 0 && (
              <div className="mt-6 relative">
                <button
                  onClick={() => setShowOrgDropdown(!showOrgDropdown)}
                  className="w-full flex items-center justify-between p-3 rounded-lg bg-white border border-gray-200 text-gray-700"
                >
                  <span>{currentOrg?.name || 'Select Organization'}</span>
                  <ChevronRight className="w-5 h-5 text-gray-400" />
                </button>
                
                {showOrgDropdown && <OrganizationDropdown />}
              </div>
            )}
          </div>
        )}
        
        {/* Mobile Breadcrumb - Only show on specific settings pages */}
        {currentPath !== '/settings' && (
          <div className="px-4 py-3 flex items-center border-b border-gray-200">
            <button 
              onClick={() => navigate('/settings')}
              className="flex items-center text-gray-600"
            >
              <ChevronLeft className="w-5 h-5 mr-1" />
              <span>Settings</span>
            </button>
          </div>
        )}
        
        {currentPath === '/settings' ? (
          /* Settings Menu List for Mobile */
          <div className="px-4 pb-20">
            {menuItems.map((section) => (
              <div key={section.section} className="mb-8">
                <h2 className="text-sm font-medium text-gray-500 mb-2">{section.section}</h2>
                <div className="bg-white rounded-xl overflow-hidden shadow-sm border border-gray-200">
                  {section.items.map((item, index) => (
                    <Link
                      key={item.path}
                      to={item.path}
                      className={`
                        flex items-center justify-between p-4 text-gray-700
                        ${index !== section.items.length - 1 ? 'border-b border-gray-200' : ''}
                      `}
                    >
                      <div className="flex items-center">
                        <item.icon className="w-5 h-5 text-gray-500 mr-3" />
                        <span>{item.label}</span>
                      </div>
                      <ChevronRight className="w-5 h-5 text-gray-400" />
                    </Link>
                  ))}
                </div>
              </div>
            ))}
            
            {/* Admin Console Button - Only show if user is admin */}
            {isAdmin && (
              <div className="mb-4">
                <button
                  onClick={() => navigate('/admin')}
                  className="w-full flex items-center justify-between p-4 text-gray-700 bg-white rounded-xl shadow-sm border border-gray-200"
                >
                  <div className="flex items-center">
                    <Settings className="w-5 h-5 text-gray-500 mr-3" />
                    <span className="font-medium">Admin Console</span>
                  </div>
                  <ChevronRight className="w-5 h-5 text-gray-400" />
                </button>
              </div>
            )}
            
            {/* Logout Button for Mobile */}
            <div className="mb-20">
              <button
                onClick={handleLogout}
                className="w-full flex items-center justify-between p-4 text-red-600 bg-white rounded-xl shadow-sm border border-gray-200"
              >
                <div className="flex items-center">
                  <LogOut className="w-5 h-5 mr-3" />
                  <span className="font-medium">Sign Out</span>
                </div>
              </button>
            </div>
          </div>
        ) : (
          /* Content Area for Mobile */
          <div className="px-4 pb-20">
            <Outlet />
          </div>
        )}
      </div>
    );
  }

  // Desktop Layout
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex gap-8">
          {/* Sidebar Navigation */}
          <div className="w-64 flex-shrink-0">
            {/* Organization Selector for Desktop - Removed to avoid duplication with navbar */}
            
            <nav className="space-y-8">
              {menuItems.map((section) => (
                <div key={section.section}>
                  <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400 uppercase tracking-wider mb-3">
                    {section.section}
                  </h3>
                  <div className="space-y-1">
                    {section.items.map((item) => (
                      <Link
                        key={item.path}
                        to={item.path}
                        className={`
                          flex items-center px-3 py-2 text-sm rounded-lg transition-colors
                          ${location.pathname === item.path
                            ? 'bg-white dark:bg-gray-800 text-gray-900 dark:text-white shadow-sm'
                            : 'text-gray-700 dark:text-gray-300 hover:bg-white dark:hover:bg-gray-800/50 hover:text-gray-900 dark:hover:text-white'
                          }
                        `}
                      >
                        <item.icon className={`w-5 h-5 mr-3 ${
                          location.pathname === item.path
                            ? 'text-gray-900 dark:text-white'
                            : 'text-gray-500 dark:text-gray-400'
                        }`} />
                        {item.label}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </nav>
          </div>

          {/* Content Area */}
          <div className="flex-1 min-w-0">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout; 