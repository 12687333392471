import React, { createContext, useContext } from 'react';
import { useUserSettings } from '../hooks/useUserSettings';

// Create the context
const UserSettingsContext = createContext(null);

// Create a provider component
export const UserSettingsProvider = ({ children }) => {
  const userSettingsHook = useUserSettings();
  
  return (
    <UserSettingsContext.Provider value={userSettingsHook}>
      {children}
    </UserSettingsContext.Provider>
  );
};

// Create a hook to use the context
export const useUserSettingsContext = () => {
  const context = useContext(UserSettingsContext);
  if (!context) {
    throw new Error('useUserSettingsContext must be used within a UserSettingsProvider');
  }
  return context;
}; 