// src/utils/api.js
import axios from 'axios';
import API_ENDPOINTS from '../config/apiConfig';
import { BASE_URL } from '../config/apiConfig';

// Create axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // or your API base URL
});

// Interceptor for adding authorization tokens
api.interceptors.request.use((config) => {
    // Find the access token from localStorage
    const accessToken = Object.keys(localStorage)
        .find(key => key.endsWith('.accessToken'));
    console.log('accessToken', accessToken);
    if (accessToken) {
        const token = localStorage.getItem(accessToken);
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export const getChartOfAccounts = async () => {
    return api.get(API_ENDPOINTS.GET_CHARTOFACCOUNTS).then((response) => response.data);
}

export const getOrgConfig = async () => {
    return api.get(API_ENDPOINTS.GET_ORG_CONFIG).then((response) => response.data);
};

export const getUserSettings = async (email) => {
    return api.get(API_ENDPOINTS.GET_USER_SETTINGS(email)).then((response) => response.data);
};

export const getDashboards = async (workspaceId) => {
    return api.get(API_ENDPOINTS.GET_DASHBOARDS(workspaceId)).then((response) => response.data);
};

// Use axios for all API calls
export const getReports = async (workspaceId) => {
    const url = API_ENDPOINTS.GET_REPORTS(workspaceId);
    const response = await api.get(url);
    return response.data; // Axios puts the response data in `data`
};

export const getReportPages = async (reportId, workspaceId) => {
    console.log('Fetching pages for report:', { reportId, workspaceId });
    try {
        const response = await api.get(
            API_ENDPOINTS.GET_REPORT_PAGES(reportId, workspaceId)
        );
        console.log('Report pages response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching report pages:', error);
        throw error;
    }
};

export const getDatasets = async () => {
    return api.get(API_ENDPOINTS.GET_DATASETS).then((response) => response.data);
};

export const postItem = async (data) => {
    return api.post(API_ENDPOINTS.POST_ITEM, data).then((response) => response.data);
};

export const getDashboardEmbedInfo = async (dashboardId) => {
    try {
        console.log('Fetching dashboard embed info for:', dashboardId);

        const orgConfig = await getOrgConfig();
        const workspaceId = orgConfig[0].workspaceId;

        const dashboardResponse = await getDashboards(workspaceId);
        const dashboard = dashboardResponse.value.find(d => d.id === dashboardId);

        if (!dashboard) {
            throw new Error('Dashboard not found');
        }

        return {
            embedUrl: dashboard.embedUrl,
            accessToken: 'YOUR_ACCESS_TOKEN',
            id: dashboard.id
        };

    } catch (error) {
        console.error('API Error:', error);
        console.error('Full error details:', {
            message: error.message,
            stack: error.stack
        });
        throw error;
    }
};

export const getEmbedToken = async (reportId, workspaceId, body) => {
    try {
        console.log('Fetching embed token for report:', { reportId, workspaceId });

        const response = await api.post(API_ENDPOINTS.GET_EMBED_TOKEN, {
            reportId,
            workspaceId,
            ...body
        });

        if (!response.data || !response.data.token) {
            throw new Error('Failed to fetch embed token');
        }

        console.log('Embed token response:', response.data);
        return response.data.token;

    } catch (error) {
        console.error('Error fetching embed token:', error);
        throw error;
    }
};

export const fetchPdfFromBlob = async (blobUrl, embedToken) => {
    try {
        // Decode any HTML entities in the URL (convert &amp; to &)
        const decodedUrl = new DOMParser().parseFromString(blobUrl, 'text/html').documentElement.textContent;
        
        // Use our backend proxy to fetch the PDF
        const response = await api.post(API_ENDPOINTS.GET_PDF_PROXY, {
            url: decodedUrl,
            embedToken
        }, {
            responseType: 'blob'
        });
        
        if (!response.data) {
            throw new Error('No data received from proxy');
        }
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        return URL.createObjectURL(blob);
    } catch (error) {
        console.error('Error fetching PDF:', error);
        throw error;
    }
};

// Get all users
export const getAllUsers = async () => {
  try {
    const response = await api.get(`${BASE_URL}/userSettings`);
    // Ensure we always return an array
    if (Array.isArray(response.data)) {
      return response.data;
    } else if (response.data && typeof response.data === 'object') {
      // If it's a single user object, wrap it in an array
      return [response.data];
    } else {
      // If it's neither an array nor an object, return an empty array
      console.warn('Unexpected response type from userSettings API:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching all users:', error);
    throw error;
  }
};

// Create or update a user
export const saveUserSettings = async (userData) => {
  try {
    // Ensure the orgAccess structure is correct for each organization
    if (userData.orgAccess) {
      Object.keys(userData.orgAccess).forEach(orgId => {
        const org = userData.orgAccess[orgId];
        
        // Ensure all required properties exist
        userData.orgAccess[orgId] = {
          reportIds: org.reportIds || {},
          dashboardIds: org.dashboardIds || [],
          entityIds: org.entityIds || [],
          dashboards: org.dashboards || [],
          reports: org.reports || [],
          entities: org.entities || []
        };
        
        // Ensure all reports have entries in reportIds
        (org.reports || []).forEach(reportId => {
          if (!userData.orgAccess[orgId].reportIds[reportId]) {
            userData.orgAccess[orgId].reportIds[reportId] = { Pages: [] };
          }
        });
        
        // Ensure all dashboards have entries in dashboardIds
        (org.dashboards || []).forEach(dashboardId => {
          if (!userData.orgAccess[orgId].dashboardIds.includes(dashboardId)) {
            userData.orgAccess[orgId].dashboardIds.push(dashboardId);
          }
        });
      });
    }
    
    // Use email as the ID
    userData.id = userData.email;
    
    // Initialize isDeleted field for new users
    if (userData.isDeleted === undefined) {
      userData.isDeleted = 0;
    }
    
    // Check if this is a new user or an existing user
    // We'll determine this by checking if the user has an _etag property
    // which would only exist for users that have been retrieved from the database
    const isNewUser = !userData._etag;
    
    // If user doesn't have a cognitoId yet but has a cognitoUsername, use that
    if (!userData.cognitoId && userData.cognitoUsername) {
      userData.cognitoId = userData.cognitoUsername;
    }
    
    let savedUserData;
    
    if (isNewUser) {
      console.log(`Creating new user with POST:`, JSON.stringify(userData, null, 2));
      const response = await api.post(`${BASE_URL}/userSettings`, userData);
      savedUserData = response.data;
    } else {
      console.log(`Updating existing user with PATCH:`, JSON.stringify(userData, null, 2));
      const response = await api.patch(`${BASE_URL}/userSettings`, userData);
      savedUserData = response.data;
    }
    
    // Also upsert the user in Sendbird
    try {
      await upsertSendbirdUser(userData);
    } catch (sendbirdError) {
      console.error('Error upserting user in Sendbird:', sendbirdError);
      // Don't throw the error, just log it - we don't want to block the main user save operation
    }
    
    return savedUserData;
  } catch (error) {
    console.error('Error saving user settings:', error);
    throw error;
  }
};

// Soft delete a user (mark as deleted)
export const deleteUserSettings = async (userId) => {
  try {
    // First, get the current user data
    const currentUserResponse = await api.get(`${BASE_URL}/userSettings?id=${encodeURIComponent(userId)}`);
    const currentUser = currentUserResponse.data;
    
    if (!currentUser) {
      throw new Error(`User with ID ${userId} not found`);
    }
    
    // Only update the isDeleted field, preserving all other data
    const updatedUser = {
      ...currentUser,
      isDeleted: 1
    };
    
    const response = await api.patch(`${BASE_URL}/userSettings`, updatedUser);
    
    // Also update the user in Sendbird
    try {
      await upsertSendbirdUser({
        ...updatedUser,
        metadata: {
          ...updatedUser.metadata,
          isDeleted: 'true'
        }
      });
    } catch (sendbirdError) {
      console.error('Error updating user in Sendbird:', sendbirdError);
      // Don't throw the error, just log it
    }
    
    return response.data;
  } catch (error) {
    console.error('Error soft-deleting user settings:', error);
    throw error;
  }
};

// Restore a deleted user
export const restoreUserSettings = async (userId) => {
  try {
    // First, get the current user data
    const currentUserResponse = await api.get(`${BASE_URL}/userSettings?id=${encodeURIComponent(userId)}`);
    const currentUser = currentUserResponse.data;
    
    if (!currentUser) {
      throw new Error(`User with ID ${userId} not found`);
    }
    
    // Only update the isDeleted field, preserving all other data
    const updatedUser = {
      ...currentUser,
      isDeleted: 0
    };
    
    const response = await api.patch(`${BASE_URL}/userSettings`, updatedUser);
    
    // Also update the user in Sendbird
    try {
      await upsertSendbirdUser({
        ...updatedUser,
        metadata: {
          ...updatedUser.metadata,
          isDeleted: 'false'
        }
      });
    } catch (sendbirdError) {
      console.error('Error updating user in Sendbird:', sendbirdError);
      // Don't throw the error, just log it
    }
    
    return response.data;
  } catch (error) {
    console.error('Error restoring user settings:', error);
    throw error;
  }
};

// Stream Chat token API call
export const getStreamChatToken = async (userId) => {
    try {
        console.log('getStreamChatToken called with userId:', userId);
        
        // Get cognitoId from localStorage
        const lastAuthUserKey = Object.keys(localStorage).find(key => key.endsWith('.LastAuthUser'));
        if (!lastAuthUserKey) {
            throw new Error('No authenticated user found in localStorage');
        }
        
        const cognitoId = localStorage.getItem(lastAuthUserKey);
        if (!cognitoId) {
            throw new Error('No cognitoId found in localStorage');
        }
        
        console.log('Making API call to generate Stream Chat token with cognitoId:', cognitoId);
        const response = await api.post(API_ENDPOINTS.GET_STREAM_CHAT_TOKEN, { userId: cognitoId });
        console.log('Stream Chat token response:', response.data);
        
        return response.data;
    } catch (error) {
        console.error('Error getting Stream Chat token:', error);
        throw error;
    }
};

export const getSendbirdToken = async (userId) => {
  console.log('[API] getSendbirdToken called with userId:', userId);
  
  try {
    let userEmail = userId;
    
    // If userId doesn't contain @, try to get the email from user settings
    if (!userId.includes('@')) {
      console.log('[API] userId does not contain @, fetching user settings to get email');
      try {
        const userSettings = await getUserSettings(userId);
        console.log('[API] User settings retrieved:', userSettings);
        
        if (userSettings && userSettings.email) {
          userEmail = userSettings.email;
          console.log('[API] Using email from user settings:', userEmail);
        } else {
          console.log('[API] No email found in user settings, using original userId');
        }
      } catch (error) {
        console.warn('[API] Error fetching user settings:', error);
        // Continue with userId as is
      }
    }
    
    // Always use the email for Sendbird token requests
    console.log('[API] Making token request with userEmail:', userEmail);
    const response = await api.post(API_ENDPOINTS.GET_SENDBIRD_TOKEN, { 
      userId: userEmail,
      // Add a flag to indicate this is an email and should be sanitized on the backend
      isEmail: userEmail.includes('@')
    });
    console.log('[API] Token response received:', response.data);
    
    return response.data;
  } catch (error) {
    console.error('[API] Error getting Sendbird token:', error);
    throw error;
  }
};

// Upsert a user in Sendbird
export const upsertSendbirdUser = async (userData) => {
  try {
    console.log('Upserting user in Sendbird:', userData.email);
    
    // Sanitize the user ID for Sendbird (emails are not allowed as user IDs)
    const sanitizeUserId = (id) => {
      if (!id) return '';
      return id.includes('@') ? id.replace(/[@.]/g, '_') : id;
    };
    
    // Always use sanitized email as the Sendbird ID
    const sendbirdUserId = sanitizeUserId(userData.email);
    
    // Format the user data for Sendbird
    // IMPORTANT: Sendbird has a limit of 5 metadata fields per user
    const sendbirdUserData = {
      id: sendbirdUserId,
      name: `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || userData.email,
      email: userData.email,
      role: userData.isAdmin ? 'admin' : 'user',
      image: userData.profilePicture || undefined,
      organization: userData.organizationName || undefined,
      // Limit metadata to maximum 5 fields (Sendbird limit)
      metadata: {
        isAdmin: userData.isAdmin ? 'true' : 'false',
        organizationId: userData.organizationId || '',
        isDeleted: userData.isDeleted ? 'true' : 'false',
        // Store the cognitoId in metadata if available
        ...(userData.cognitoId ? { cognitoId: userData.cognitoId } : {})
      }
    };
    
    // Check if we should create or update the user
    console.log(`Sending user data to backend for ${sendbirdUserId}`);
    
    const response = await api.post(API_ENDPOINTS.UPSERT_SENDBIRD_USERS, {
      users: [sendbirdUserData],
      checkExistence: true // Tell the backend to check if users exist first
    });
    
    console.log('Sendbird user upsert response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error upserting user in Sendbird:', error);
    // Don't throw the error, just log it - we don't want to block the main user save operation
    return { error: error.message };
  }
};

// Sync all users with Sendbird
export const syncAllUsersWithSendbird = async () => {
  try {
    console.log('Syncing all users with Sendbird');
    const response = await api.post(`${API_ENDPOINTS.UPSERT_SENDBIRD_USERS}?syncAll=true`);
    console.log('Sync all users response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error syncing all users with Sendbird:', error);
    throw error;
  }
};

// Save PowerBI filter state to API
export const savePbiFilterState = async (filterState) => {
  try {
    const response = await api.post(`${BASE_URL}/pbiFilterState`, filterState);
    return response.data;
  } catch (error) {
    console.error('Error saving PowerBI filter state:', error);
    throw error;
  }
};

// Get PowerBI filter state from API
export const getPbiFilterState = async (stateId) => {
  try {
    const response = await api.get(`${BASE_URL}/pbiFilterState/${stateId}`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving PowerBI filter state:', error);
    throw error;
  }
};

export default {
  ...api,
  getSendbirdToken,
  syncAllUsersWithSendbird,
  // other exports
};


