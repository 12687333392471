import { savePbiFilterState, getPbiFilterState } from './api';
import { models } from 'powerbi-client';

/**
 * Get the current Power BI report filters and save them via API
 * @param {Object} reportInstance - Power BI report instance
 * @returns {Promise<Object>} - The saved filter state response with stateId
 */
export const saveReportFilterState = async (reportInstance) => {
  if (!reportInstance) {
    throw new Error('No active report instance provided');
  }
  
  try {
    // Get report ID
    const reportId = reportInstance.getId();
    if (!reportId) {
      throw new Error('Could not get report ID from report instance');
    }
    
    // Get current report filters
    const pages = await reportInstance.getPages();
    const reportFilters = await reportInstance.getFilters();
    
    // Build filter state object with report and page filters
    const filterState = {
      reportId: reportId,
      reportFilters: reportFilters,
      pageFilters: [],
      visualFilters: [], // Added to store visual/slicer filters
      activePageName: null
    };
    
    // Get the active page
    const activePage = pages.find(p => p.isActive);
    if (activePage) {
      filterState.activePageName = activePage.name;
      // Get page-level filters for the active page
      const pageFilters = await activePage.getFilters();
      filterState.pageFilters = pageFilters;
      
      // Get visuals on the page (including slicers)
      try {
        const visuals = await activePage.getVisuals();
        console.log('Got page visuals:', visuals.length);
        
        // Look for slicers specifically
        const slicers = visuals.filter(v => v.type === 'slicer');
        console.log('Found slicers:', slicers.length);
        
        // Get filter state for each slicer
        const visualFilters = [];
        
        // Process all slicers first for better debugging
        for (const slicer of slicers) {
          try {
            console.log(`Processing slicer: ${slicer.name}`);
            
            // We'll use multiple approaches to get the most accurate slicer state
            
            // APPROACH 1: Get the raw slicer state
            let slicerState = null;
            try {
              slicerState = await slicer.getSlicerState();
              console.log(`Raw slicer state for ${slicer.name}:`, slicerState);
            } catch (slicerStateError) {
              console.warn(`Error getting raw slicer state for ${slicer.name}:`, slicerStateError);
            }
            
            // APPROACH 2: Get the filters
            let slicerFilters = [];
            try {
              slicerFilters = await slicer.getFilters();
              console.log(`Slicer filters for ${slicer.name}:`, slicerFilters);
            } catch (filtersError) {
              console.warn(`Error getting filters for ${slicer.name}:`, filtersError);
            }
            
            // APPROACH 3: Look for matching report filters
            const matchingReportFilters = [];
            if (slicerState && slicerState.targets && slicerState.targets.length > 0) {
              for (const target of slicerState.targets) {
                const reportFilter = reportFilters.find(filter => 
                  filter.target && 
                  filter.target.table === target.table && 
                  filter.target.column === target.column &&
                  filter.operator === "In" && 
                  Array.isArray(filter.values) && 
                  filter.values.length > 0
                );
                
                if (reportFilter) {
                  matchingReportFilters.push(reportFilter);
                }
              }
            }
            
            // Now build a comprehensive representation of the slicer state
            const slicerData = {
              name: slicer.name,
              type: slicer.type
            };
            
            // Always include the raw slicer state if available
            if (slicerState) {
              slicerData.slicerState = slicerState;
            }
            
            // Always include filters
            slicerData.filters = slicerFilters;
            
            // Include report filters that match this slicer (for extra resilience)
            if (matchingReportFilters.length > 0) {
              slicerData.matchingReportFilters = matchingReportFilters;
            }
            
            // Build enhanced filters that will work well with setFilters()
            const enhancedFilters = [];
            
            // Try to build filters from various sources, prioritizing those with actual values
            if (slicerState && slicerState.targets) {
              for (const target of slicerState.targets) {
                // Create base filter structure
                const baseFilter = {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: target.table,
                    column: target.column
                  },
                  filterType: 1,
                  displaySettings: {
                    isHiddenInViewMode: false
                  },
                  requireSingleSelection: false
                };
                
                // Source 1: Check if slicerState has specific filters
                if (slicerState.filters && slicerState.filters.length > 0) {
                  const matchingFilter = slicerState.filters.find(f => 
                    f.target?.table === target.table && 
                    f.target?.column === target.column && 
                    f.operator === "In" && 
                    Array.isArray(f.values) && 
                    f.values.length > 0
                  );
                  
                  if (matchingFilter) {
                    baseFilter.operator = "In";
                    baseFilter.values = matchingFilter.values;
                    enhancedFilters.push(baseFilter);
                    continue; // Skip to next target if we found values
                  }
                }
                
                // Source 2: Check slicerFilters
                if (slicerFilters && slicerFilters.length > 0) {
                  const matchingFilter = slicerFilters.find(f => 
                    f.target?.table === target.table && 
                    f.target?.column === target.column && 
                    f.operator === "In" && 
                    Array.isArray(f.values) && 
                    f.values.length > 0
                  );
                  
                  if (matchingFilter) {
                    baseFilter.operator = "In";
                    baseFilter.values = matchingFilter.values;
                    enhancedFilters.push(baseFilter);
                    continue; // Skip to next target if we found values
                  }
                }
                
                // Source 3: Check slicerState.selection
                if (slicerState.selection && 
                    slicerState.selection.values && 
                    slicerState.selection.values.length > 0) {
                  baseFilter.operator = "In";
                  baseFilter.values = slicerState.selection.values;
                  enhancedFilters.push(baseFilter);
                  continue; // Skip to next target if we found values
                }
                
                // Source 4: Check matching report filters
                const matchingReportFilter = reportFilters.find(filter => 
                  filter.target?.table === target.table &&
                  filter.target?.column === target.column &&
                  filter.operator === "In" && 
                  Array.isArray(filter.values) && 
                  filter.values.length > 0
                );
                
                if (matchingReportFilter) {
                  baseFilter.operator = "In";
                  baseFilter.values = matchingReportFilter.values;
                  enhancedFilters.push(baseFilter);
                  continue; // Skip to next target if we found values
                }
                
                // Default case - no values found
                baseFilter.operator = "All";
                baseFilter.values = [];
                enhancedFilters.push(baseFilter);
              }
            }
            
            // Add enhanced filters to the slicer data
            if (enhancedFilters.length > 0) {
              // Only include filters with actual values for the cleaned state
              const effectiveFilters = enhancedFilters.filter(f => 
                f.operator === "In" && Array.isArray(f.values) && f.values.length > 0
              );
              
              if (effectiveFilters.length > 0) {
                // Create a clean slicer state object for easy application
                const cleanSlicerState = {
                  filters: effectiveFilters,
                  targets: slicerState?.targets || []
                };
                
                slicerData.cleanSlicerState = cleanSlicerState;
                
                // Keep the original slicerState structure but replace the filters with effective ones
                if (slicerState) {
                  slicerData.slicerState = {
                    ...slicerState,
                    filters: effectiveFilters
                  };
                } else {
                  slicerData.slicerState = {
                    filters: effectiveFilters,
                    targets: slicerState?.targets || []
                  };
                }
              }
            }
            
            // Add this slicer data to the visual filters
            visualFilters.push(slicerData);
            
          } catch (slicerError) {
            console.warn(`Error processing slicer ${slicer.name}:`, slicerError);
          }
        }
        
        // Also get filters for non-slicer visuals
        const filterableVisuals = visuals.filter(v => 
          v.type !== 'slicer' && (
            v.type === 'basicFilter' ||
            v.type === 'advancedFilter' ||
            v.type === 'tableEx' ||
            v.type === 'pivotTable' ||
            v.type === 'matrix'
          )
        );
        
        for (const visual of filterableVisuals) {
          try {
            const visualFiltersData = await visual.getFilters();
            if (visualFiltersData && visualFiltersData.length > 0) {
              // Only add if it has actual filters with values
              const hasRealFilters = visualFiltersData.some(f => 
                f.operator === "In" && Array.isArray(f.values) && f.values.length > 0
              );
              
              if (hasRealFilters) {
                visualFilters.push({
                  name: visual.name,
                  type: visual.type,
                  filters: visualFiltersData
                });
              }
            }
          } catch (visualError) {
            console.warn(`Error getting filters for visual ${visual.name}:`, visualError);
          }
        }
        
        // Store all visual filters
        filterState.visualFilters = visualFilters;
        console.log(`Captured data for ${visualFilters.length} visuals/slicers`);
      } catch (visualsError) {
        console.warn('Error getting page visuals:', visualsError);
      }
    }
    
    // Add current timestamp
    filterState.timestamp = new Date().toISOString();
    
    console.log('Saving filter state:', filterState);
    
    // Save to API
    const response = await savePbiFilterState(filterState);
    console.log('API response:', response);
    
    // Make sure reportId is included in the response
    if (response && response.stateId) {
      return {
        ...response,
        reportId: reportId,
        filterState: filterState // Return the full filter state for proper counting
      };
    }
    
    return response;
  } catch (error) {
    console.error('Error saving report filter state:', error);
    throw error;
  }
};

/**
 * Apply saved filter state to a Power BI report
 * @param {Object} reportInstance - Power BI report instance
 * @param {string} stateId - Filter state ID to retrieve
 * @returns {Promise<Object>} - The applied filter state
 */
export const applyReportFilterState = async (reportInstance, stateId) => {
  if (!reportInstance || !stateId) {
    throw new Error('Report instance and stateId are required');
  }
  
  try {
    console.log(`Retrieving filter state with ID: ${stateId}`);
    
    // Get filter state from API
    const response = await getPbiFilterState(stateId);
    
    if (!response || !response.filterState) {
      throw new Error('Failed to retrieve filter state from API');
    }
    
    const filterState = response.filterState;
    console.log('Retrieved filter state:', filterState);
    
    // Wait a moment before trying to apply filters
    // This helps ensure the report is stable and ready to accept filter changes
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    try {
      // Ensure the report is fully loaded and ready
      await ensureReportReady(reportInstance);
    } catch (readyError) {
      console.warn('Report readiness check failed, continuing anyway:', readyError);
      // Continue even if the readiness check fails
    }
    
    // Navigate to the active page if specified
    let activePage = null;
    if (filterState.activePageName) {
      try {
        console.log(`Navigating to page: ${filterState.activePageName}`);
        await reportInstance.setPage(filterState.activePageName);
        console.log('Page navigation successful');
        
        // Wait a bit after page navigation before proceeding
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Get the active page object for applying visual filters later
        const pages = await reportInstance.getPages();
        activePage = pages.find(p => p.isActive);
      } catch (pageError) {
        console.warn('Error navigating to page:', pageError);
        console.log('Continuing with filter application despite page navigation failure');
        // Continue with filter application even if page navigation fails
      }
    }
    
    // Apply with retry logic
    let attempts = 0;
    const maxAttempts = 3;
    
    while (attempts < maxAttempts) {
      try {
        attempts++;
        
        // IMPORTANT: DO NOT clear existing report filters - this was causing issues
        // Instead, we'll set each filter individually
        
        // Get page visuals FIRST so we can apply slicer filters directly
        // This is important as we want to apply filters to slicers before report filters
        // to avoid conflicts
        let slicers = [];
        if (activePage) {
          try {
            const visuals = await activePage.getVisuals();
            
            // Extract all the slicers
            slicers = visuals.filter(v => v.type === 'slicer');
            console.log(`Found ${slicers.length} slicers on page`);
          } catch (error) {
            console.warn('Error getting page visuals:', error);
          }
        }
        
        // First, apply slicer-specific filters if we have any
        let slicerApplied = false;
        
        if (slicers.length > 0 && filterState.visualFilters && filterState.visualFilters.length > 0) {
          console.log(`Attempting to apply slicer states first (${filterState.visualFilters.length} visual filters available)`);
          
          // First try to apply slicers directly from visual filters
          for (const slicer of slicers) {
            try {
              console.log(`Looking for saved state for slicer: ${slicer.name}`);
              
              // Find matching visual state in the saved state
              const visualState = filterState.visualFilters.find(v => v.name === slicer.name);
              
              if (!visualState) {
                console.log(`No saved state found for slicer ${slicer.name}`);
                continue;
              }
              
              console.log(`Found saved state for slicer ${slicer.name}:`, visualState);
              
              // APPROACH 1: Try directly setting the slicer's slicerState
              if (visualState.slicerState && visualState.slicerState.targets) {
                try {
                  // First try using setSlicerState which handles the visual selection explicitly
                  // We need to include a selection property for this to work properly
                  const completeSlicerState = {
                    ...visualState.slicerState,
                    // Ensure selection property exists
                    selection: {
                      // Extract values from slicerState.filters
                      values: visualState.slicerState.filters && 
                             visualState.slicerState.filters[0] &&
                             visualState.slicerState.filters[0].values || []
                    }
                  };
                  
                  console.log(`Applying complete slicerState with selection to ${slicer.name}:`, completeSlicerState);
                  await slicer.setSlicerState(completeSlicerState);
                  console.log(`Successfully applied slicerState with selection to ${slicer.name}`);
                  slicerApplied = true;
                  continue; // Skip other approaches if this one worked
                } catch (error) {
                  console.warn(`Error applying complete slicerState to ${slicer.name}:`, error);
                }
              }
              
              // APPROACH 2: Try applying cleanSlicerState with selection property
              if (visualState.cleanSlicerState && visualState.cleanSlicerState.filters) {
                try {
                  const cleanSlicerStateWithSelection = {
                    ...visualState.cleanSlicerState,
                    // Add selection property for the visual state
                    selection: {
                      values: visualState.cleanSlicerState.filters && 
                              visualState.cleanSlicerState.filters[0] && 
                              visualState.cleanSlicerState.filters[0].values || []
                    }
                  };
                  
                  console.log(`Applying cleanSlicerState with selection to ${slicer.name}:`, cleanSlicerStateWithSelection);
                  await slicer.setSlicerState(cleanSlicerStateWithSelection);
                  console.log(`Successfully applied cleanSlicerState with selection to ${slicer.name}`);
                  slicerApplied = true;
                  continue; // Skip other approaches if this one worked
                } catch (error) {
                  console.warn(`Error applying cleanSlicerState with selection to ${slicer.name}:`, error);
                }
              }
              
              // APPROACH 3: Create selection-focused structure
              try {
                // Find any filter with actual values to use for selection
                let selectionValues = [];
                
                // Try to find values in various places, prioritizing slicerState.filters
                if (visualState.slicerState && 
                    visualState.slicerState.filters && 
                    visualState.slicerState.filters.length > 0) {
                  const filterWithValues = visualState.slicerState.filters.find(f => 
                    f.operator === "In" && Array.isArray(f.values) && f.values.length > 0
                  );
                  if (filterWithValues) {
                    selectionValues = filterWithValues.values;
                  }
                }
                
                // If no values found yet, try cleanSlicerState
                if (selectionValues.length === 0 && 
                    visualState.cleanSlicerState && 
                    visualState.cleanSlicerState.filters && 
                    visualState.cleanSlicerState.filters.length > 0) {
                  const filterWithValues = visualState.cleanSlicerState.filters.find(f => 
                    f.operator === "In" && Array.isArray(f.values) && f.values.length > 0
                  );
                  if (filterWithValues) {
                    selectionValues = filterWithValues.values;
                  }
                }
                
                // If we found selection values, create minimal slicer state focusing on selection
                if (selectionValues.length > 0) {
                  const targets = visualState.slicerState?.targets || 
                                  visualState.cleanSlicerState?.targets || [];
                  
                  if (targets.length > 0) {
                    const selectionFocusedState = {
                      targets: targets,
                      selection: {
                        values: selectionValues
                      },
                      filters: [
                        {
                          $schema: "http://powerbi.com/product/schema#basic",
                          target: targets[0],
                          filterType: 1,
                          operator: "In",
                          values: selectionValues,
                          requireSingleSelection: false
                        }
                      ]
                    };
                    
                    console.log(`Applying selection-focused state to ${slicer.name}:`, selectionFocusedState);
                    await slicer.setSlicerState(selectionFocusedState);
                    console.log(`Successfully applied selection-focused state to ${slicer.name}`);
                    slicerApplied = true;
                    continue; // Skip other approaches if this one worked
                  }
                }
              } catch (error) {
                console.warn(`Error applying selection-focused state to ${slicer.name}:`, error);
              }
              
              // APPROACH 4: Try applying cleanSlicerState.filters using setFilters
              if (visualState.cleanSlicerState && 
                  visualState.cleanSlicerState.filters && 
                  visualState.cleanSlicerState.filters.length > 0) {
                
                const effectiveFilters = visualState.cleanSlicerState.filters.filter(f => 
                  f.operator === "In" && Array.isArray(f.values) && f.values.length > 0
                );
                
                if (effectiveFilters.length > 0) {
                  try {
                    console.log(`Applying ${effectiveFilters.length} clean filters to slicer ${slicer.name}:`, effectiveFilters);
                    await slicer.setFilters(effectiveFilters);
                    console.log(`Successfully applied clean filters to slicer ${slicer.name}`);
                    slicerApplied = true;
                    continue; // Skip other approaches if this one worked
                  } catch (error) {
                    console.warn(`Error applying clean filters to slicer ${slicer.name}:`, error);
                  }
                }
              }
              
              // APPROACH 5: Try using the enhanced slicerState structure
              if (visualState.slicerState && 
                  visualState.slicerState.filters && 
                  visualState.slicerState.filters.length > 0) {
                  
                const slicerFilters = visualState.slicerState.filters.filter(f => 
                  f.operator === "In" && Array.isArray(f.values) && f.values.length > 0
                );
                
                if (slicerFilters.length > 0) {
                  try {
                    console.log(`Applying ${slicerFilters.length} slicerState filters to slicer ${slicer.name}:`, slicerFilters);
                    await slicer.setFilters(slicerFilters);
                    console.log(`Successfully applied slicerState filters to slicer ${slicer.name}`);
                    slicerApplied = true;
                    continue; // Skip other approaches if this one worked
                  } catch (error) {
                    console.warn(`Error applying slicerState filters to slicer ${slicer.name}:`, error);
                  }
                }
              }
              
              // APPROACH 6: Try using regular filters
              if (visualState.filters && visualState.filters.length > 0) {
                const effectiveFilters = visualState.filters.filter(f => 
                  f.operator === "In" && Array.isArray(f.values) && f.values.length > 0
                );
                
                if (effectiveFilters.length > 0) {
                  try {
                    console.log(`Applying ${effectiveFilters.length} regular filters to slicer ${slicer.name}:`, effectiveFilters);
                    await slicer.setFilters(effectiveFilters);
                    console.log(`Successfully applied regular filters to slicer ${slicer.name}`);
                    slicerApplied = true;
                    continue; // Skip other approaches if this one worked
                  } catch (error) {
                    console.warn(`Error applying regular filters to slicer ${slicer.name}:`, error);
                  }
                }
              }
              
              // APPROACH 7: Try using matchingReportFilters if available
              if (visualState.matchingReportFilters && visualState.matchingReportFilters.length > 0) {
                try {
                  console.log(`Applying ${visualState.matchingReportFilters.length} matching report filters to slicer ${slicer.name}`);
                  await slicer.setFilters(visualState.matchingReportFilters);
                  console.log(`Successfully applied matching report filters to slicer ${slicer.name}`);
                  slicerApplied = true;
                } catch (error) {
                  console.warn(`Error applying matching report filters to slicer ${slicer.name}:`, error);
                }
              }
            } catch (error) {
              console.warn(`Error processing slicer ${slicer.name}:`, error);
            }
          }
        }
        
        // Apply report-level filters only if they have actual values
        if (filterState.reportFilters && filterState.reportFilters.length > 0) {
          // Filter out report filters that have actual values
          const effectiveReportFilters = filterState.reportFilters.filter(filter => 
            filter.operator === "In" && Array.isArray(filter.values) && filter.values.length > 0
          );
          
          if (effectiveReportFilters.length > 0) {
            console.log(`Applying ${effectiveReportFilters.length} effective report filters`);
            
            // Apply each filter one by one instead of clearing and setting all at once
            for (const filter of effectiveReportFilters) {
              try {
                console.log(`Applying report filter for ${filter.target.table}.${filter.target.column}:`, filter.values);
                await reportInstance.updateFilters(models.FiltersOperations.Replace, [filter]);
                console.log(`Successfully applied filter for ${filter.target.table}.${filter.target.column}`);
              } catch (filterError) {
                console.warn(`Error applying filter for ${filter.target.table}.${filter.target.column}:`, filterError);
              }
            }
          } else {
            console.log('No effective report-level filters to apply (no actual values)');
          }
        } else {
          console.log('No report-level filters to apply');
        }
        
        // If slicers weren't applied directly and we have report filters that match slicer targets,
        // try to apply them to slicers as a last resort
        if (!slicerApplied && slicers.length > 0 && filterState.reportFilters && filterState.reportFilters.length > 0) {
          console.log('Trying to apply report filters to slicers as fallback');
          
          // Loop through each slicer on the page
          for (const slicer of slicers) {
            try {
              // Get slicer state to find its target table/column
              const slicerState = await slicer.getSlicerState();
              
              if (!slicerState || !slicerState.targets || slicerState.targets.length === 0) {
                continue;
              }
              
              // For each target
              for (const target of slicerState.targets) {
                // Check if we have a matching report filter with values
                const matchingReportFilter = filterState.reportFilters?.find(f => 
                  f.target && 
                  f.target.table === target.table && 
                  f.target.column === target.column &&
                  f.operator === "In" && 
                  Array.isArray(f.values) && 
                  f.values.length > 0
                );
                
                if (matchingReportFilter) {
                  // Apply this filter directly to the slicer
                  try {
                    console.log(`Applying report filter for ${target.table}.${target.column} to slicer ${slicer.name}:`, matchingReportFilter);
                    await slicer.setFilters([matchingReportFilter]);
                    console.log(`Successfully applied report filter to slicer ${slicer.name}`);
                  } catch (error) {
                    console.warn(`Error applying report filter to slicer ${slicer.name}:`, error);
                  }
                }
              }
            } catch (error) {
              console.warn(`Error processing slicer ${slicer.name}:`, error);
            }
          }
        }
        
        // If we reach here, no error was thrown, so break out of the retry loop
        console.log('Filter application complete, breaking out of retry loop');
        break;
      } catch (error) {
        console.error(`Error applying filters (attempt ${attempts}/${maxAttempts}):`, error);
        
        if (attempts >= maxAttempts) {
          throw new Error(`Failed to apply filters after ${maxAttempts} attempts: ${error.message}`);
        }
        
        // Wait a bit before retrying
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
    
    return filterState;
  } catch (error) {
    console.error('Error applying report filter state:', error);
    throw error;
  }
};

/**
 * Ensures the report is fully loaded and ready for operations
 * @param {Object} reportInstance - Power BI report instance
 * @returns {Promise<void>}
 */
const ensureReportReady = async (reportInstance) => {
  return new Promise((resolve, reject) => {
    // We can't check if report is loaded directly - isLoaded() isn't available
    // Instead, we'll add a short delay to ensure the report has stabilized
    console.log('Ensuring report is ready before applying filters...');
    
    // Set up a one-time event handler for the loaded event in case it fires
    const loadedHandler = () => {
      console.log('Report loaded event triggered');
      reportInstance.off('loaded', loadedHandler);
      resolve();
    };
    
    // Add the event handler
    reportInstance.on('loaded', loadedHandler);
    
    // Set a timeout to resolve anyway after a short delay
    setTimeout(() => {
      reportInstance.off('loaded', loadedHandler);
      console.log('Continuing with filter application after delay');
      resolve();
    }, 1500);
  });
};

/**
 * Create a filter state sharing message for Sendbird
 * @param {string} stateId - The filter state ID
 * @param {string} reportName - The name of the report
 * @param {string} reportId - The ID of the report
 * @param {Object} filterCounts - Optional counts of filters by type
 * @returns {Object} - Custom Sendbird message data
 */
export const createFilterShareMessage = (stateId, reportName, reportId, filterCounts = null) => {
  return {
    type: 'CUSTOM',
    customType: 'pbi_filter_share',
    data: JSON.stringify({
      stateId: stateId,
      reportName: reportName || 'Power BI Report',
      reportId: reportId,
      timestamp: new Date().toISOString(),
      filterCounts: filterCounts || {
        report: 0,
        page: 0,
        visual: 0,
        total: 0
      }
    })
  };
}; 