import React from 'react';
import TestConnectionButton from '../components/Chat/TestConnectionButton';
import { SendbirdProvider } from '../context/SendbirdContext';

const TestSendbird = () => {
  return (
    <SendbirdProvider>
      <div style={{ padding: '20px' }}>
        <h1>Sendbird Connection Test Page</h1>
        <p>Use this page to test the connection to Sendbird using the original app ID format.</p>
        <TestConnectionButton />
      </div>
    </SendbirdProvider>
  );
};

export default TestSendbird; 