import React, { useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Home, FileText, MessageSquare, Settings } from 'lucide-react';
import SlideUpMenu from './SlideUpMenu';

const MobileNavigation = ({ reports }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openItems, setOpenItems] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const handleReportClick = (report, page) => {
    navigate(`/report/${report.id}/page/${page.name}`);
    setIsMenuOpen(false);
  };

  const toggleItem = (itemId) => {
    setOpenItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  const activeReportId = location.pathname.split('/')[2];

  return (
    <>
      <nav className="fixed bottom-0 left-0 right-0 h-16 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 z-40">
        <div className="h-full grid grid-cols-4 items-center">
          <NavLink 
            to="/dashboard" 
            className={({ isActive }) => `
              flex flex-col items-center justify-center
              ${isActive ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400'}
            `}
          >
            <Home size={20} />
            <span className="text-xs mt-1">Home</span>
          </NavLink>
          
          <button 
            onClick={() => setIsMenuOpen(true)}
            className={`flex flex-col items-center justify-center w-full
              ${location.pathname.includes('/report') ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400'}
            `}
          >
            <FileText size={20} />
            <span className="text-xs mt-1">Reports</span>
          </button>
          
          <NavLink 
            to="/chat" 
            className={({ isActive }) => `
              flex flex-col items-center justify-center
              ${isActive ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400'}
            `}
          >
            <MessageSquare size={20} />
            <span className="text-xs mt-1">Chat</span>
          </NavLink>
          
          <NavLink 
            to="/settings" 
            className={({ isActive }) => `
              flex flex-col items-center justify-center
              ${isActive ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400'}
            `}
          >
            <Settings size={20} />
            <span className="text-xs mt-1">Settings</span>
          </NavLink>
        </div>
      </nav>

      <SlideUpMenu 
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        reports={reports}
        onReportClick={handleReportClick}
        activeReportId={activeReportId}
        openItems={openItems}
        onToggleItem={toggleItem}
      />
    </>
  );
};

export default MobileNavigation; 