import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from './reportWebVitals';
import { registerServiceWorker } from './utils/notificationService';

const root = ReactDOM.createRoot(document.getElementById("root"));

// Register service worker for push notifications
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    console.log('Window loaded, initializing service worker...');
    
    try {
      // Set registration time before registration to prevent reload loops
      localStorage.setItem('swRegistrationTime', new Date().getTime().toString());
      
      // Use the enhanced registration function from notificationService
      const registration = await registerServiceWorker();
      
      if (registration) {
        console.log('Service Worker successfully registered from index.js');
        
        // Force update if there's a waiting worker
        if (registration.waiting) {
          console.log('Found waiting service worker, activating it...');
          registration.waiting.postMessage({type: 'SKIP_WAITING'});
        }
        
        // Check for updates every hour
        setInterval(() => {
          console.log('Checking for service worker updates...');
          registration.update()
            .then(() => console.log('Service worker update check completed'))
            .catch(err => console.error('Error checking for updates:', err));
        }, 60 * 60 * 1000); // Check every hour
      } else {
        console.warn('Service Worker registration failed or was not completed');
      }
    } catch (error) {
      console.error('Error during service worker initialization:', error);
    }
  });
  
  // Handle service worker controller changes
  let refreshing = false;
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('Service worker controller changed');
    
    // Check if refresh is disabled
    if (localStorage.getItem('disableSwRefresh') === 'true') {
      console.log('Page refresh disabled by user action');
      return;
    }
    
    // Prevent multiple refreshes
    if (refreshing) return;
    refreshing = true;
    
    // Only reload if this was a deliberate update, not initial registration
    const lastRegistrationTime = parseInt(localStorage.getItem('swRegistrationTime') || '0');
    const currentTime = new Date().getTime();
    
    if (currentTime - lastRegistrationTime > 3000) {
      console.log('Reloading page due to service worker update');
      window.location.reload();
    } else {
      console.log('Skipping reload as this appears to be initial registration');
    }
  });
  
  // Handle messages from service worker
  navigator.serviceWorker.addEventListener('message', (event) => {
    console.log('Received message from service worker:', event.data);
    
    // Handle specific message types if needed
    if (event.data && event.data.type === 'NOTIFICATION_CLICKED') {
      console.log('User clicked notification:', event.data.notification);
      // Handle notification click (e.g., navigate to specific page)
    }
  });
}

root.render(
  <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
