import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useUserSettingsContext } from '../context/UserSettingsContext';

function ProtectedRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { fetchSettings } = useUserSettingsContext();

  React.useEffect(() => {
    checkAuth();
  }, []);

  async function checkAuth() {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken;
      
      if (!token) {
        throw new Error('No token found');
      }

      // Validate token expiration
      const currentTime = Math.floor(Date.now() / 1000);
      if (token.payload.exp < currentTime) {
        throw new Error('Token expired');
      }

      setIsAuthenticated(true);
      
      // Fetch user settings after successful authentication
      // This will use email as the identifier instead of user ID
      await fetchSettings();
    } catch (error) {
      console.error('Auth check failed:', error);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children || <Outlet />;
}

export default ProtectedRoute; 