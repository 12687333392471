import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import MobileNavigation from './MobileNavigation';
import TopLoadingBar from '../TopLoadingBar';
import { Search } from 'lucide-react';
import { getReports, getOrgConfig, getReportPages } from '../../utils/api';
import hotelIcon from '../../assets/img/hotel--icon.svg';
import PullToRefresh from 'react-pull-to-refresh';

const MobileLayout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [reports, setReports] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgConfig = await getOrgConfig();
        const wsId = orgConfig[0].workspaceId;
        const reportsResponse = await getReports(wsId);
        
        // Get pages for each report
        const reportsWithPages = await Promise.all(
          reportsResponse.value.map(async (report) => {
            try {
              const pagesResponse = await getReportPages(report.id, wsId);
              return {
                ...report,
                pages: pagesResponse.value
              };
            } catch (error) {
              console.error(`Error fetching pages for report ${report.id}:`, error);
              return {
                ...report,
                pages: []
              };
            }
          })
        );
        setReports(reportsWithPages);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };
    fetchData();
  }, []);

  const handleRefresh = () => {
    return new Promise((resolve) => {
      window.location.reload();
      resolve();
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 via-blue-50 to-blue-100">
      <TopLoadingBar isLoading={isLoading} />
      
      {/* Mobile header */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <div className="h-16 bg-white border-b border-gray-200 flex items-center justify-between px-4">
          <div className="flex items-center">
            <img src={hotelIcon} alt="Hotel Icon" className="w-6 h-6" />
            <span className="text-xl font-semibold ml-2">lodgeic</span>
          </div>
          <div className="flex-1 max-w-xs ml-4">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setShowResults(true)}
                placeholder="Search..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500 text-sm"
              />
              {showResults && searchResults && searchResults.length > 0 && (
                <div className="absolute top-full left-0 right-0 mt-1 bg-white rounded-lg shadow-lg border border-gray-200">
                  {searchResults.map((result) => (
                    <button
                      key={result.id}
                      onClick={() => {
                        navigate(result.path);
                        setShowResults(false);
                        setSearchTerm('');
                      }}
                      className="w-full text-left p-3 hover:bg-gray-100"
                    >
                      <div className="text-sm font-medium">{result.name}</div>
                      {result.type === 'page' && (
                        <div className="text-xs text-gray-500">{result.reportName}</div>
                      )}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <PullToRefresh
        onRefresh={handleRefresh}
        pullingContent={<div className="text-center text-gray-500 pt-4">Pull down to refresh...</div>}
        refreshingContent={<div className="text-center text-gray-500 pt-4">Refreshing...</div>}
        className="min-h-screen"
        style={{ touchAction: 'none' }}
      >
        <main className="pt-16 pb-20">
          <div className="h-full">
            <Outlet />
          </div>
        </main>
      </PullToRefresh>

      <MobileNavigation reports={reports} />
    </div>
  );
};

export default MobileLayout; 