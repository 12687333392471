import React, { useState, useEffect } from 'react';
import { Bell, Save, CheckCircle, XCircle, RefreshCw, HelpCircle, Send } from 'lucide-react';
import { Link } from 'react-router-dom';
import './settings.css';
import {
  isPushNotificationSupported,
  checkPushSubscription,
  subscribeToPushNotifications,
  unsubscribeFromPushNotifications,
  registerServiceWorker,
  resetServiceWorker,
  sendTestNotification
} from '../../utils/notificationService';
import { toast } from 'react-hot-toast';

const NotificationSettings = () => {
  const [notifications, setNotifications] = useState({
    pushNotifications: false,
    desktopNotifications: true,
    soundAlerts: true,
    chatNotifications: true,
    taskReminders: true
  });
  
  const [pushSupported, setPushSupported] = useState(false);
  const [loading, setLoading] = useState(true);
  const [testLoading, setTestLoading] = useState(false);
  const [error, setError] = useState(null);
  const [serviceWorkerStatus, setServiceWorkerStatus] = useState({
    registered: false,
    active: false,
    controller: false
  });

  // Initialize on component mount
  useEffect(() => {
    const initComponent = async () => {
      try {
        // Check if browser supports push notifications
        const supported = isPushNotificationSupported();
        setPushSupported(supported);
        
        if (supported) {
          // Initialize service worker
          const registration = await registerServiceWorker();
          
          if (registration) {
            setServiceWorkerStatus(prevStatus => ({
              ...prevStatus,
              registered: true,
              active: !!registration.active,
              controller: !!navigator.serviceWorker.controller
            }));
            
            // Check if already subscribed
            const isSubscribed = await checkPushSubscription();
            setNotifications(prev => ({
              ...prev,
              pushNotifications: isSubscribed
            }));
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    
    initComponent();
  }, []);

  // Handle toggle change
  const handleToggleChange = (setting) => {
    if (setting === 'pushNotifications') {
      handlePushNotificationChange(!notifications.pushNotifications);
    } else {
      setNotifications(prev => ({
        ...prev,
        [setting]: !prev[setting]
      }));
    }
  };

  // Initialize push notifications
  const initPushNotifications = async () => {
    try {
      setError(null);
      
      // Check service worker and register if needed
      const registration = await registerServiceWorker();
      
      if (!registration) {
        throw new Error('Failed to register service worker');
      }
      
      setServiceWorkerStatus({
        registered: true,
        active: !!registration.active,
        controller: !!navigator.serviceWorker.controller
      });
      
      return true;
    } catch (err) {
      setError(`Service worker registration failed: ${err.message}`);
      return false;
    }
  };

  // Handle push notification toggle
  const handlePushNotificationChange = async (enable) => {
    try {
      setLoading(true);
      setError(null);
      
      if (enable) {
        // Initialize if not already
        const initialized = await initPushNotifications();
        
        if (!initialized) {
          setLoading(false);
          return;
        }
        
        // Subscribe to push notifications
        const result = await subscribeToPushNotifications();
        
        if (!result) {
          throw new Error('Failed to subscribe to push notifications');
        }
        
        setNotifications(prev => ({
          ...prev,
          pushNotifications: true
        }));
        
        toast.success('Push notifications enabled');
      } else {
        // Unsubscribe from push notifications
        const result = await unsubscribeFromPushNotifications();
        
        if (!result) {
          throw new Error('Failed to unsubscribe from push notifications');
        }
        
        setNotifications(prev => ({
          ...prev,
          pushNotifications: false
        }));
        
        toast.success('Push notifications disabled');
      }
    } catch (err) {
      // Extract the best error message
      const errorMsg = err.response?.data?.error || err.message || 'Unknown error';
      setError(errorMsg);
      toast.error(`Error: ${errorMsg}`);
      
      // Reset the toggle to its previous state
      setNotifications(prev => ({
        ...prev,
        pushNotifications: !enable
      }));
      
      // Special handling for permission errors
      if (errorMsg.includes('permission') || errorMsg.includes('Permission')) {
        toast('Please check your browser settings to allow notifications', { icon: '⚠️' });
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle sending a test notification
  const handleSendTestNotification = async () => {
    if (!notifications.pushNotifications) {
      toast.error('Please enable push notifications first');
      return;
    }
    
    try {
      setTestLoading(true);
      const result = await sendTestNotification();
      
      if (result.success) {
        toast.success(result.message);
      } else {
        // This might happen if all subscriptions are expired
        toast.error(result.message || 'Failed to send test notification');
        
        // If all subscriptions failed, suggest resetting
        if (result.message && result.message.includes('expired')) {
          setNotifications(prev => ({ ...prev, pushNotifications: false }));
          toast('Try re-enabling push notifications', { icon: '🔄' });
        }
      }
    } catch (err) {
      // Extract the best error message
      const errorMsg = err.response?.data?.error || err.message || 'Unknown error';
      toast.error(`Error: ${errorMsg}`);
      
      // Special handling for common errors
      if (errorMsg.includes('No active subscriptions found')) {
        setNotifications(prev => ({ ...prev, pushNotifications: false }));
        toast('Please re-enable push notifications', { icon: '🔄' });
      }
    } finally {
      setTestLoading(false);
    }
  };

  // Handle service worker reset
  const handleResetServiceWorker = async () => {
    try {
      setLoading(true);
      await resetServiceWorker();
      toast.success('Service worker reset successfully. Page will reload.');
    } catch (err) {
      toast.error(`Failed to reset service worker: ${err.message}`);
      setLoading(false);
    }
  };

  // Prepare notification settings for save
  const saveNotificationSettings = () => {
    // Save to backend, local storage or context
    toast.success('Notification settings saved');
  };

  // If loading or push notifications not supported in this browser
  if (loading) {
    return (
      <div className="p-4 flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="settings-page space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <Bell className="mr-2 h-5 w-5 text-blue-500" />
          <h2 className="text-xl font-semibold text-white">Notification Settings</h2>
        </div>
        <Link to="/settings/notifications/help" className="text-blue-400 hover:text-blue-300 flex items-center">
          <HelpCircle className="w-4 h-4 mr-1" />
          <span>Help & Troubleshooting</span>
        </Link>
      </div>

      {error && (
        <div className="bg-red-900/30 backdrop-blur-sm text-red-200 p-4 rounded-lg">
          <h3 className="text-lg font-medium mb-2">Error</h3>
          <p>{error}</p>
        </div>
      )}

      {/* Service Worker Status */}
      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/10 p-4">
        <h3 className="text-white font-medium mb-2">Service Worker Status</h3>
        <div className="space-y-1 text-sm">
          <div className="flex items-center">
            <span className="text-gray-300 w-24">Registered:</span>
            {serviceWorkerStatus.registered ? (
              <CheckCircle className="h-4 w-4 text-green-500" />
            ) : (
              <XCircle className="h-4 w-4 text-red-500" />
            )}
          </div>
          <div className="flex items-center">
            <span className="text-gray-300 w-24">Active:</span>
            {serviceWorkerStatus.active ? (
              <CheckCircle className="h-4 w-4 text-green-500" />
            ) : (
              <XCircle className="h-4 w-4 text-red-500" />
            )}
          </div>
          <div className="flex items-center">
            <span className="text-gray-300 w-24">Controlling:</span>
            {serviceWorkerStatus.controller ? (
              <CheckCircle className="h-4 w-4 text-green-500" />
            ) : (
              <XCircle className="h-4 w-4 text-red-500" />
            )}
          </div>
        </div>
        <div className="flex gap-2 mt-2">
          <button
            onClick={handleResetServiceWorker}
            className="flex items-center text-sm text-blue-400 hover:text-blue-300"
          >
            <RefreshCw className="h-3 w-3 mr-1" />
            Reset Service Worker
          </button>
          {notifications.pushNotifications && (
            <button
              onClick={handleSendTestNotification}
              disabled={testLoading}
              className="flex items-center text-sm text-green-400 hover:text-green-300 ml-4"
            >
              <Send className="h-3 w-3 mr-1" />
              {testLoading ? 'Sending...' : 'Send Test Notification'}
            </button>
          )}
        </div>
      </div>

      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/10 p-6 space-y-6">
        {/* Push Notifications Toggle */}
        <div className="toggle-item">
          <div>
            <h3 className="text-white font-medium">Push Notifications</h3>
            <p className="text-gray-400 text-sm">
              Receive notifications even when the browser is closed
            </p>
            {!pushSupported && (
              <div className="mt-1 text-amber-400 text-xs">
                Your browser does not support push notifications
              </div>
            )}
          </div>
          <button
            className={`toggle ${notifications.pushNotifications ? 'active' : ''} ${
              !pushSupported ? 'disabled' : ''
            }`}
            onClick={() => pushSupported && handleToggleChange('pushNotifications')}
            disabled={!pushSupported}
          >
            <span className="toggle-slider"></span>
          </button>
        </div>
        
        {/* Desktop Notifications Toggle */}
        <div className="toggle-item">
          <div>
            <h3 className="text-white font-medium">Desktop Notifications</h3>
            <p className="text-gray-400 text-sm">
              Show notifications on your desktop when the app is open
            </p>
          </div>
          <button
            className={`toggle ${notifications.desktopNotifications ? 'active' : ''}`}
            onClick={() => handleToggleChange('desktopNotifications')}
          >
            <span className="toggle-slider"></span>
          </button>
        </div>

        {/* Sound Alerts Toggle */}
        <div className="toggle-item">
          <div>
            <h3 className="text-white font-medium">Sound Alerts</h3>
            <p className="text-gray-400 text-sm">
              Play a sound for important notifications
            </p>
          </div>
          <button
            className={`toggle ${notifications.soundAlerts ? 'active' : ''}`}
            onClick={() => handleToggleChange('soundAlerts')}
          >
            <span className="toggle-slider"></span>
          </button>
        </div>

        {/* Chat Notifications Toggle */}
        <div className="toggle-item">
          <div>
            <h3 className="text-white font-medium">Chat Notifications</h3>
            <p className="text-gray-400 text-sm">
              Get notified about new messages
            </p>
          </div>
          <button
            className={`toggle ${notifications.chatNotifications ? 'active' : ''}`}
            onClick={() => handleToggleChange('chatNotifications')}
          >
            <span className="toggle-slider"></span>
          </button>
        </div>

        {/* Task Reminders Toggle */}
        <div className="toggle-item">
          <div>
            <h3 className="text-white font-medium">Task Reminders</h3>
            <p className="text-gray-400 text-sm">
              Get reminders about upcoming tasks and deadlines
            </p>
          </div>
          <button
            className={`toggle ${notifications.taskReminders ? 'active' : ''}`}
            onClick={() => handleToggleChange('taskReminders')}
          >
            <span className="toggle-slider"></span>
          </button>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg flex items-center"
          onClick={saveNotificationSettings}
        >
          <Save className="h-4 w-4 mr-2" />
          Save Settings
        </button>
      </div>
    </div>
  );
};

export default NotificationSettings; 