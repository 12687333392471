import React, { useState, useEffect } from 'react';
import { Plus, X, Check } from 'lucide-react';

const UserForm = ({ user, organizations, reports, dashboards, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    isAdmin: 0,
    isDeleted: 0,
    orgAccess: {}
  });
  
  const [selectedOrg, setSelectedOrg] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (user) {
      const orgAccess = { ...user.orgAccess } || {};
      
      // Ensure all organizations have the correct schema
      Object.keys(orgAccess).forEach(orgId => {
        const org = orgAccess[orgId];
        
        // Ensure all required properties exist
        orgAccess[orgId] = {
          reportIds: org.reportIds || {},
          dashboardIds: org.dashboardIds || [],
          entityIds: org.entityIds || [],
          dashboards: org.dashboards || [],
          reports: org.reports || [],
          entities: org.entities || []
        };
        
        // If we have reports but no reportIds, create them
        if (org.reports && org.reports.length > 0 && Object.keys(org.reportIds || {}).length === 0) {
          org.reports.forEach(reportId => {
            orgAccess[orgId].reportIds[reportId] = { Pages: [] };
          });
        }
        
        // If we have dashboards but no dashboardIds, create them
        if (org.dashboards && org.dashboards.length > 0 && (org.dashboardIds || []).length === 0) {
          orgAccess[orgId].dashboardIds = [...org.dashboards];
        }
        
        // If we have entities but no entityIds, create them
        if (org.entities && org.entities.length > 0 && (org.entityIds || []).length === 0) {
          orgAccess[orgId].entityIds = [...org.entities];
        }
      });
      
      setFormData({
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        isAdmin: user.isAdmin || 0,
        isDeleted: user.isDeleted || 0,
        orgAccess: orgAccess,
        // Preserve the _etag property for existing users
        _etag: user._etag,
        // Preserve any other system properties
        _rid: user._rid,
        _self: user._self,
        _ts: user._ts
      });
    } else {
      // Reset form for new user
      setFormData({
        email: '',
        firstName: '',
        lastName: '',
        isAdmin: 0,
        isDeleted: 0,
        orgAccess: {}
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    
    // Clear error for this field if it exists
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  };

  const handleOrgSelect = (e) => {
    setSelectedOrg(e.target.value);
  };

  const handleAddOrg = () => {
    if (!selectedOrg) return;
    
    const org = organizations.find(org => org.orgId === selectedOrg);
    if (!org) return;
    
    setFormData({
      ...formData,
      orgAccess: {
        ...formData.orgAccess,
        [selectedOrg]: {
          reportIds: {},
          dashboardIds: [],
          entityIds: [],
          dashboards: [],
          reports: [],
          entities: []
        }
      }
    });
    
    setSelectedOrg('');
  };

  const handleRemoveOrg = (orgId) => {
    const updatedOrgAccess = { ...formData.orgAccess };
    delete updatedOrgAccess[orgId];
    
    setFormData({
      ...formData,
      orgAccess: updatedOrgAccess
    });
  };

  const handleToggleReport = (orgId, reportId) => {
    const orgAccess = { ...formData.orgAccess };
    const reports = orgAccess[orgId].reports || [];
    
    if (reports.includes(reportId)) {
      orgAccess[orgId].reports = reports.filter(id => id !== reportId);
      
      const reportIds = { ...orgAccess[orgId].reportIds };
      delete reportIds[reportId];
      orgAccess[orgId].reportIds = reportIds;
    } else {
      orgAccess[orgId].reports = [...reports, reportId];
      
      orgAccess[orgId].reportIds = {
        ...orgAccess[orgId].reportIds,
        [reportId]: { Pages: [] }
      };
    }
    
    setFormData({
      ...formData,
      orgAccess
    });
  };

  const handleToggleDashboard = (orgId, dashboardId) => {
    const orgAccess = { ...formData.orgAccess };
    const dashboards = orgAccess[orgId].dashboards || [];
    
    if (dashboards.includes(dashboardId)) {
      orgAccess[orgId].dashboards = dashboards.filter(id => id !== dashboardId);
      
      orgAccess[orgId].dashboardIds = orgAccess[orgId].dashboardIds.filter(id => id !== dashboardId);
    } else {
      orgAccess[orgId].dashboards = [...dashboards, dashboardId];
      
      orgAccess[orgId].dashboardIds = [...orgAccess[orgId].dashboardIds, dashboardId];
    }
    
    setFormData({
      ...formData,
      orgAccess
    });
  };

  const handleToggleEntity = (orgId, entityId) => {
    const orgAccess = { ...formData.orgAccess };
    const entities = orgAccess[orgId].entities || [];
    
    if (entities.includes(entityId)) {
      // Remove from entities array
      orgAccess[orgId].entities = entities.filter(id => id !== entityId);
      
      // Also remove from entityIds array
      orgAccess[orgId].entityIds = orgAccess[orgId].entityIds.filter(id => id !== entityId);
    } else {
      // Add to entities array
      orgAccess[orgId].entities = [...entities, entityId];
      
      // Also add to entityIds array
      orgAccess[orgId].entityIds = [...orgAccess[orgId].entityIds, entityId];
    }
    
    setFormData({
      ...formData,
      orgAccess
    });
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    
    if (!formData.firstName) {
      newErrors.firstName = 'First name is required';
    }
    
    if (!formData.lastName) {
      newErrors.lastName = 'Last name is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    // Ensure all organizations have the correct schema
    const updatedOrgAccess = { ...formData.orgAccess };
    
    Object.keys(updatedOrgAccess).forEach(orgId => {
      const orgAccess = updatedOrgAccess[orgId];
      
      // Ensure all required properties exist
      updatedOrgAccess[orgId] = {
        reportIds: orgAccess.reportIds || {},
        dashboardIds: orgAccess.dashboardIds || [],
        entityIds: orgAccess.entityIds || [],
        dashboards: orgAccess.dashboards || [],
        reports: orgAccess.reports || [],
        entities: orgAccess.entities || []
      };
      
      // Ensure all reports have entries in reportIds
      (orgAccess.reports || []).forEach(reportId => {
        if (!updatedOrgAccess[orgId].reportIds[reportId]) {
          updatedOrgAccess[orgId].reportIds[reportId] = { Pages: [] };
        }
      });
      
      // Ensure all dashboards have entries in dashboardIds
      (orgAccess.dashboards || []).forEach(dashboardId => {
        if (!updatedOrgAccess[orgId].dashboardIds.includes(dashboardId)) {
          updatedOrgAccess[orgId].dashboardIds.push(dashboardId);
        }
      });
    });
    
    const updatedFormData = {
      ...formData,
      orgAccess: updatedOrgAccess
    };
    
    onSave(updatedFormData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className={`w-full px-3 py-2 border ${
              errors.email ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 dark:bg-gray-700 dark:text-white`}
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-500">{errors.email}</p>
          )}
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            First Name
          </label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            className={`w-full px-3 py-2 border ${
              errors.firstName ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 dark:bg-gray-700 dark:text-white`}
          />
          {errors.firstName && (
            <p className="mt-1 text-sm text-red-500">{errors.firstName}</p>
          )}
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Last Name
          </label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            className={`w-full px-3 py-2 border ${
              errors.lastName ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 dark:bg-gray-700 dark:text-white`}
          />
          {errors.lastName && (
            <p className="mt-1 text-sm text-red-500">{errors.lastName}</p>
          )}
        </div>
        
        <div className="md:col-span-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="isAdmin"
              name="isAdmin"
              checked={formData.isAdmin === 1}
              onChange={(e) => setFormData({
                ...formData,
                isAdmin: e.target.checked ? 1 : 0
              })}
              className="h-4 w-4 text-orange-500 focus:ring-orange-500 border-gray-300 rounded"
            />
            <label htmlFor="isAdmin" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
              Administrator Access
            </label>
          </div>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Administrators can access the admin console and manage users, organizations, and settings.
          </p>
        </div>
      </div>
      
      <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Organization Access</h3>
        
        <div className="flex items-end space-x-4 mb-6">
          <div className="flex-grow">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Add Organization
            </label>
            <select
              value={selectedOrg}
              onChange={handleOrgSelect}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 dark:bg-gray-700 dark:text-white"
            >
              <option value="">Select an organization</option>
              {organizations.map(org => (
                !formData.orgAccess[org.orgId] && (
                  <option key={org.orgId} value={org.orgId}>
                    {org.name || org.orgId}
                  </option>
                )
              ))}
            </select>
          </div>
          <button
            type="button"
            onClick={handleAddOrg}
            disabled={!selectedOrg}
            className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Plus size={18} />
          </button>
        </div>
        
        {Object.keys(formData.orgAccess).length === 0 ? (
          <p className="text-gray-500 dark:text-gray-400 italic">No organizations added</p>
        ) : (
          <div className="space-y-6">
            {Object.keys(formData.orgAccess).map(orgId => {
              const org = organizations.find(o => o.orgId === orgId);
              if (!org) return null;
              
              return (
                <div key={orgId} className="border border-gray-200 dark:border-gray-700 rounded-lg p-4">
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="text-md font-medium text-gray-900 dark:text-white">
                      {org.name || org.orgId}
                    </h4>
                    <button
                      type="button"
                      onClick={() => handleRemoveOrg(orgId)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X size={18} />
                    </button>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Dashboards */}
                    <div>
                      <h5 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Dashboards</h5>
                      {dashboards[orgId] && dashboards[orgId].length > 0 ? (
                        <ul className="space-y-2 max-h-40 overflow-y-auto">
                          {dashboards[orgId].map(dashboard => (
                            <li key={dashboard.id} className="flex items-center">
                              <input
                                type="checkbox"
                                id={`dashboard-${orgId}-${dashboard.id}`}
                                checked={(formData.orgAccess[orgId].dashboards || []).includes(dashboard.id)}
                                onChange={() => handleToggleDashboard(orgId, dashboard.id)}
                                className="h-4 w-4 text-orange-500 focus:ring-orange-500 border-gray-300 rounded"
                              />
                              <label
                                htmlFor={`dashboard-${orgId}-${dashboard.id}`}
                                className="ml-2 block text-sm text-gray-700 dark:text-gray-300"
                              >
                                {dashboard.name || dashboard.id}
                              </label>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-sm text-gray-500 dark:text-gray-400 italic">No dashboards available</p>
                      )}
                    </div>
                    
                    {/* Reports */}
                    <div>
                      <h5 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Reports</h5>
                      {reports[orgId] && reports[orgId].length > 0 ? (
                        <ul className="space-y-2 max-h-40 overflow-y-auto">
                          {reports[orgId].map(report => (
                            <li key={report.id} className="flex items-center">
                              <input
                                type="checkbox"
                                id={`report-${orgId}-${report.id}`}
                                checked={(formData.orgAccess[orgId].reports || []).includes(report.id)}
                                onChange={() => handleToggleReport(orgId, report.id)}
                                className="h-4 w-4 text-orange-500 focus:ring-orange-500 border-gray-300 rounded"
                              />
                              <label
                                htmlFor={`report-${orgId}-${report.id}`}
                                className="ml-2 block text-sm text-gray-700 dark:text-gray-300"
                              >
                                {report.name || report.id}
                              </label>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-sm text-gray-500 dark:text-gray-400 italic">No reports available</p>
                      )}
                    </div>
                    
                    {/* Entities/Locations - Placeholder for now */}
                    <div className="md:col-span-2">
                      <h5 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Locations/Entities</h5>
                      <p className="text-sm text-gray-500 dark:text-gray-400 italic">
                        Entity management will be implemented in a future update
                      </p>
                      {/* Placeholder for entity selection */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      
      <div className="flex justify-end space-x-4 pt-4 border-t border-gray-200 dark:border-gray-700">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default UserForm; 