import React, { useState } from 'react';
import { testAddNewUser, testSyncAllUsers, testDirectUpsertUser } from './UserManagementTest';

const SendbirdTestRunner = () => {
  const [logs, setLogs] = useState([]);
  const [isRunning, setIsRunning] = useState(false);

  // Override console.log and console.error to capture logs
  const originalConsoleLog = console.log;
  const originalConsoleError = console.error;

  const captureLog = (type, ...args) => {
    const message = args.map(arg => {
      if (typeof arg === 'object') {
        try {
          return JSON.stringify(arg, null, 2);
        } catch (e) {
          return String(arg);
        }
      }
      return String(arg);
    }).join(' ');

    setLogs(prevLogs => [...prevLogs, { type, message, timestamp: new Date().toISOString() }]);
    
    // Also call the original console method
    if (type === 'error') {
      originalConsoleError(...args);
    } else {
      originalConsoleLog(...args);
    }
  };

  const setupConsoleCapture = () => {
    console.log = (...args) => captureLog('log', ...args);
    console.error = (...args) => captureLog('error', ...args);
  };

  const restoreConsole = () => {
    console.log = originalConsoleLog;
    console.error = originalConsoleError;
  };

  const runTest = async (testFn, testName) => {
    setIsRunning(true);
    setLogs(prevLogs => [...prevLogs, { type: 'header', message: `Running test: ${testName}`, timestamp: new Date().toISOString() }]);
    
    setupConsoleCapture();
    
    try {
      await testFn();
    } catch (error) {
      captureLog('error', `Uncaught error in test: ${error.message}`);
    } finally {
      restoreConsole();
      setIsRunning(false);
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Sendbird API Integration Tests</h1>
      
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => runTest(testAddNewUser, 'Add New User')}
          disabled={isRunning}
          className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 disabled:opacity-50"
        >
          Test Add New User
        </button>
        
        <button
          onClick={() => runTest(testSyncAllUsers, 'Sync All Users')}
          disabled={isRunning}
          className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 disabled:opacity-50"
        >
          Test Sync All Users
        </button>
        
        <button
          onClick={() => runTest(testDirectUpsertUser, 'Direct Upsert User')}
          disabled={isRunning}
          className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 disabled:opacity-50"
        >
          Test Direct Upsert
        </button>
        
        <button
          onClick={() => setLogs([])}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
        >
          Clear Logs
        </button>
      </div>
      
      {isRunning && (
        <div className="mb-4 p-3 bg-blue-50 text-blue-700 rounded-md">
          Test is running, please wait...
        </div>
      )}
      
      <div className="border rounded-md overflow-hidden">
        <div className="bg-gray-100 px-4 py-2 font-mono text-sm">Test Logs</div>
        <div className="p-4 bg-gray-900 text-white font-mono text-sm h-96 overflow-y-auto">
          {logs.length === 0 ? (
            <div className="text-gray-400">No logs yet. Run a test to see results.</div>
          ) : (
            logs.map((log, index) => (
              <div 
                key={index} 
                className={`mb-1 ${
                  log.type === 'error' ? 'text-red-400' : 
                  log.type === 'header' ? 'text-yellow-300 font-bold' : 'text-green-300'
                }`}
              >
                {log.message}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SendbirdTestRunner; 