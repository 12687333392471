import React, { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import { useSendbird } from '../../context/SendbirdContext';
import { getUserSettings } from '../../utils/api';

// Import Sendbird UIKit components
import {
  SendBirdProvider,
  ChannelList,
  Channel,
  ChannelSettings,
  useSendbirdStateContext,
} from '@sendbird/uikit-react';
import '@sendbird/uikit-react/dist/index.css'; // Import UIKit CSS

// Create a MessageCustomization component for Sendbird
const MessageCustomization = ({ message, channel }) => {
  // Only render custom UI for filter share messages
  if (message.customType !== 'pbi_filter_share' || !message.data) {
    return null;
  }
  
  let filterData;
  try {
    filterData = JSON.parse(message.data);
    console.log('Parsed filter share message data:', filterData);
    
    // Try to parse the filter state if it's nested in the message data
    if (filterData.filterState && typeof filterData.filterState === 'string') {
      try {
        filterData.filterState = JSON.parse(filterData.filterState);
      } catch (parseError) {
        console.warn('Could not parse nested filter state:', parseError);
      }
    }
  } catch (error) {
    console.error('Error parsing filter data:', error);
    return null;
  }
  
  // Check if required fields are present
  if (!filterData.stateId || !filterData.reportId) {
    console.error('Missing required filter data fields:', filterData);
    return null;
  }
  
  // Count filters and slicers for display
  const filterCounts = filterData.filterCounts || {
    report: filterData.filterState?.reportFilters?.length || 0,
    page: filterData.filterState?.pageFilters?.length || 0,
    visual: filterData.filterState?.visualFilters?.length || 0,
    total: 0
  };
  
  if (!filterCounts.total) {
    filterCounts.total = filterCounts.report + filterCounts.page + filterCounts.visual;
  }
  
  const handleApplyFilters = (event) => {
    event.preventDefault();
    
    try {
      const { stateId, reportId } = filterData;
      
      console.log(`Navigating to report with filter state. ReportId: ${reportId}, StateId: ${stateId}`);
      
      // Add a success message before navigation
      const msgEl = document.createElement('div');
      msgEl.className = 'fixed top-4 right-4 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded z-50';
      msgEl.innerHTML = `
        <p class="font-semibold">Navigating to report</p>
        <p class="text-sm">Opening report with shared filters...</p>
      `;
      document.body.appendChild(msgEl);
      
      // Remove message after 2 seconds
      setTimeout(() => {
        if (document.body.contains(msgEl)) {
          document.body.removeChild(msgEl);
        }
      }, 2000);
      
      // Small delay to show the message
      setTimeout(() => {
        // Navigate to the report with filter state using window.location
        window.location.href = `/report/${reportId}?filterState=${stateId}`;
      }, 500);
    } catch (error) {
      console.error('Error applying filters:', error);
      
      // Show error notification
      const errorEl = document.createElement('div');
      errorEl.className = 'fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded z-50';
      errorEl.innerHTML = `
        <p class="font-semibold">Error navigating to report</p>
        <p class="text-sm">${error.message || 'An unexpected error occurred'}</p>
      `;
      document.body.appendChild(errorEl);
      
      // Remove error message after 5 seconds
      setTimeout(() => {
        if (document.body.contains(errorEl)) {
          document.body.removeChild(errorEl);
        }
      }, 5000);
    }
  };
  
  // Format the timestamp nicely
  const formattedTime = new Date(filterData.timestamp).toLocaleString(undefined, {
    month: 'short', 
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  });
  
  // Determine sender name
  const senderName = message.sender?.nickname || message.sender?.userId || 'User';
  
  return (
    <div className="my-2 mx-1 p-0.5 rounded-lg transition-shadow">
      <div className="flex items-start">
        {/* Sender name and time */}
        <div className="w-full">
          <div className="flex items-center mb-1.5 px-1">
            <span className="text-xs font-medium text-gray-600">{senderName}</span>
            <span className="mx-1.5 text-gray-300">•</span>
            <span className="text-xs text-gray-400">{formattedTime}</span>
          </div>
          
          {/* Card content */}
          <div className="bg-white rounded-xl shadow-md border border-gray-200 overflow-hidden">
            {/* Header with gradient */}
            <div className="bg-gradient-to-r from-orange-100 to-orange-50 px-4 py-3 border-b border-orange-100 flex items-center">
              <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center mr-3 shadow-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#f97316" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                  <line x1="8" y1="21" x2="16" y2="21"></line>
                  <line x1="12" y1="17" x2="12" y2="21"></line>
                </svg>
              </div>
              <div>
                <h3 className="text-gray-800 font-medium">
                  {filterData.reportName || 'Power BI Report'}
                </h3>
                <p className="text-sm text-gray-500">
                  {filterCounts.total > 0 ? (
                    <span>
                      {filterCounts.total} filter{filterCounts.total !== 1 ? 's' : ''} 
                      {filterCounts.visual > 0 && ` • ${filterCounts.visual} slicer${filterCounts.visual !== 1 ? 's' : ''}`}
                    </span>
                  ) : (
                    <span>Report view</span>
                  )}
                </p>
              </div>
            </div>
            
            {/* Action button */}
            <div className="px-4 py-3">
              <button 
                onClick={handleApplyFilters}
                className="w-full py-2 px-4 bg-orange-500 hover:bg-orange-600 text-white text-sm font-medium rounded-lg transition-colors shadow-sm flex items-center justify-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                  <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"></path>
                </svg>
                View with these filters
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Update the StandardMessageRenderer component to add image preview support
const StandardMessageRenderer = ({ message, channel }) => {
  // Skip if it's our custom filter share message
  if (message.customType === 'pbi_filter_share') {
    return null;
  }
  
  // Format the timestamp nicely
  const formattedTime = new Date(message.createdAt).toLocaleString(undefined, {
    month: 'short', 
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  });
  
  // Determine if the message is from the current user
  const isCurrentUser = message.sender.userId === window.sendbirdClient?.currentUser?.userId;
  
  // Get the sender's name or fallback to userId
  const senderName = message.sender.nickname || message.sender.userId;
  
  // Get first letter for avatar
  const avatarLetter = senderName.charAt(0).toUpperCase();
  
  // Container styles for proper alignment
  const containerClasses = isCurrentUser 
    ? "flex justify-end mb-3 group" 
    : "flex justify-start mb-3 group";
    
  // Message bubble styles with improved design
  const bubbleClasses = isCurrentUser
    ? "bg-orange-50 text-gray-800 p-3 rounded-2xl rounded-tr-sm max-w-xs break-words shadow-sm"
    : "bg-white text-gray-800 p-3 rounded-2xl rounded-tl-sm max-w-xs break-words shadow-sm border border-gray-100";
  
  // Check if the message is a file message
  const isFileMessage = message.type === 'file' || (message.url && message.name);
  
  // Check if it's an image file
  const isImageFile = isFileMessage && 
    message.name && 
    /\.(jpg|jpeg|png|gif|webp|bmp)$/i.test(message.name);
  
  // Helper function to handle message reply
  const handleReplyMessage = (message, channel) => {
    // Implementation depends on how Sendbird handles replies
    console.log('Reply to message:', message);
    // You might need to use Sendbird's SDK methods or update UI state
  };

  // Helper function to handle message edit
  const handleEditMessage = (message, channel) => {
    // Implementation depends on how Sendbird handles edits
    console.log('Edit message:', message);
    // You might need to use Sendbird's SDK methods or update UI state
  };

  // Helper function to handle message delete
  const handleDeleteMessage = async (message, channel) => {
    if (!channel || !message) return;
    
    // Confirm deletion
    if (!window.confirm('Are you sure you want to delete this message?')) {
      return;
    }
    
    try {
      await channel.deleteMessage(message);
      console.log('Message deleted successfully');
    } catch (error) {
      console.error('Error deleting message:', error);
      alert('Failed to delete message. Please try again.');
    }
  };
  
  // Helper function to handle file download
  const handleDownloadFile = (message) => {
    if (!message.url) {
      console.error('No file URL available for download');
      return;
    }
    
    // Create a temporary link element to trigger the download
    const link = document.createElement('a');
    link.href = message.url;
    link.download = message.name || 'download';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Helper function to open image in new tab
  const handleOpenImage = (url) => {
    if (!url) return;
    window.open(url, '_blank');
  };
  
  // Helper function to get file icon based on file type
  const getFileIcon = (fileName) => {
    if (!fileName) return null;
    
    const extension = fileName.split('.').pop()?.toLowerCase();
    
    // Image file types
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-blue-500">
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
          <circle cx="8.5" cy="8.5" r="1.5"></circle>
          <polyline points="21 15 16 10 5 21"></polyline>
        </svg>
      );
    }
    
    // Document file types
    if (['pdf', 'doc', 'docx', 'txt', 'rtf', 'xls', 'xlsx', 'ppt', 'pptx'].includes(extension)) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-green-500">
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
          <polyline points="14 2 14 8 20 8"></polyline>
          <line x1="16" y1="13" x2="8" y2="13"></line>
          <line x1="16" y1="17" x2="8" y2="17"></line>
          <polyline points="10 9 9 9 8 9"></polyline>
        </svg>
      );
    }
    
    // Video file types
    if (['mp4', 'mov', 'avi', 'wmv', 'flv', 'webm'].includes(extension)) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-red-500">
          <rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"></rect>
          <line x1="7" y1="2" x2="7" y2="22"></line>
          <line x1="17" y1="2" x2="17" y2="22"></line>
          <line x1="2" y1="12" x2="22" y2="12"></line>
          <line x1="2" y1="7" x2="7" y2="7"></line>
          <line x1="2" y1="17" x2="7" y2="17"></line>
          <line x1="17" y1="17" x2="22" y2="17"></line>
          <line x1="17" y1="7" x2="22" y2="7"></line>
        </svg>
      );
    }
    
    // Audio file types
    if (['mp3', 'wav', 'ogg', 'aac', 'flac'].includes(extension)) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-purple-500">
          <path d="M9 18V5l12-2v13"></path>
          <circle cx="6" cy="18" r="3"></circle>
          <circle cx="18" cy="16" r="3"></circle>
        </svg>
      );
    }
    
    // Default file icon
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500">
        <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
        <polyline points="13 2 13 9 20 9"></polyline>
      </svg>
    );
  };
  
  // Helper function to format file size
  const formatFileSize = (bytes) => {
    if (!bytes || isNaN(bytes)) return '';
    
    const units = ['B', 'KB', 'MB', 'GB'];
    let size = bytes;
    let unitIndex = 0;
    
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }
    
    return `${size.toFixed(1)} ${units[unitIndex]}`;
  };
  
  return (
    <div className={containerClasses}>
      {/* For other users, show avatar on the left */}
      {!isCurrentUser && (
        <div className="mr-2 mt-1">
          <div className="w-9 h-9 rounded-full flex items-center justify-center text-sm font-medium text-white shadow-sm"
               style={{backgroundColor: `hsl(${senderName.charCodeAt(0) % 360}, 70%, 45%)`}}>
            {avatarLetter}
          </div>
        </div>
      )}
      
      <div className="flex flex-col">
        {/* Show sender name and message */}
        <div className="flex flex-col">
          {!isCurrentUser && (
            <div className="text-xs font-medium text-gray-600 mb-1 ml-0.5">{senderName}</div>
          )}
          
          {isImageFile ? (
            <div className="max-w-xs overflow-hidden rounded-xl shadow-sm border border-gray-200">
              <div className="relative">
                <img 
                  src={message.url} 
                  alt={message.name} 
                  className="max-w-full h-auto rounded-t-xl cursor-pointer hover:opacity-95 transition-opacity"
                  onClick={() => handleOpenImage(message.url)}
                  style={{ maxHeight: '300px', objectFit: 'cover' }}
                />
                <div className="absolute top-2 right-2 flex">
                  <button 
                    onClick={() => handleDownloadFile(message)} 
                    className="p-1.5 rounded-full bg-black/40 hover:bg-black/60 transition-colors text-white"
                    title="Download image"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                      <polyline points="7 10 12 15 17 10"></polyline>
                      <line x1="12" y1="15" x2="12" y2="3"></line>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="p-2 bg-white border-t border-gray-100">
                <div className="flex items-center">
                  <div className="mr-2 flex-shrink-0">
                    {getFileIcon(message.name)}
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="font-medium text-sm truncate" title={message.name || 'Image'}>
                      {message.name || 'Image'}
                    </div>
                    {message.size && (
                      <div className="text-xs text-gray-500 mt-0.5">
                        {formatFileSize(message.size)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : isFileMessage ? (
            <div className={`bg-white text-gray-800 p-3 rounded-2xl ${isCurrentUser ? 'rounded-tr-sm' : 'rounded-tl-sm'} max-w-xs shadow-sm border border-gray-200`}>
              <div className="flex items-center">
                <div className="mr-3 flex-shrink-0">
                  {getFileIcon(message.name)}
                </div>
                <div className="flex-1 min-w-0 mr-2">
                  <div className="font-medium text-sm truncate" title={message.name || 'File'}>
                    {message.name || 'File'}
                  </div>
                  {message.size && (
                    <div className="text-xs text-gray-500 mt-0.5">
                      {formatFileSize(message.size)}
                    </div>
                  )}
                </div>
                <button 
                  onClick={() => handleDownloadFile(message)} 
                  className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
                  title="Download file"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            <div className={bubbleClasses}>
              {message.message}
            </div>
          )}
          
          {/* Timestamp and actions */}
          <div className="flex items-center mt-1 text-xs text-gray-400">
            <span>{formattedTime}</span>
            
            {/* Message actions - show on hover */}
            <div className="flex ml-2 opacity-0 group-hover:opacity-100 transition-opacity">
              <button 
                className="mr-2 p-1 hover:text-gray-600 hover:bg-gray-100 rounded-full" 
                title="Reply"
                onClick={() => handleReplyMessage(message, channel)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polyline points="9 17 4 12 9 7"></polyline>
                  <path d="M20 18v-2a4 4 0 0 0-4-4H4"></path>
                </svg>
              </button>
              
              {isCurrentUser && (
                <>
                  {!isFileMessage && (
                    <button 
                      className="mr-2 p-1 hover:text-gray-600 hover:bg-gray-100 rounded-full" 
                      title="Edit"
                      onClick={() => handleEditMessage(message, channel)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                      </svg>
                    </button>
                  )}
                  
                  <button 
                    className="p-1 hover:text-gray-600 hover:bg-gray-100 rounded-full" 
                    title="Delete"
                    onClick={() => handleDeleteMessage(message, channel)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    </svg>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Create a custom SendbirdApp instead of using the default one
// This bypasses the problematic user list query
const CustomApp = ({ onChannelSelect }) => {
  const [showSettings, setShowSettings] = useState(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState('');
  const isMobile = window.innerWidth <= 768;
  const [isMobileChannelView, setIsMobileChannelView] = useState(false);
  const [reconnecting, setReconnecting] = useState(false);
  
  // Listen for window resize to update mobile status
  useEffect(() => {
    const handleResize = () => {
      const mobileCheck = window.innerWidth <= 768;
      if (mobileCheck !== isMobile) {
        // Only force rerender when mobile status changes
        window.dispatchEvent(new Event('resize')); // Force Sendbird to rerender
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);
  
  // Reset channel view when component unmounts
  useEffect(() => {
    return () => setCurrentChannelUrl('');
  }, []);

  // Handle connection issues
  useEffect(() => {
    const checkConnection = () => {
      try {
        const isConnected = window.sendbirdClient?.connectionState === 'OPEN';
        if (!isConnected && !reconnecting && window.sendbirdClient) {
          console.log('Detected closed connection, attempting to reconnect...');
          setReconnecting(true);
          
          // Attempt to reconnect
          window.sendbirdClient.reconnect()
            .then(() => {
              console.log('Successfully reconnected');
              setReconnecting(false);
            })
            .catch(error => {
              console.error('Failed to reconnect:', error);
              setReconnecting(false);
            });
        }
      } catch (error) {
        console.error('Error checking connection:', error);
      }
    };
    
    // Check connection on mount and every 30 seconds
    checkConnection();
    const interval = setInterval(checkConnection, 30000);
    
    return () => clearInterval(interval);
  }, [reconnecting]);

  const handleChannelSelect = (channel) => {
    // Safety check for null channel
    if (!channel) {
      console.error('Received null channel in handleChannelSelect');
      return;
    }
    
    // Safety check for missing URL
    if (!channel.url) {
      console.error('Channel missing URL in handleChannelSelect', channel);
      return;
    }
    
    try {
      setCurrentChannelUrl(channel.url);
      setIsMobileChannelView(true);
      if (onChannelSelect) {
        onChannelSelect(channel);
      }
    } catch (error) {
      console.error('Error in handleChannelSelect:', error);
    }
  };
  
  const handleBackToChannelList = () => {
    if (isMobile) {
      setIsMobileChannelView(false);
    }
  };

  // Handle connection or loading state
  if (reconnecting) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="flex flex-col items-center text-center max-w-sm p-6">
          <div className="relative w-16 h-16 mb-4">
            <div className="absolute inset-0 animate-spin rounded-full border-4 border-orange-200 border-t-orange-500"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#f97316" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="opacity-50">
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
              </svg>
            </div>
          </div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">Reconnecting</h3>
          <p className="text-gray-500">Attempting to reestablish connection to the chat service...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full">
      {/* Channel List */}
      {(!isMobile || !isMobileChannelView) && (
        <div className={isMobile ? "w-full" : "w-1/3 border-r border-gray-200"}>
          <div className="p-3 border-b border-gray-100 bg-orange-500 text-white flex items-center justify-between">
            <h2 className="text-lg font-semibold">Messages</h2>
            <div className="w-7 h-7 rounded-full bg-orange-600 flex items-center justify-center text-xs font-medium">
              <span className="transform translate-y-px">+</span>
            </div>
          </div>
          <ChannelList
            onChannelSelect={(channel) => {
              try {
                if (!channel) {
                  console.error('Received null channel in onChannelSelect');
                  return;
                }
                
                if (!channel.url) {
                  console.error('Channel missing URL in onChannelSelect', channel);
                  return;
                }
                
                console.log('Channel selected:', channel.url);
                handleChannelSelect(channel);
              } catch (error) {
                console.error('Error in onChannelSelect:', error);
              }
            }}
            onChannelCreated={(channel) => {
              try {
                if (!channel) {
                  console.error('Received null channel in onChannelCreated');
                  return;
                }
                
                if (!channel.url) {
                  console.error('Channel missing URL in onChannelCreated', channel);
                  return;
                }
                
                console.log('Channel created:', channel.url);
                handleChannelSelect(channel);
              } catch (error) {
                console.error('Error in onChannelCreated:', error);
              }
            }}
            allowProfileEdit={false}
            disableUserProfile={true}
            className="lodgeic-channel-list"
          />
        </div>
      )}
      
      {/* Channel/Conversation View */}
      {(!isMobile || isMobileChannelView) && currentChannelUrl && (
        <div className={isMobile ? "w-full absolute inset-0 bg-white z-10" : "w-2/3"}>
          <div className="h-full flex flex-col">
            <Channel
              channelUrl={currentChannelUrl}
              onChatHeaderActionClick={() => {
                setShowSettings(true);
              }}
              className="lodgeic-channel flex-1"
              renderMessageContent={({ message, channel }) => {
                if (message.customType === 'pbi_filter_share') {
                  return <MessageCustomization message={message} channel={channel} />;
                }
                
                // Pass both message and channel to our renderer
                return <StandardMessageRenderer message={message} channel={channel} />;
              }}
              renderChannelHeader={() => (
                <div className={`channel-header flex items-center justify-between p-3 border-b border-gray-200 ${isMobile ? "bg-orange-500 text-white" : "bg-white"}`}>
                  {isMobile && (
                    <button 
                      onClick={handleBackToChannelList}
                      className="mr-2 p-1.5 rounded-full hover:bg-orange-600 flex items-center justify-center"
                      aria-label="Back to conversations"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </button>
                  )}
                  <div className="flex items-center flex-1 truncate">
                    <span className="font-medium truncate">
                      {(() => {
                        try {
                          // Get channel name from current channel
                          const channel = window.sendbirdClient?.channel?.getChannel(currentChannelUrl);
                          
                          // Get members excluding current user for one-on-one chats
                          if (channel && channel.members && channel.members.length === 2) {
                            const currentUserId = window.sendbirdClient?.currentUser?.userId;
                            const otherMember = channel.members.find(m => m.userId !== currentUserId);
                            if (otherMember) {
                              return otherMember.nickname || otherMember.userId;
                            }
                          }
                          
                          // Use channel name if available, otherwise show generic "Chat"
                          return channel?.name || "Chat";
                        } catch (error) {
                          return "Chat";
                        }
                      })()}
                    </span>
                  </div>
                  <button 
                    onClick={() => setShowSettings(true)}
                    className={`p-1.5 rounded-full ${isMobile ? "hover:bg-orange-600 flex items-center justify-center" : "hover:bg-gray-100"}`}
                    aria-label="Channel settings"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="12" cy="12" r="1"></circle>
                      <circle cx="19" cy="12" r="1"></circle>
                      <circle cx="5" cy="12" r="1"></circle>
                    </svg>
                  </button>
                </div>
              )}
            />
          </div>
        </div>
      )}
      
      {/* Empty State When No Channel Is Selected */}
      {(!isMobile || isMobileChannelView) && !currentChannelUrl && (
        <div className={isMobile ? "w-full absolute inset-0 bg-white z-10" : "w-2/3"}>
          <div className={`channel-header flex items-center justify-between p-3 border-b border-gray-200 ${isMobile ? "bg-orange-500 text-white" : "bg-white"}`}>
            {isMobile && (
              <button 
                onClick={handleBackToChannelList}
                className="mr-2 p-1.5 rounded-full hover:bg-orange-600 flex items-center justify-center"
                aria-label="Back to conversations"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </button>
            )}
            <div className="flex items-center flex-1">
              <span className="font-medium">Messages</span>
            </div>
          </div>
          <div className="flex items-center justify-center h-full bg-gray-50">
            <div className="text-center p-6 max-w-sm">
              <div className="w-20 h-20 mx-auto mb-5 flex items-center justify-center rounded-full bg-orange-100">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-orange-500">
                  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">No conversation selected</h3>
              <p className="text-gray-500 mb-5">Choose a conversation from the list to start messaging or create a new one.</p>
              <button 
                onClick={() => {
                  // This would typically open the create channel dialog
                  console.log("Create new conversation clicked");
                  // You might want to implement functionality to create a new channel here
                }}
                className="inline-flex items-center px-4 py-2 bg-orange-500 hover:bg-orange-600 text-white text-sm font-medium rounded-lg transition-colors shadow-sm"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-1.5">
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                New Conversation
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Settings Overlay */}
      {showSettings && (
        <div className="fixed inset-0" style={{ zIndex: 9999 }}>
          <div 
            className="absolute inset-0 bg-black/50" 
            onClick={() => setShowSettings(false)} 
          />
          <div 
            className={`
              absolute bg-white shadow-xl 
              ${isMobile ? 'inset-0' : 'right-0 inset-y-0 w-80'}
            `}
            style={{ zIndex: 10000 }}
          >
            <div className="flex flex-col h-full">
              <div className="flex items-center p-3 border-b border-gray-200 bg-orange-500 text-white">
                <button 
                  onClick={() => setShowSettings(false)}
                  className="mr-2 p-1.5 rounded-full hover:bg-orange-600 flex items-center justify-center"
                  aria-label="Close settings"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="15 18 9 12 15 6"></polyline>
                  </svg>
                </button>
                <h3 className="font-medium">Channel Settings</h3>
              </div>
              <div className="flex-1 overflow-auto">
                <ChannelSettings
                  channelUrl={currentChannelUrl}
                  onCloseClick={() => setShowSettings(false)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ChatBox = ({ isOpen, onClose, userId }) => {
  const { chatClient, loading, error, initializeChat } = useSendbird();
  const [isAnimating, setIsAnimating] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [appId, setAppId] = useState(null);
  
  // Determine if we're in modal mode or embedded mode (for mobile)
  const isModal = onClose !== undefined;
  
  // Control animation timing for modal mode
  useEffect(() => {
    if (!isModal) return; // Skip for embedded mode
    
    if (isOpen) {
      setIsAnimating(true);
      document.body.style.overflow = 'hidden'; // Prevent scrolling when chat is open
    } else {
      setTimeout(() => {
        setIsAnimating(false);
        document.body.style.overflow = ''; // Re-enable scrolling
      }, 300); // Match transition duration
    }
  }, [isOpen, isModal]);

  // Get user email if available
  useEffect(() => {
    const fetchUserEmail = async () => {
      if (userId) {
        try {
          const userSettings = await getUserSettings(userId);
          if (userSettings && userSettings.email) {
            setUserEmail(userSettings.email);
          }
        } catch (error) {
          console.warn('Could not fetch user email:', error);
        }
      }
    };
    
    fetchUserEmail();
  }, [userId]);

  // Initialize chat when opened - this is now handled by MainLayout
  // This is kept as a fallback in case the chat wasn't initialized
  useEffect(() => {
    if (isOpen && userId && (!chatClient || chatClient.connectionState !== 'OPEN') && !loading) {
      // Use email if available, otherwise fall back to userId
      const chatUserId = userEmail || userId;
      console.log(`[ChatBox] Initializing chat with ${userEmail ? 'email' : 'userId'}: ${chatUserId}`);
      initializeChat(chatUserId);
    }
    
    // Get the Sendbird App ID from the chat client or environment
    if (chatClient) {
      setAppId(chatClient.appInfo?.appId);
    } else if (process.env.REACT_APP_SENDBIRD_APP_ID) {
      setAppId(process.env.REACT_APP_SENDBIRD_APP_ID);
    }
  }, [isOpen, userId, userEmail, chatClient, loading, initializeChat]);

  // Handle channel selection to mark messages as read
  const handleChannelSelect = (channel) => {
    if (!channel) return;
    
    console.log(`[ChatBox] Channel selected: ${channel.url}, unread count: ${channel.unreadMessageCount || 0}`);
    
    // Only try to mark as read if there are unread messages
    if (channel.unreadMessageCount > 0) {
      try {
        // For UIKit, we don't need to manually mark as read
        // The UIKit components handle this automatically
        console.log(`[ChatBox] Channel ${channel.url} selected with ${channel.unreadMessageCount} unread messages`);
      } catch (error) {
        console.error('[ChatBox] Error in channel selection handler:', error);
      }
    }
  };

  // Mark all channels as read when chat is opened
  useEffect(() => {
    if (isOpen && chatClient && chatClient.connectionState === 'OPEN') {
      console.log('[ChatBox] Chat opened, channels will be marked as read when selected');
    }
  }, [isOpen, chatClient]);

  // Only render the visible UI when the chat is open or animating in modal mode
  // For embedded mode, always render if isOpen is true
  if (isModal && !isOpen && !isAnimating) return null;
  
  // Apply different container classes based on the mode
  const containerClasses = isModal 
    ? `fixed inset-y-0 right-0 w-full md:w-5/6 lg:w-4/5 xl:w-3/4 bg-white shadow-xl z-50 transform transition-transform duration-300 ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`
    : 'h-full bg-white flex flex-col';
  
  // Overlay that dims the rest of the page (only for modal mode)
  const overlayClasses = `fixed inset-0 bg-gray-900 bg-opacity-50 z-40 transition-opacity duration-300 ${
    isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
  }`;
  
  // Loading states
  if (loading) {
    return (
      <>
        {isModal && <div className={overlayClasses} onClick={onClose}></div>}
        <div className={containerClasses}>
          {isModal && (
            <div className="chat-header flex justify-between items-center p-4 border-b border-gray-200 bg-orange-500 text-white">
              <div className="chat-title flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                </svg>
                <h3 className="text-lg font-semibold">Lodgeic Messages</h3>
              </div>
              <button className="text-white hover:text-gray-200 p-2" onClick={onClose}>
                <X size={20} />
              </button>
            </div>
          )}
          <div className="flex items-center justify-center h-full">
            <div className="flex flex-col items-center max-w-sm text-center p-6">
              <div className="relative w-16 h-16 mb-6">
                <div className="absolute inset-0 animate-spin rounded-full border-4 border-orange-200 border-t-orange-500"></div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#f97316" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="opacity-50">
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                  </svg>
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">Connecting to chat</h3>
              <p className="text-gray-500">Please wait while we're establishing a secure connection to the messaging service...</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  // Error states
  if (error || !chatClient) {
    const isRateLimited = error?.message?.includes('rate limit') || error?.status === 429;
    
    return (
      <>
        {isModal && <div className={overlayClasses} onClick={onClose}></div>}
        <div className={containerClasses}>
          {isModal && (
            <div className="chat-header flex justify-between items-center p-4 border-b border-gray-200 bg-orange-500 text-white">
              <div className="chat-title flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                </svg>
                <h3 className="text-lg font-semibold">Lodgeic Messages</h3>
              </div>
              <button className="text-white hover:text-gray-200 p-2" onClick={onClose}>
                <X size={20} />
              </button>
            </div>
          )}
          <div className="flex flex-col items-center justify-center h-full p-6">
            <div className="flex flex-col items-center max-w-sm text-center">
              <div className="w-20 h-20 mb-6 rounded-full bg-red-50 flex items-center justify-center">
                {isRateLimited ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#ef4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="10" y1="15" x2="14" y2="15"></line>
                    <line x1="9" y1="9" x2="9.01" y2="9"></line>
                    <line x1="15" y1="9" x2="15.01" y2="9"></line>
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#ef4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="12"></line>
                    <line x1="12" y1="16" x2="12.01" y2="16"></line>
                  </svg>
                )}
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                {isRateLimited ? 'Too Many Requests' : 'Connection Error'}
              </h3>
              <p className="text-gray-500 mb-6">
                {isRateLimited 
                  ? 'We\'ve detected too many requests. Please wait a moment before trying again.' 
                  : `We couldn't establish a connection to the chat service. ${error?.message || 'Please try again later.'}`}
              </p>
              <button
                onClick={() => {
                  const chatUserId = userEmail || userId;
                  initializeChat(chatUserId);
                }}
                className="flex items-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors shadow-sm"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M21 2v6h-6"></path>
                  <path d="M3 12a9 9 0 0 1 15-6.7L21 8"></path>
                  <path d="M3 22v-6h6"></path>
                  <path d="M21 12a9 9 0 0 1-15 6.7L3 16"></path>
                </svg>
                <span>Try Again</span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  // Normal render
  return (
    <>
      {isModal && <div className={overlayClasses} onClick={onClose}></div>}
      <div className={containerClasses}>
        <div className="flex flex-col h-full">
          {/* Show header only in modal mode or if we're at the top of mobile view */}
          {isModal && (
            <div className="chat-header flex justify-between items-center p-4 border-b border-gray-200 bg-orange-500 text-white">
              <div className="chat-title flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                </svg>
                <h3 className="text-lg font-semibold">Lodgeic Messages</h3>
              </div>
              <button className="text-white hover:text-gray-200 p-2" onClick={onClose}>
                <X size={20} />
              </button>
            </div>
          )}
          
          {/* Custom Chat App */}
          <div className="flex-1 overflow-hidden">
            {chatClient && (
              <SendBirdProvider
                appId={appId}
                userId={chatClient.currentUser?.userId}
                accessToken={chatClient.currentUser?.accessToken}
                theme="light"
                // These are the correct options for disabling user profile
                config={{
                  logLevel: 'error', // Reduce console log noise
                  isUserListEnabled: false, // Disable user listing
                  disableUserProfile: true, // Disable user profile
                  reconnectTimeout: 3000, // Reconnect more quickly
                  websocketMaxRetries: 10, // Maximum reconnection attempts
                }}
                colorSet={{
                  '--sendbird-light-primary-500': '#f97316', // orange-500
                  '--sendbird-light-primary-400': '#fb923c', // orange-400
                  '--sendbird-light-primary-300': '#fdba74', // orange-300
                  '--sendbird-light-primary-200': '#fed7aa', // orange-200
                  '--sendbird-light-primary-100': '#ffedd5', // orange-100
                  '--sendbird-light-secondary-500': '#ea580c', // orange-600
                  '--sendbird-light-secondary-400': '#f97316', // orange-500
                  '--sendbird-light-secondary-300': '#fb923c', // orange-400
                  '--sendbird-light-secondary-200': '#fdba74', // orange-300
                  '--sendbird-light-secondary-100': '#fed7aa', // orange-200
                }}
                // Add connection hooks to detect and handle connection issues
                onConnectionFailed={(error) => {
                  console.error('Sendbird connection failed:', error);
                  // If the component is mounted, we can attempt to reconnect
                  if (window.sendbirdClient) {
                    window.sendbirdClient.reconnect().catch(err => {
                      console.error('Failed to reconnect after connection failure:', err);
                    });
                  }
                }}
                onReconnectFailed={(error) => {
                  console.error('Sendbird reconnection failed:', error);
                  // After reconnection failure, we might want to reset the chat client
                  // This will be handled by the reconnecting state in CustomApp
                }}
                onReconnectSucceeded={() => {
                  console.log('Sendbird reconnection succeeded');
                }}
              >
                <CustomApp onChannelSelect={handleChannelSelect} />
              </SendBirdProvider>
            )}

            {/* Custom styles for chat UI components */}
            <style jsx global>{`
              /* Custom styles for channel list */
              .lodgeic-channel-list {
                height: 100%;
                overflow: hidden;
              }
              
              /* Improve channel list item appearance */
              .sendbird-channel-list-item {
                padding: 12px 16px !important;
                border-bottom: 1px solid rgba(0,0,0,0.06) !important;
                transition: background-color 0.15s ease-in-out !important;
              }
              
              .sendbird-channel-list-item:hover {
                background-color: rgba(249, 115, 22, 0.05) !important;
              }
              
              .sendbird-channel-list-item--active {
                background-color: rgba(249, 115, 22, 0.1) !important;
              }
              
              .sendbird-channel-list-item__avatar {
                margin-right: 12px !important;
              }
              
              .sendbird-channel-list-item__cover {
                width: 48px !important;
                height: 48px !important;
                border-radius: 50% !important;
                overflow: hidden !important;
                border: 1px solid rgba(0,0,0,0.05) !important;
              }
              
              .sendbird-channel-list-item__content__upper {
                margin-bottom: 4px !important;
              }
              
              .sendbird-channel-list-item__content__upper__header__channel-name {
                font-size: 15px !important;
                font-weight: 500 !important;
              }
              
              .sendbird-channel-list-item__content__upper__header__channel-name span {
                color: #374151 !important; 
              }
              
              .sendbird-channel-list-item__content__lower {
                margin-top: 0 !important;
              }
              
              .sendbird-channel-list-item__content__lower__last-message span {
                font-size: 13px !important;
                line-height: 1.4 !important;
                color: #6B7280 !important;
                display: -webkit-box !important;
                -webkit-line-clamp: 1 !important;
                -webkit-box-orient: vertical !important;
                overflow: hidden !important;
              }
              
              .sendbird-channel-list-item__content__upper__last-message-at {
                font-size: 12px !important;
                color: #9CA3AF !important;
              }
              
              .sendbird-channel-list-item__content__upper__last-message-at span {
                color: #9CA3AF !important;
              }
              
              /* Unread count badge */
              .sendbird-channel-list-item__content__upper__header__right {
                margin-left: 8px !important;
              }
              
              .sendbird-channel-list-item__content__upper__header__right .sendbird-badge {
                background-color: #f97316 !important;
                min-width: 20px !important;
                height: 20px !important;
                padding: 0 6px !important;
                border-radius: 10px !important;
                font-size: 11px !important;
                font-weight: 600 !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
              }
              
              /* Message input styling */
              .sendbird-message-input__container {
                padding: 12px 16px !important;
                border-top: 1px solid rgba(0,0,0,0.08) !important;
              }
              
              .sendbird-message-input {
                border: 1px solid #E5E7EB !important;
                border-radius: 24px !important;
                box-shadow: 0 1px 2px rgba(0,0,0,0.05) !important;
                min-height: 44px !important;
                padding: 8px 12px !important;
              }
              
              .sendbird-message-input:focus-within {
                border-color: #f97316 !important;
                box-shadow: 0 0 0 2px rgba(249,115,22,0.1) !important;
              }
              
              .sendbird-message-input__input {
                font-size: 14px !important;
                padding: 0 8px !important;
              }
              
              .sendbird-message-input__buttons__send {
                background-color: #f97316 !important;
              }
              
              /* Channel header styling */
              .channel-header {
                height: 60px !important;
                border-bottom: 1px solid rgba(0,0,0,0.08) !important;
                display: flex !important;
                align-items: center !important;
                padding: 0 16px !important;
              }
              
              .channel-header span {
                font-size: 16px !important;
                font-weight: 600 !important;
              }
              
              /* Message list styling */
              .sendbird-conversation__messages {
                padding: 16px !important;
                background-color: #F9FAFB !important;
              }
              
              .sendbird-message-content {
                margin: 2px 0 !important;
              }
              
              /* Conversation header styling */
              .sendbird-conversation__header {
                border-bottom: 1px solid rgba(0,0,0,0.08) !important;
                height: 60px !important;
              }
              
              .sendbird-conversation-header__left .sendbird-avatar {
                width: 36px !important;
                height: 36px !important;
                margin-right: 12px !important;
              }
              
              .sendbird-conversation-header__title {
                font-size: 16px !important;
                font-weight: 600 !important;
                color: #374151 !important;
              }
              
              .sendbird-conversation-header__subtitle {
                font-size: 13px !important;
                color: #6B7280 !important;
              }
              
              /* Action button styling */
              .sendbird-message-input__buttons__attachment {
                margin-right: 8px !important;
              }
              
              /* File upload button styling */
              .sendbird-iconbutton--pressed {
                background-color: #FEF3C7 !important;
              }
              
              /* Message separator styling */
              .sendbird-message-separator__text {
                background: #F9FAFB !important;
                font-size: 12px !important;
                font-weight: 500 !important;
                color: #6B7280 !important;
                padding: 0 12px !important;
              }
              
              /* Channel settings styling */
              .sendbird-channel-settings__header {
                height: 60px !important;
                background-color: #f97316 !important;
              }
              
              .sendbird-channel-settings__header-title, 
              .sendbird-channel-settings__header-subtitle {
                color: white !important;
              }
              
              .sendbird-channel-settings__panel-item:hover {
                background-color: #FEF3C7 !important;
              }
              
              /* Custom styles for filter share messages */
              .sendbird-message-content__text-body {
                width: 100%;
              }
              
              /* Improve message container spacing */
              .sendbird-message-content {
                max-width: 100%;
              }
              
              /* Override Sendbird message styling for our custom components */
              .sendbird-message-content__text .sendbird-message-content__text-body {
                background: none;
                padding: 0;
                border-radius: 0;
              }
              
              /* Ensure our custom component has proper width */
              .sendbird-message-content__text {
                max-width: 100%;
                width: 100%;
              }
              
              /* Hide the text decorator since we're using our own UI */
              .sendbird-message-content__text-body__text-decoration {
                display: none;
              }
              
              /* Fix button hover states */
              .sendbird-message-content__text button:hover {
                opacity: 1;
              }
              
              /* Standard message styling */
              .sendbird-message-wrapper {
                max-width: 75%;
                margin: 4px 0;
              }
              
              .sendbird-message-bubble {
                word-break: break-word;
                white-space: pre-wrap;
                font-size: 14px;
                border-radius: 18px !important;
                box-shadow: 0 1px 2px rgba(0,0,0,0.05);
              }
              
              /* Mobile optimizations */
              @media (max-width: 768px) {
                /* Make channel list more compact */
                .sendbird-channel-list {
                  --sendbird-channel-list-item-height: 72px;
                }
                
                /* Hide unnecessary UI elements in mobile */
                .sendbird-chat-header__right-icon, 
                .sendbird-openchannel-conversation-header__right-icon {
                  display: none !important;
                }
                
                /* Reduce font size in channel header */
                .channel-header {
                  font-size: 16px;
                  height: 54px;
                  padding: 0 12px;
                }
                
                /* Make channel title more readable */
                .sendbird-channel-header__title {
                  font-size: 16px;
                  font-weight: 600;
                }
                
                /* Optimize message input for mobile */
                .sendbird-message-input {
                  z-index: 5 !important;
                  position: relative;
                  bottom: 0;
                  margin-bottom: 56px; /* Match exactly the height of the bottom navbar */
                  background-color: white;
                  border-top: 1px solid #eee;
                }
                
                .sendbird-message-input__input {
                  font-size: 15px;
                  border: 1px solid #e2e8f0;
                  border-radius: 20px;
                  padding: 8px 12px;
                }
                
                /* Improve message spacing */
                .sendbird-message-content {
                  margin: 2px 0;
                }
                
                /* Fix z-index for messaging with Lodgeic nav bar */
                .sendbird-conversation__header,
                .sendbird-message-input {
                  z-index: 5 !important;
                }
                
                /* Fix button sizes for mobile */
                .sendbird-message-input__buttons {
                  margin-left: 4px;
                }
                
                /* Ensure message list uses full height */
                .sendbird-conversation, .sendbird-conversation__messages {
                  height: calc(100vh - 122px) !important; /* 16px top header + 50px bottom nav + 56px input */
                  max-height: unset !important;
                }
                
                /* Fix bottom padding to account for nav bar */
                .sendbird-conversation__messages {
                  padding-bottom: 16px !important;
                }
                
                /* Prevent content from being hidden behind bottom nav */
                .sendbird-conversation__messages-padding {
                  padding-bottom: 65px !important; /* Ensure content isn't hidden behind the navbar */
                }
                
                /* Stop the screen from bouncing on iOS when scrolling */
                .sendbird-conversation__messages {
                  overscroll-behavior: none;
                  -webkit-overflow-scrolling: touch; /* For better iOS scrolling performance */
                }
                
                /* Fix touch targets for better mobile experience */
                .sendbird-button, .sendbird-iconbutton {
                  min-width: 36px;
                  min-height: 36px;
                }
                
                /* Fix channel list height */
                .sendbird-channel-list {
                  height: calc(100vh - 122px) !important;
                }
                
                /* Fix channel list scrolling */
                .sendbird-channel-list__body {
                  height: 100% !important;
                  overscroll-behavior: none;
                  -webkit-overflow-scrolling: touch;
                }
                
                /* Ensure proper content loading */
                .sendbird-place-holder--channel-list, .sendbird-place-holder--messaging {
                  height: 100% !important;
                }
                
                /* Fix for channel header in mobile */
                .channel-header {
                  position: sticky;
                  top: 0;
                  z-index: 10;
                }
                
                /* Enable proper tap highlighting */
                * {
                  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
                }
                
                /* Fix for attachment button UI */
                .sendbird-message-input-wrapper:before {
                  display: none;
                }
                
                /* Improve file upload UI */
                .sendbird-message-input-container {
                  border: none !important;
                  background: transparent !important;
                }
                
                /* Modify header styles to match app theme */
                .sendbird-chat-header {
                  background-color: #f97316 !important;
                  border-bottom: 1px solid #f97316 !important;
                  height: 50px !important;
                }
                
                .sendbird-chat-header__left {
                  padding-left: 12px !important;
                }
                
                .sendbird-chat-header__title, .sendbird-channel-header__title {
                  color: white !important;
                }
                
                /* Improve message bubbles */
                .sendbird-message-bubble {
                  border-radius: 16px !important;
                  padding: 8px 12px !important;
                }
                
                /* Fix scrollbar appearance */
                ::-webkit-scrollbar {
                  width: 0px;
                  background: transparent;
                }
              }
              
              /* Fix settings overlay z-index issues */
              .sendbird-channel-settings,
              .sendbird-channel-settings__header,
              .sendbird-modal__content {
                z-index: 62 !important;
              }
              
              .sendbird-modal__overlay {
                z-index: 63 !important;
              }
              
              /* Ensure settings content is clickable */
              .sendbird-channel-settings__content {
                position: relative;
                z-index: 62;
              }
              
              /* Fix modal positioning */
              .sendbird-modal {
                z-index: 65 !important;
              }
              
              /* Fix modal stacking and interaction issues */
              .sendbird-modal {
                position: fixed !important;
                z-index: 100 !important;
              }
              
              .sendbird-modal__content {
                position: relative;
                z-index: 101 !important;
              }
              
              .sendbird-modal__overlay {
                z-index: 99 !important;
              }
              
              /* Ensure edit channel form stays on top */
              .sendbird-edit-channel-name-modal {
                z-index: 102 !important;
              }
              
              .sendbird-channel-settings {
                position: relative;
                z-index: 70;
              }
              
              .sendbird-channel-settings__content {
                position: relative;
                z-index: inherit;
              }
              
              .sendbird-channel-settings__panel {
                position: relative;
                z-index: 71;
              }
              
              /* Prevent modal background from blocking interactions */
              .sendbird-modal__overlay {
                pointer-events: none;
              }
              
              .sendbird-modal__content {
                pointer-events: auto;
              }
              
              /* Fix any nested modals */
              .sendbird-modal .sendbird-modal {
                z-index: 105 !important;
              }

              /* Reset any Sendbird modal styles that might interfere */
              .sendbird-modal,
              .sendbird-modal__content,
              .sendbird-modal__overlay {
                position: fixed !important;
                z-index: 10001 !important;
              }

              /* Ensure modals are interactive */
              .sendbird-modal__overlay {
                background: rgba(0, 0, 0, 0.5) !important;
              }

              /* Override any Sendbird blocking styles */
              .sendbird-channel-settings,
              .sendbird-channel-settings__content,
              .sendbird-channel-settings__panel,
              .sendbird-edit-channel-name-modal,
              .sendbird-create-channel-type-select-modal,
              .sendbird-message-search-modal {
                position: relative !important;
                z-index: auto !important;
              }

              /* Ensure modal content is clickable */
              .sendbird-modal__content {
                pointer-events: auto !important;
              }

              /* Remove any overlay interference */
              .sendbird-modal__overlay ~ .sendbird-modal__overlay {
                display: none !important;
              }

              /* Fix stacking for nested modals */
              .sendbird-modal .sendbird-modal {
                z-index: 10002 !important;
              }

              /* Ensure proper modal positioning */
              .sendbird-modal {
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
              }

              /* Fix any scroll issues */
              .sendbird-channel-settings {
                height: 100% !important;
                overflow: visible !important;
              }

              /* Ensure proper event handling */
              .sendbird-channel-settings * {
                pointer-events: auto !important;
              }
            `}</style>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBox;