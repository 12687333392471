import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

const Permissions = () => {
  const [activeTab, setActiveTab] = useState('roles');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">Permissions Management</h2>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Manage roles and permissions for users across the application.
        </p>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200 dark:border-gray-700 mb-6">
        <nav className="flex space-x-8">
          <button
            onClick={() => handleTabChange('roles')}
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'roles'
                ? 'border-orange-500 text-orange-600 dark:text-orange-500'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
          >
            Roles
          </button>
          <button
            onClick={() => handleTabChange('permissions')}
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'permissions'
                ? 'border-orange-500 text-orange-600 dark:text-orange-500'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
          >
            Permissions
          </button>
          <button
            onClick={() => handleTabChange('policies')}
            className={`py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'policies'
                ? 'border-orange-500 text-orange-600 dark:text-orange-500'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
            }`}
          >
            Policies
          </button>
        </nav>
      </div>

      {/* Content based on active tab */}
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        {activeTab === 'roles' && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Role Management</h3>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Define roles that can be assigned to users. Each role contains a set of permissions.
            </p>
            <div className="flex justify-center items-center py-12">
              <div className="text-center">
                <div className="bg-gray-100 dark:bg-gray-700 rounded-full p-4 inline-block mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                  </svg>
                </div>
                <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Role Management Coming Soon</h4>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  This feature is currently under development and will be available soon.
                </p>
                <button
                  onClick={() => toast.info('Role management will be available in a future update')}
                  className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-lg"
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'permissions' && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Permission Management</h3>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Define granular permissions that control access to features and data.
            </p>
            <div className="flex justify-center items-center py-12">
              <div className="text-center">
                <div className="bg-gray-100 dark:bg-gray-700 rounded-full p-4 inline-block mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                  </svg>
                </div>
                <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Permission Management Coming Soon</h4>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  This feature is currently under development and will be available soon.
                </p>
                <button
                  onClick={() => toast.info('Permission management will be available in a future update')}
                  className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-lg"
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'policies' && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Policy Management</h3>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Create and manage access policies that combine roles and permissions.
            </p>
            <div className="flex justify-center items-center py-12">
              <div className="text-center">
                <div className="bg-gray-100 dark:bg-gray-700 rounded-full p-4 inline-block mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                </div>
                <h4 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Policy Management Coming Soon</h4>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  This feature is currently under development and will be available soon.
                </p>
                <button
                  onClick={() => toast.info('Policy management will be available in a future update')}
                  className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-lg"
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Permissions; 