import React from "react";
import { useNavigate } from 'react-router-dom';
import { signIn } from 'aws-amplify/auth'
import { fetchAuthSession } from 'aws-amplify/auth'
import Intercom from '@intercom/messenger-js-sdk';
import { fetchUserAttributes, confirmSignIn, resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { getOrgConfig } from '../utils/api';
import { useUserSettingsContext } from '../context/UserSettingsContext';
import App from '../App';
import { toast } from 'react-hot-toast';
import hotelIcon from '../assets/img/hotel--icon.svg';

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [newPasswordRequired, setNewPasswordRequired] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const { fetchSettings } = useUserSettingsContext();
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  
  // Forgot password states
  const [forgotPasswordMode, setForgotPasswordMode] = React.useState(false);
  const [confirmationCode, setConfirmationCode] = React.useState('');
  const [resetPasswordStep, setResetPasswordStep] = React.useState('request'); // 'request', 'verify', 'reset'
  const [resetPasswordError, setResetPasswordError] = React.useState('');

  function login(event) {
    event.preventDefault();
    
    if (newPasswordRequired && newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      toast.error('Passwords do not match');
      return;
    }
  
    signIn({
      username: email,
      password: password
    })
      .then(async (user) => {
        switch (user.nextStep.signInStep) {
          case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
            setNewPasswordRequired(true);
            toast.info('New password required. Please set a new password.');
            break;
          default:
            toast.success('Login successful!');
            break;
        }
        try {
          // First ensure we have a valid session
          await fetchAuthSession();
          // Then fetch user attributes
          const userAttributes = await fetchUserAttributes();
          // Initialize Intercom with user data
          Intercom({
            app_id: 'hae2ajlm',
            user_id: userAttributes.email,
            name: userAttributes.given_name,
            email: userAttributes.email,
          });
          
          // Fetch user settings using email
          await fetchSettings();
          
          console.log('Sign in successful:', user);
          navigate('/dashboard');
        } catch (error) {
          console.error('Error fetching user data:', error);
          setPassword('');
          toast.error('Error fetching user data. Please try again.', { duration: 5000 });
        }
      })
      .catch(error => {
        switch (error.name) {
          case 'UserAlreadyAuthenticatedException':
            // Refresh the access token and then navigate
            (async () => {
              try {
                const session = await fetchAuthSession();
                console.log(session);
                toast.success('Session refreshed! Redirecting...');
                navigate('/dashboard');
              } catch (refreshError) {
                console.error('Error refreshing session:', refreshError);
                setPassword('');
                toast.error('Error refreshing session. Please try again.');
              }
            })();
            break;
          default:
            toast.error(`Error signing in: ${error.message}`);
            console.error('Error signing in:', error);
            break;
        }
      });
  }

  async function handleNewPasswordSubmit(event) {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      toast.error('Passwords do not match'); // Notify user about mismatched passwords
      return;
    }

    try {
      // Step 1: Sign in with the existing password
      const user = await signIn({
        username: email,
        password: password,
      });

      // Step 2: Check if the user is in the 'NEW_PASSWORD_REQUIRED' state
      if (user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        // Step 3: Respond to the challenge by submitting the new password
        await confirmSignIn({
          challengeResponse: newPassword,
        });

        // After successful password change, proceed with the normal login flow
        const session = await fetchAuthSession();
        const userAttributes = await fetchUserAttributes();

        // Initialize Intercom
        Intercom({
          app_id: 'hae2ajlm',
          user_id: userAttributes.email,
          name: userAttributes.given_name,
          email: userAttributes.email,
        });
        
        // Fetch user settings
        await fetchSettings();

        // Redirect to the dashboard
        navigate('/dashboard');
        toast.success('Password updated successfully! Redirecting to dashboard...');
      }
    } catch (error) {
      console.error('Error confirming new password:', error);

      setPasswordError('The new password is invalid. Please follow password guidelines.');
      toast.error(error.message);
    }
  }

  // Function to initiate the forgot password flow
  async function handleForgotPasswordRequest(event) {
    event.preventDefault();
    
    if (!email) {
      setResetPasswordError('Please enter your email address');
      toast.error('Please enter your email address');
      return;
    }
    
    try {
      await resetPassword({ username: email });
      setResetPasswordStep('verify');
      toast.success('Verification code sent to your email');
      setResetPasswordError('');
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setResetPasswordError(error.message);
      toast.error(`Error: ${error.message}`);
    }
  }
  
  // Function to verify the confirmation code
  async function handleVerifyCode(event) {
    event.preventDefault();
    
    if (!confirmationCode) {
      setResetPasswordError('Please enter the verification code');
      toast.error('Please enter the verification code');
      return;
    }
    
    // Move to password reset step if code is provided
    setResetPasswordStep('reset');
    setResetPasswordError('');
  }
  
  // Function to confirm the new password with verification code
  async function handleForgotPasswordConfirm(event) {
    event.preventDefault();
    
    if (!newPassword) {
      setResetPasswordError('Please enter a new password');
      toast.error('Please enter a new password');
      return;
    }
    
    if (newPassword !== confirmPassword) {
      setResetPasswordError('Passwords do not match');
      toast.error('Passwords do not match');
      return;
    }
    
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: confirmationCode,
        newPassword: newPassword
      });
      
      // Reset all states and return to login
      setForgotPasswordMode(false);
      setResetPasswordStep('request');
      setConfirmationCode('');
      setNewPassword('');
      setConfirmPassword('');
      setResetPasswordError('');
      
      toast.success('Password reset successful! You can now log in with your new password.');
    } catch (error) {
      console.error('Error confirming password reset:', error);
      setResetPasswordError(error.message);
      toast.error(`Error: ${error.message}`);
    }
  }
  
  // Function to cancel forgot password flow and return to login
  function cancelForgotPassword() {
    setForgotPasswordMode(false);
    setResetPasswordStep('request');
    setConfirmationCode('');
    setNewPassword('');
    setConfirmPassword('');
    setResetPasswordError('');
  }

  React.useEffect(() => {
    document.body.classList.add("login-page");
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);

  React.useEffect(() => {
    // Set theme colors
    const colors = {
      primary: '#F97316', // orange-500
      primaryHover: '#EA580C', // orange-600
      light: '#FFF7ED', // orange-50
      medium: '#FED7AA', // orange-200
    };

    const root = document.documentElement;
    root.style.setProperty('--color-primary', colors.primary);
    root.style.setProperty('--color-primary-hover', colors.primaryHover);
    root.style.setProperty('--color-light', colors.light);
    root.style.setProperty('--color-medium', colors.medium);
  }, []);

  return (
    <div 
      className="min-h-screen relative overflow-hidden flex items-center justify-center"
      style={{ 
        background: `linear-gradient(135deg, #FFF7ED 0%, #EFF6FF 50%, #DBEAFE 100%)`
      }}
    >
      {/* Animated Blur effect circles */}
      <div 
        className="absolute top-0 left-0 w-96 h-96 rounded-full filter blur-3xl opacity-30 -translate-x-1/2 -translate-y-1/2 animate-pulse"
        style={{ backgroundColor: 'var(--color-medium)' }} 
      />
      <div 
        className="absolute bottom-0 right-0 w-96 h-96 rounded-full filter blur-3xl opacity-30 translate-x-1/2 translate-y-1/2 animate-pulse"
        style={{ backgroundColor: '#93C5FD' }} 
      />

      {/* Login Container */}
      <div className="container mx-auto px-6 py-16 relative">
        <div className="max-w-md mx-auto">
          {/* Logo Section */}
          <div className="text-center mb-8">
            <div className="flex items-center justify-center ">
              <div className="rounded-xl">
                <img 
                  src={hotelIcon}
                  alt="Hotel Icon"
                  className="w-8 h-8"
                  style={{ color: 'var(--color-primary)' }}
                />
              </div>
              <span className="text-2xl font-bold tracking-tight text-slate-800">
                lodgeic
              </span>
            </div>
          </div>

          {/* Main Card */}
          <div className="backdrop-blur-xl bg-white/30 p-8 rounded-3xl shadow-lg border border-white/20 relative overflow-hidden hover:shadow-xl transition-all">
            {/* Background Pattern */}
            <div className="absolute inset-0 opacity-5">
              <div className="absolute inset-0" 
                   style={{ 
                     backgroundImage: 'radial-gradient(circle at 2px 2px, var(--color-primary) 1px, transparent 0)',
                     backgroundSize: '20px 20px'
                   }}>
              </div>
            </div>

            {/* Content */}
            <div className="relative">
              <h1 className="text-3xl font-bold text-slate-800 text-center mb-8">
                {forgotPasswordMode 
                  ? 'Reset Password' 
                  : (newPasswordRequired ? 'Set New Password' : 'Welcome Back')}
              </h1>
              
              {/* Forgot Password Flow */}
              {forgotPasswordMode && (
                <>
                  {resetPasswordStep === 'request' ? (
                    <form onSubmit={handleForgotPasswordRequest} className="space-y-6">
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-slate-700">
                          Email
                        </label>
                        <div className="relative">
                          <input
                            type="email"
                            className="w-full px-4 py-3 rounded-xl bg-white/50 backdrop-blur-sm border border-white/20 text-slate-800 placeholder-slate-500 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-all"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      
                      {resetPasswordError && (
                        <div className="bg-red-50/50 backdrop-blur-sm text-red-500 text-sm p-3 rounded-lg border border-red-200/50">
                          {resetPasswordError}
                        </div>
                      )}
                      
                      <div className="flex space-x-3">
                        <button
                          type="button"
                          onClick={cancelForgotPassword}
                          className="w-1/2 py-3 rounded-xl font-semibold text-slate-700 transition-all border border-slate-300 hover:bg-slate-100"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="w-1/2 py-3 rounded-xl font-semibold text-white transition-all shadow-lg hover:shadow-xl hover:translate-y-[-1px] active:translate-y-0"
                          style={{ 
                            background: `linear-gradient(to right, var(--color-primary), var(--color-primary-hover))`,
                          }}
                        >
                          Send Code
                        </button>
                      </div>
                    </form>
                  ) : resetPasswordStep === 'verify' ? (
                    <form onSubmit={handleVerifyCode} className="space-y-6">
                      {/* Display the email in a disabled field */}
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-slate-700">
                          Email
                        </label>
                        <div className="relative">
                          <input
                            type="email"
                            className="w-full px-4 py-3 rounded-xl bg-white/50 backdrop-blur-sm border border-white/20 text-slate-800 placeholder-slate-500 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-all"
                            value={email}
                            disabled
                          />
                        </div>
                      </div>
                      
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-slate-700">
                          Verification Code
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            className="w-full px-4 py-3 rounded-xl bg-white/50 backdrop-blur-sm border border-white/20 text-slate-800 placeholder-slate-500 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-all"
                            placeholder="Enter verification code"
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                          />
                        </div>
                      </div>
                      
                      {resetPasswordError && (
                        <div className="bg-red-50/50 backdrop-blur-sm text-red-500 text-sm p-3 rounded-lg border border-red-200/50">
                          {resetPasswordError}
                        </div>
                      )}
                      
                      <div className="flex space-x-3">
                        <button
                          type="button"
                          onClick={() => setResetPasswordStep('request')}
                          className="w-1/2 py-3 rounded-xl font-semibold text-slate-700 transition-all border border-slate-300 hover:bg-slate-100"
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="w-1/2 py-3 rounded-xl font-semibold text-white transition-all shadow-lg hover:shadow-xl hover:translate-y-[-1px] active:translate-y-0"
                          style={{ 
                            background: `linear-gradient(to right, var(--color-primary), var(--color-primary-hover))`,
                          }}
                        >
                          Verify Code
                        </button>
                      </div>
                      
                      <div className="text-center mt-2">
                        <button 
                          type="button"
                          onClick={handleForgotPasswordRequest}
                          className="text-sm text-slate-600 hover:text-slate-800 transition-colors underline-offset-4 hover:underline"
                        >
                          Resend code
                        </button>
                      </div>
                    </form>
                  ) : (
                    <form onSubmit={handleForgotPasswordConfirm} className="space-y-6">
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-slate-700">
                          New Password
                        </label>
                        <input
                          type="password"
                          className="w-full px-4 py-3 rounded-xl bg-white/50 backdrop-blur-sm border border-white/20 text-slate-800 placeholder-slate-500 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-all"
                          placeholder="Enter new password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                      
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-slate-700">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="w-full px-4 py-3 rounded-xl bg-white/50 backdrop-blur-sm border border-white/20 text-slate-800 placeholder-slate-500 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-all"
                          placeholder="Confirm new password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                      
                      {resetPasswordError && (
                        <div className="bg-red-50/50 backdrop-blur-sm text-red-500 text-sm p-3 rounded-lg border border-red-200/50">
                          {resetPasswordError}
                        </div>
                      )}
                      
                      <div className="flex space-x-3">
                        <button
                          type="button"
                          onClick={() => setResetPasswordStep('verify')}
                          className="w-1/2 py-3 rounded-xl font-semibold text-slate-700 transition-all border border-slate-300 hover:bg-slate-100"
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="w-1/2 py-3 rounded-xl font-semibold text-white transition-all shadow-lg hover:shadow-xl hover:translate-y-[-1px] active:translate-y-0"
                          style={{ 
                            background: `linear-gradient(to right, var(--color-primary), var(--color-primary-hover))`,
                          }}
                        >
                          Reset Password
                        </button>
                      </div>
                    </form>
                  )}
                </>
              )}
              
              {/* Normal Login Flow */}
              {!forgotPasswordMode && (
                <>
                  <form onSubmit={newPasswordRequired ? handleNewPasswordSubmit : login} className="space-y-6">
                    {/* Email Input */}
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-slate-700">
                        Email
                      </label>
                      <div className="relative">
                        <input
                          type="email"
                          className="w-full px-4 py-3 rounded-xl bg-white/50 backdrop-blur-sm border border-white/20 text-slate-800 placeholder-slate-500 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-all"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Password Input */}
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-slate-700">
                        Password
                      </label>
                      <div className="relative">
                        <input
                          type="password"
                          className="w-full px-4 py-3 rounded-xl bg-white/50 backdrop-blur-sm border border-white/20 text-slate-800 placeholder-slate-500 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-all"
                          placeholder="Enter your password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* New Password Fields */}
                    {newPasswordRequired && (
                      <>
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-slate-700">
                            New Password
                          </label>
                          <input
                            type="password"
                            className="w-full px-4 py-3 rounded-xl bg-white/50 backdrop-blur-sm border border-white/20 text-slate-800 placeholder-slate-500 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-all"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </div>
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-slate-700">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="w-full px-4 py-3 rounded-xl bg-white/50 backdrop-blur-sm border border-white/20 text-slate-800 placeholder-slate-500 focus:ring-2 focus:ring-primary/50 focus:border-primary transition-all"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </>
                    )}

                    {/* Error Message */}
                    {passwordError && (
                      <div className="bg-red-50/50 backdrop-blur-sm text-red-500 text-sm p-3 rounded-lg border border-red-200/50">
                        {passwordError}
                      </div>
                    )}

                    {/* Submit Button */}
                    <button
                      type="submit"
                      className="w-full py-3 rounded-xl font-semibold text-white transition-all shadow-lg hover:shadow-xl hover:translate-y-[-1px] active:translate-y-0"
                      style={{ 
                        background: `linear-gradient(to right, var(--color-primary), var(--color-primary-hover))`,
                      }}
                    >
                      {newPasswordRequired ? 'Set New Password' : 'Log In'}
                    </button>
                  </form>

                  {/* Forgot Password Link */}
                  <div className="text-center mt-6">
                    <button 
                      onClick={() => setForgotPasswordMode(true)}
                      className="text-sm text-slate-600 hover:text-slate-800 transition-colors underline-offset-4 hover:underline"
                    >
                      Forgot your password?
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Additional Info */}
          <div className="text-center mt-8 text-sm text-slate-600">
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
