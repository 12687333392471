import React, { useState } from 'react';
import { Save, MessageCircle, UserPlus } from 'lucide-react';
import { toast } from 'react-hot-toast';
import SendbirdTestRunner from './SendbirdTestRunner';
import SendbirdSingleUserTest from './SendbirdSingleUserTest';

const SettingsAdmin = () => {
  const [formData, setFormData] = useState({
    siteName: 'Lodgeic Admin',
    siteDescription: 'Administration portal for Lodgeic',
    primaryColor: '#f97316', // Orange-500
    logoUrl: '',
    enableDarkMode: true,
    enableNotifications: true,
    sessionTimeout: 30,
    maxLoginAttempts: 5,
    passwordPolicy: {
      minLength: 8,
      requireUppercase: true,
      requireLowercase: true,
      requireNumbers: true,
      requireSpecialChars: true
    }
  });
  
  const [activeTab, setActiveTab] = useState('general');

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handlePasswordPolicyChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    setFormData({
      ...formData,
      passwordPolicy: {
        ...formData.passwordPolicy,
        [name]: type === 'checkbox' ? checked : value
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // In a real application, you would save the settings to a backend
    console.log('Saving settings:', formData);
    toast.success('Settings saved successfully');
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Admin Settings</h1>
      </div>
      
      {/* Tabs */}
      <div className="border-b border-gray-200 mb-6">
        <nav className="flex -mb-px">
          <button
            onClick={() => setActiveTab('general')}
            className={`py-4 px-6 font-medium text-sm ${
              activeTab === 'general'
                ? 'border-b-2 border-orange-500 text-orange-600'
                : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            General Settings
          </button>
          <button
            onClick={() => setActiveTab('sendbird')}
            className={`py-4 px-6 font-medium text-sm flex items-center ${
              activeTab === 'sendbird'
                ? 'border-b-2 border-orange-500 text-orange-600'
                : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <MessageCircle size={16} className="mr-2" />
            Sendbird API Tests
          </button>
          <button
            onClick={() => setActiveTab('sendbirdSingleUser')}
            className={`py-4 px-6 font-medium text-sm flex items-center ${
              activeTab === 'sendbirdSingleUser'
                ? 'border-b-2 border-orange-500 text-orange-600'
                : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <UserPlus size={16} className="mr-2" />
            Test Single User
          </button>
        </nav>
      </div>
      
      {activeTab === 'general' && (
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Site Settings */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-lg font-semibold mb-4">Site Settings</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Site Name
                </label>
                <input
                  type="text"
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Site Description
                </label>
                <input
                  type="text"
                  name="siteDescription"
                  value={formData.siteDescription}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Primary Color
                </label>
                <div className="flex items-center">
                  <input
                    type="color"
                    name="primaryColor"
                    value={formData.primaryColor}
                    onChange={handleInputChange}
                    className="w-10 h-10 border border-gray-300 rounded-md mr-2"
                  />
                  <input
                    type="text"
                    name="primaryColor"
                    value={formData.primaryColor}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Logo URL
                </label>
                <input
                  type="text"
                  name="logoUrl"
                  value={formData.logoUrl}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>

          {/* Feature Settings */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-lg font-semibold mb-4">Feature Settings</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="enableDarkMode"
                  name="enableDarkMode"
                  checked={formData.enableDarkMode}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-orange-600 border-gray-300 rounded"
                />
                <label htmlFor="enableDarkMode" className="ml-2 block text-sm text-gray-700">
                  Enable Dark Mode
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="enableNotifications"
                  name="enableNotifications"
                  checked={formData.enableNotifications}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-orange-600 border-gray-300 rounded"
                />
                <label htmlFor="enableNotifications" className="ml-2 block text-sm text-gray-700">
                  Enable Notifications
                </label>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Session Timeout (minutes)
                </label>
                <input
                  type="number"
                  name="sessionTimeout"
                  value={formData.sessionTimeout}
                  onChange={handleInputChange}
                  min="1"
                  max="120"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Max Login Attempts
                </label>
                <input
                  type="number"
                  name="maxLoginAttempts"
                  value={formData.maxLoginAttempts}
                  onChange={handleInputChange}
                  min="1"
                  max="10"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>

          {/* Password Policy */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-lg font-semibold mb-4">Password Policy</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Minimum Length
                </label>
                <input
                  type="number"
                  name="minLength"
                  value={formData.passwordPolicy.minLength}
                  onChange={handlePasswordPolicyChange}
                  min="6"
                  max="20"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="requireUppercase"
                  name="requireUppercase"
                  checked={formData.passwordPolicy.requireUppercase}
                  onChange={handlePasswordPolicyChange}
                  className="h-4 w-4 text-orange-600 border-gray-300 rounded"
                />
                <label htmlFor="requireUppercase" className="ml-2 block text-sm text-gray-700">
                  Require Uppercase Letters
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="requireLowercase"
                  name="requireLowercase"
                  checked={formData.passwordPolicy.requireLowercase}
                  onChange={handlePasswordPolicyChange}
                  className="h-4 w-4 text-orange-600 border-gray-300 rounded"
                />
                <label htmlFor="requireLowercase" className="ml-2 block text-sm text-gray-700">
                  Require Lowercase Letters
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="requireNumbers"
                  name="requireNumbers"
                  checked={formData.passwordPolicy.requireNumbers}
                  onChange={handlePasswordPolicyChange}
                  className="h-4 w-4 text-orange-600 border-gray-300 rounded"
                />
                <label htmlFor="requireNumbers" className="ml-2 block text-sm text-gray-700">
                  Require Numbers
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="requireSpecialChars"
                  name="requireSpecialChars"
                  checked={formData.passwordPolicy.requireSpecialChars}
                  onChange={handlePasswordPolicyChange}
                  className="h-4 w-4 text-orange-600 border-gray-300 rounded"
                />
                <label htmlFor="requireSpecialChars" className="ml-2 block text-sm text-gray-700">
                  Require Special Characters
                </label>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
            >
              <Save size={16} className="mr-2" />
              Save Settings
            </button>
          </div>
        </form>
      )}
      
      {activeTab === 'sendbird' && <SendbirdTestRunner />}
      {activeTab === 'sendbirdSingleUser' && <SendbirdSingleUserTest />}
    </div>
  );
};

export default SettingsAdmin; 