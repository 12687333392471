import React from 'react';
import { X, ChevronDown, ChevronRight } from 'lucide-react';

const SlideUpMenu = ({ isOpen, onClose, reports, onReportClick, activeReportId, openItems, onToggleItem }) => {
  if (!isOpen) return null;

  return (
    <>
      {/* Backdrop */}
      <div 
        className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40"
        onClick={onClose}
      />
      
      {/* Menu */}
      <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 rounded-t-2xl z-50 max-h-[80vh] overflow-y-auto">
        <div className="sticky top-0 flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
          <h2 className="text-lg font-semibold">Reports</h2>
          <button onClick={onClose} className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg">
            <X size={20} />
          </button>
        </div>

        <div className="p-4 space-y-2">
          {reports.map((report) => (
            <div key={report.id} className="space-y-1">
              <button
                onClick={() => onToggleItem(`report_${report.id}`)}
                className={`w-full flex items-center justify-between p-3 rounded-lg ${
                  activeReportId === report.id ? 'bg-orange-50 dark:bg-orange-900/20 text-orange-500' : ''
                }`}
              >
                <span>{report.name}</span>
                {report.pages?.length > 0 && (
                  openItems[`report_${report.id}`] ? <ChevronDown size={20} /> : <ChevronRight size={20} />
                )}
              </button>
              
              {openItems[`report_${report.id}`] && report.pages?.length > 0 && (
                <div className="ml-4 space-y-1">
                  {report.pages.map((page) => (
                    <button
                      key={page.name}
                      onClick={() => onReportClick(report, page)}
                      className="w-full p-2 text-left text-sm rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      {page.displayName}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SlideUpMenu; 