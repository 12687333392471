import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsconfig from './utils/awsConfig';
import { Toaster } from 'react-hot-toast';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import MainLayout from './components/Layout/MainLayout';
import Dashboard from './components/Dashboard';
import Report from './components/Report';
import { LoadingProvider } from './context/LoadingContext';
import { UserSettingsProvider } from './context/UserSettingsContext';
import { SendbirdProvider } from './context/SendbirdContext';
import './assets/css/chat.css';
import Settings from './components/Settings';
import SettingsLayout from './components/Settings/SettingsLayout';
import EmailSettings from './components/Settings/EmailSettings';
import NotificationSettings from './components/Settings/NotificationSettings';
import PrivacySettings from './components/Settings/PrivacySettings';
import TeamSettings from './components/Settings/TeamSettings';
import BillingSettings from './components/Settings/BillingSettings';
import WorkspaceSettings from './components/Settings/WorkspaceSettings';
import DeviceSettings from './components/Settings/DeviceSettings';
import KeyboardSettings from './components/Settings/KeyboardSettings';
import AppearanceSettings from './components/Settings/AppearanceSettings';
import MobileLayout from './components/Layout/MobileLayout';
import AdminLayout from './components/Admin/AdminLayout';
import UserManagement from './components/Admin/UserManagement';
import Organizations from './components/Admin/Organizations';
import Reports from './components/Admin/Reports';
import Permissions from './components/Admin/Permissions';
import SettingsAdmin from './components/Admin/Settings';
import SendbirdTest from './components/Chat/SendbirdTest';
import SendbirdDebug from './components/Chat/SendbirdDebug';
import TestSendbird from './pages/TestSendbird';
import Chat from './pages/Chat';

const AUTH = Amplify.configure(awsconfig);

function App() {
  const location = useLocation();
  const isMobile = window.innerWidth <= 768;
  const isLoginPage = location.pathname === '/login';

  return (
    <LoadingProvider>
      <UserSettingsProvider>
        <SendbirdProvider>
          <Routes>
            {/* Public route */}
            <Route path="/login" element={<Login />} />

            {/* Admin routes */}
            <Route path="/admin" element={<AdminProtectedRoute><AdminLayout /></AdminProtectedRoute>}>
              <Route index element={<Navigate to="/admin/users" replace />} />
              <Route path="users" element={<UserManagement />} />
              <Route path="organizations" element={<Organizations />} />
              <Route path="reports" element={<Reports />} />
              <Route path="permissions" element={<Permissions />} />
              <Route path="settings" element={<SettingsAdmin />} />
            </Route>

            {/* Test routes for Sendbird */}
            <Route path="/test/sendbird" element={<ProtectedRoute><SendbirdTest /></ProtectedRoute>} />
            <Route path="/debug/sendbird" element={<ProtectedRoute><SendbirdDebug /></ProtectedRoute>} />
            <Route path="/test/sendbird-connection" element={<TestSendbird />} />

            {/* Protected routes with different layouts for mobile/desktop */}
            <Route element={<ProtectedRoute>
              {isMobile ? <MobileLayout /> : <MainLayout />}
            </ProtectedRoute>}>
              <Route index element={<Navigate to="/dashboard" replace />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="dashboard/:dashboardId" element={<Dashboard />} />
              <Route path="report/:reportId" element={<Report />} />
              <Route path="report/:reportId/page/:pageName" element={<Report />} />
              <Route path="chat" element={<Chat />} />
              
              {/* Settings Routes */}
              <Route path="settings" element={<SettingsLayout />}>
                <Route index element={<Navigate to="/settings/email" replace />} />
                <Route path="email" element={<EmailSettings />} />
                <Route path="notifications" element={<NotificationSettings />} />
                <Route path="privacy" element={<PrivacySettings />} />
                <Route path="appearance" element={<AppearanceSettings />} />
                <Route path="team" element={<TeamSettings />} />
                <Route path="billing" element={<BillingSettings />} />
                <Route path="workspace" element={<WorkspaceSettings />} />
                <Route path="devices" element={<DeviceSettings />} />
                <Route path="shortcuts" element={<KeyboardSettings />} />
              </Route>
            </Route>

            {/* Catch all redirects to dashboard */}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>

          <Toaster position="top-right" />
        </SendbirdProvider>
      </UserSettingsProvider>
    </LoadingProvider>
  );
}

export default App; 