import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Edit, Trash2, Search, ArrowLeft, RefreshCw, Eye, EyeOff, X, UserCheck } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { 
  getAllUsers, 
  saveUserSettings, 
  deleteUserSettings, 
  restoreUserSettings,
  syncAllUsersWithSendbird,
  getOrgConfig,
  getReports,
  getDashboards
} from '../../utils/api';
import UserForm from './UserForm';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [reports, setReports] = useState({});
  const [dashboards, setDashboards] = useState({});
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const navigate = useNavigate();

  // Define fetchData outside of useEffect so it can be reused
  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Fetch all users
      const usersData = await getAllUsers();
      // Double-check that usersData is an array
      if (!Array.isArray(usersData)) {
        console.error('getAllUsers did not return an array:', usersData);
        setUsers([]);
      } else {
        setUsers(usersData);
      }
      
      // Fetch organization config
      const orgConfig = await getOrgConfig();
      setOrganizations(Array.isArray(orgConfig) ? orgConfig : []);
      
      // Fetch reports and dashboards for each organization
      const reportsData = {};
      const dashboardsData = {};
      
      if (Array.isArray(orgConfig)) {
        for (const org of orgConfig) {
          try {
            if (org.workspaceId) {
              const reportsResponse = await getReports(org.workspaceId);
              reportsData[org.orgId] = reportsResponse.value || reportsResponse;
              
              const dashboardsResponse = await getDashboards(org.workspaceId);
              dashboardsData[org.orgId] = dashboardsResponse.value || dashboardsResponse;
            }
          } catch (error) {
            console.error(`Error fetching data for org ${org.orgId}:`, error);
          }
        }
      } else {
        console.error('orgConfig is not an array:', orgConfig);
      }
      
      setReports(reportsData);
      setDashboards(dashboardsData);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load data');
      // Ensure users is at least an empty array on error
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddUser = () => {
    setEditingUser(null);
    setShowAddUserForm(true);
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setShowAddUserForm(true);
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to mark this user as deleted? This will disable their Cognito account and prevent them from logging in.')) {
      try {
        const updatedUser = await deleteUserSettings(userId);
        // Update the user in the list instead of removing it
        setUsers(users.map(user => user.email === userId ? updatedUser : user));
        
        // Check if Cognito account was disabled successfully
        if (updatedUser.cognitoDisabled) {
          toast.success('User marked as deleted and their account access has been disabled');
        } else if (updatedUser.cognitoDisableError) {
          toast.success('User marked as deleted, but there was an issue disabling their account access');
          console.error('Cognito disable error:', updatedUser.cognitoDisableError);
        } else {
          toast.success('User marked as deleted');
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        toast.error('Failed to delete user');
      }
    }
  };

  const handleRestoreUser = async (userId) => {
    if (window.confirm('Are you sure you want to restore this user? This will re-enable their Cognito account and send them a new temporary password via email.')) {
      try {
        const restoredUser = await restoreUserSettings(userId);
        setUsers(users.map(user => user.email === userId ? restoredUser : user));
        
        // Check if Cognito account was enabled successfully and welcome email was sent
        if (restoredUser.cognitoEnabled && restoredUser.welcomeEmailSent) {
          toast.success('User restored successfully. A new temporary password has been sent to their email.');
        } else if (restoredUser.cognitoEnabled && !restoredUser.welcomeEmailSent) {
          toast.success('User restored and account access re-enabled, but there was an issue sending the welcome email');
          console.error('Email error:', restoredUser.emailError);
        } else if (!restoredUser.cognitoEnabled) {
          toast.success('User restored, but there was an issue re-enabling their account access');
          console.error('Cognito enable error:', restoredUser.cognitoEnableError);
        } else {
          toast.success('User restored successfully');
        }
      } catch (error) {
        console.error('Error restoring user:', error);
        toast.error('Failed to restore user');
      }
    }
  };

  const handleSaveUser = async (userData) => {
    try {
      console.log('Saving user data:', JSON.stringify(userData, null, 2));
      
      // Validate the orgAccess structure before saving
      Object.keys(userData.orgAccess || {}).forEach(orgId => {
        const org = userData.orgAccess[orgId];
        console.log(`Validating org ${orgId}:`, org);
        
        // Check if the required properties exist
        if (!org.reportIds) console.warn(`Missing reportIds for org ${orgId}`);
        if (!org.dashboardIds) console.warn(`Missing dashboardIds for org ${orgId}`);
        if (!org.reports) console.warn(`Missing reports for org ${orgId}`);
        if (!org.dashboards) console.warn(`Missing dashboards for org ${orgId}`);
      });
      
      const savedUser = await saveUserSettings(userData);
      
      if (editingUser) {
        setUsers(users.map(user => user.email === userData.email ? savedUser : user));
        toast.success('User updated successfully');
      } else {
        setUsers([...users, savedUser]);
        toast.success('User added successfully');
      }
      
      setShowAddUserForm(false);
      setEditingUser(null);
    } catch (error) {
      console.error('Error saving user:', error);
      toast.error('Failed to save user');
    }
  };

  // Add a function to sync all users with Sendbird
  const handleSyncAllUsers = async () => {
    try {
      setIsSyncing(true);
      await syncAllUsersWithSendbird();
      toast.success('All users synced with Sendbird successfully');
    } catch (error) {
      console.error('Error syncing users with Sendbird:', error);
      toast.error('Failed to sync users with Sendbird');
    } finally {
      setIsSyncing(false);
    }
  };

  // Filter users based on search term and deleted status
  const filteredUsers = Array.isArray(users) 
    ? users.filter(user => 
        (showDeletedUsers || user.isDeleted !== 1) && // Only show deleted users if showDeletedUsers is true
        (user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    : [];

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">User Management</h2>
        <div className="flex space-x-3">
          <button
            onClick={() => navigate('/dashboard')}
            className="bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300 px-4 py-2 rounded-lg flex items-center"
          >
            <ArrowLeft size={18} className="mr-2" />
            Back to Dashboard
          </button>
          <button
            onClick={handleSyncAllUsers}
            disabled={isSyncing}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            {isSyncing ? <RefreshCw className="h-4 w-4 animate-spin" /> : <UserCheck className="h-4 w-4" />}
            Sync Users with Chat
          </button>
          <button
            onClick={handleAddUser}
            className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
          >
            <Plus className="h-4 w-4" />
            Add User
          </button>
        </div>
      </div>

      {/* Search and Filters */}
      <div className="mb-6 flex flex-wrap gap-4 items-center">
        <div className="relative flex-grow">
          <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 dark:bg-gray-700 dark:text-white"
          />
        </div>
        
        <button
          onClick={() => setShowDeletedUsers(!showDeletedUsers)}
          className={`px-4 py-2 rounded-lg flex items-center ${
            showDeletedUsers 
              ? 'bg-blue-500 hover:bg-blue-600 text-white' 
              : 'bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300'
          }`}
        >
          {showDeletedUsers ? (
            <>
              <EyeOff size={18} className="mr-2" />
              Hide Deleted
            </>
          ) : (
            <>
              <Eye size={18} className="mr-2" />
              Show Deleted
            </>
          )}
        </button>
        
        <button
          onClick={fetchData}
          className="bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300 px-4 py-2 rounded-lg flex items-center"
        >
          <RefreshCw size={18} className="mr-2" />
          Refresh
        </button>
      </div>

      {/* User List */}
      {isLoading ? (
        <div className="text-center py-8">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-orange-500 mx-auto"></div>
          <p className="mt-4 text-gray-600 dark:text-gray-400">Loading users...</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Organizations</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Role</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {filteredUsers.length === 0 ? (
                <tr>
                  <td colSpan="6" className="px-6 py-4 text-center text-gray-500 dark:text-gray-400">
                    No users found
                  </td>
                </tr>
              ) : (
                filteredUsers.map((user) => (
                  <tr key={user.email} className={`hover:bg-gray-50 dark:hover:bg-gray-700 ${user.isDeleted === 1 ? 'bg-red-50 dark:bg-red-900/20' : ''}`}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{user.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                      {user.firstName} {user.lastName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                      {user.orgAccess ? Object.keys(user.orgAccess).join(', ') : '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                      {user.isAdmin === 1 ? (
                        <span className="px-2 py-1 text-xs font-medium rounded-full bg-orange-100 text-orange-800 dark:bg-orange-800 dark:text-orange-100">
                          Admin
                        </span>
                      ) : (
                        <span className="px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                          User
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                      {user.isDeleted === 1 ? (
                        <span className="px-2 py-1 text-xs font-medium rounded-full bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100">
                          Deleted
                        </span>
                      ) : (
                        <span className="px-2 py-1 text-xs font-medium rounded-full bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100">
                          Active
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                      {user.isDeleted === 1 ? (
                        <button
                          onClick={() => handleRestoreUser(user.email)}
                          className="text-green-600 hover:text-green-800 dark:text-green-400 dark:hover:text-green-300"
                          title="Restore User"
                        >
                          <RefreshCw size={18} />
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() => handleEditUser(user)}
                            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 mr-3"
                            title="Edit User"
                          >
                            <Edit size={18} />
                          </button>
                          <button
                            onClick={() => handleDeleteUser(user.email)}
                            className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                            title="Delete User"
                          >
                            <Trash2 size={18} />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Add/Edit User Form Modal */}
      {showAddUserForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center border-b border-gray-200 dark:border-gray-700 px-6 py-4">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {editingUser ? 'Edit User' : 'Add New User'}
              </h3>
              <button
                onClick={() => {
                  setShowAddUserForm(false);
                  setEditingUser(null);
                }}
                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
              >
                <X size={24} />
              </button>
            </div>
            <div className="p-6">
              <UserForm
                user={editingUser}
                organizations={organizations}
                reports={reports}
                dashboards={dashboards}
                onSave={handleSaveUser}
                onCancel={() => {
                  setShowAddUserForm(false);
                  setEditingUser(null);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement; 