import React, { useState } from 'react';
import { Share } from 'lucide-react';
import { saveReportFilterState, createFilterShareMessage } from '../../utils/pbiFilterShare';
import { useSendbird } from '../../context/SendbirdContext';

/**
 * Button component for sharing Power BI report filters via Sendbird chat
 */
const ReportFilterShare = ({ reportInstance, reportName }) => {
  const [isSharing, setIsSharing] = useState(false);
  const [shareError, setShareError] = useState(null);
  const { chatClient } = useSendbird();

  // Handle click to share filters
  const handleShareFilters = async () => {
    if (isSharing) return;
    
    try {
      setIsSharing(true);
      setShareError(null);
      
      // Check if chat client is connected
      if (!chatClient || chatClient.connectionState !== 'OPEN') {
        throw new Error('Chat connection not available');
      }
      
      // Get the report ID
      const reportId = reportInstance.getId();
      if (!reportId) {
        throw new Error('Could not get report ID');
      }
      
      // Save the current filter state
      const response = await saveReportFilterState(reportInstance);
      
      if (!response || !response.stateId) {
        throw new Error('Failed to save filter state');
      }
      
      const stateId = response.stateId;
      
      // Extract filter state if available in response or use API response to count filters
      let filterState = response.filterState;
      console.log('Filter state in response:', filterState);
      
      // Count the filters
      const filterCount = {
        report: 0,
        page: 0,
        visual: 0,
        total: 0
      };
      
      if (filterState) {
        filterCount.report = filterState.reportFilters?.length || 0;
        filterCount.page = filterState.pageFilters?.length || 0;
        filterCount.visual = filterState.visualFilters?.length || 0;
        filterCount.total = filterCount.report + filterCount.page + filterCount.visual;
      }
      
      console.log('Filter counts:', filterCount);
      
      // Create a custom message for each active channel
      await createShareModal(stateId, reportId, filterCount);
      
    } catch (error) {
      console.error('Error sharing filters:', error);
      setShareError(error.message || 'Failed to share filters');
      
      // Auto-dismiss error after 5 seconds
      setTimeout(() => {
        setShareError(null);
      }, 5000);
    } finally {
      setIsSharing(false);
    }
  };
  
  // Function to create a modal to select which channel to share with
  const createShareModal = async (stateId, reportId, filterCount) => {
    // First get the channel list
    try {
      console.log(`Creating share modal for stateId: ${stateId}, reportId: ${reportId}`);
      
      const query = chatClient.groupChannel.createMyGroupChannelListQuery();
      query.includeEmpty = true;
      query.limit = 100;
      
      const channels = await query.next();
      
      // Open a popover or modal to select channels
      const modal = document.createElement('div');
      modal.className = 'fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50';
      modal.innerHTML = `
        <div class="bg-white rounded-xl p-6 shadow-2xl max-w-md w-full mx-4 animate-fadeIn">
          <div class="flex items-center justify-between mb-4">
            <h3 class="text-xl font-semibold text-gray-800">Share Report View</h3>
            <button id="close-modal" class="text-gray-500 hover:text-gray-700 transition-colors focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          
          <div class="mb-5">
            <div class="flex items-center mb-2">
              <span class="w-10 h-10 rounded-full bg-orange-100 flex items-center justify-center mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#f97316" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                  <polyline points="16 6 12 2 8 6"></polyline>
                  <line x1="12" y1="2" x2="12" y2="15"></line>
                </svg>
              </span>
              <div>
                <p class="text-gray-800 font-medium">${reportName || 'Report'}</p>
                ${filterCount.total > 0 ? 
                  `<p class="text-sm text-gray-600">
                    Sharing ${filterCount.total} filter${filterCount.total !== 1 ? 's' : ''} 
                    ${filterCount.visual > 0 ? `with ${filterCount.visual} slicer selection${filterCount.visual !== 1 ? 's' : ''}` : ''}
                  </p>` :
                  `<p class="text-sm text-gray-600">Share your current view with others</p>`
                }
              </div>
            </div>
          </div>
          
          <div class="mb-5">
            <label class="block text-sm font-medium text-gray-700 mb-2">Select a conversation</label>
            <div class="relative">
              <div class="bg-gray-50 border border-gray-200 rounded-lg overflow-hidden">
                <div class="divide-y divide-gray-200 max-h-60 overflow-y-auto custom-scrollbar" id="channel-list">
                  ${channels.length > 0 ? 
                    channels.map(channel => `
                      <button data-channel-url="${channel.url}" class="w-full text-left px-4 py-3 hover:bg-gray-100 transition-colors flex items-center">
                        <div class="flex-1">
                          <p class="font-medium text-gray-800">${channel.name || 'Unnamed Chat'}</p>
                          <p class="text-xs text-gray-500">${channel.memberCount} member${channel.memberCount !== 1 ? 's' : ''}</p>
                        </div>
                        <svg class="text-gray-400 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </button>
                    `).join('') : 
                    `<div class="px-4 py-3 text-center text-gray-500">No conversations available</div>`
                  }
                </div>
              </div>
            </div>
          </div>
          
          <div class="flex justify-end space-x-3">
            <button id="cancel-share" class="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
              Cancel
            </button>
          </div>
        </div>
      `;
      
      // Add styles for custom scrollbar and animation
      const styleElement = document.createElement('style');
      styleElement.textContent = `
        .custom-scrollbar::-webkit-scrollbar {
          width: 6px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background-color: rgba(243, 244, 246, 1);
          border-radius: 10px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: rgba(209, 213, 219, 1);
          border-radius: 10px;
        }
        .animate-fadeIn {
          animation: fadeIn 0.3s ease-out;
        }
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
      `;
      document.head.appendChild(styleElement);
      
      document.body.appendChild(modal);
      
      // Handle channel selection
      const channelList = modal.querySelector('#channel-list');
      channelList.addEventListener('click', async (e) => {
        const channelButton = e.target.closest('[data-channel-url]');
        if (channelButton) {
          const channelUrl = channelButton.dataset.channelUrl;
          
          // Find the selected channel
          const selectedChannel = channels.find(c => c.url === channelUrl);
          if (!selectedChannel) return;
          
          // Show loading state on the button
          channelButton.innerHTML = `
            <div class="flex-1">
              <p class="font-medium text-gray-800">Sharing to ${selectedChannel.name || 'Unnamed Chat'}...</p>
            </div>
            <svg class="animate-spin -ml-1 mr-2 h-4 w-4 text-orange-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          `;
          
          try {
            const channel = await chatClient.groupChannel.getChannel(channelUrl);
            
            // Create a custom message for sharing filters
            const params = createFilterShareMessage(stateId, reportName, reportId, filterCount);
            
            // Add a user-friendly text for non-customized clients
            params.message = `I'm sharing a view of the ${reportName || 'Power BI report'} with current filters.`;
            
            console.log('Sending filter share message with params:', params);
            
            // Send the message
            const sentMessage = await channel.sendUserMessage(params);
            console.log('Message sent successfully:', sentMessage);
            
            // Show success message
            const successEl = document.createElement('div');
            successEl.className = 'fixed top-6 right-6 bg-white border-l-4 border-green-500 shadow-lg rounded-r-lg p-4 flex items-start z-50 animate-fadeIn';
            successEl.innerHTML = `
              <div class="mr-3 text-green-500 mt-0.5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg>
              </div>
              <div>
                <h3 class="font-medium text-gray-800">Shared successfully</h3>
                <p class="text-sm text-gray-600">
                  Shared with ${selectedChannel.name || 'Unnamed Chat'}
                </p>
              </div>
            `;
            document.body.appendChild(successEl);
            
            // Remove success message after 3 seconds
            setTimeout(() => {
              successEl.style.animation = 'fadeOut 0.3s ease-out forwards';
              setTimeout(() => {
                if (document.body.contains(successEl)) {
                  document.body.removeChild(successEl);
                }
              }, 300);
            }, 3000);
            
            // Clean up
            document.body.removeChild(modal);
            document.head.removeChild(styleElement);
          } catch (error) {
            console.error('Error sending filter share message:', error);
            
            // Show error state on the button
            channelButton.innerHTML = `
              <div class="flex-1">
                <p class="font-medium text-gray-800">${selectedChannel.name || 'Unnamed Chat'}</p>
                <p class="text-xs text-red-500">Failed to share. Please try again.</p>
              </div>
              <svg class="text-red-500 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="12"></line>
                <line x1="12" y1="16" x2="12.01" y2="16"></line>
              </svg>
            `;
            
            // Reset after a moment
            setTimeout(() => {
              channelButton.innerHTML = `
                <div class="flex-1">
                  <p class="font-medium text-gray-800">${selectedChannel.name || 'Unnamed Chat'}</p>
                  <p class="text-xs text-gray-500">${selectedChannel.memberCount} member${selectedChannel.memberCount !== 1 ? 's' : ''}</p>
                </div>
                <svg class="text-gray-400 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              `;
            }, 3000);
          }
        }
      });
      
      // Handle close and cancel buttons
      const closeButton = modal.querySelector('#close-modal');
      const cancelButton = modal.querySelector('#cancel-share');
      
      const closeModal = () => {
        modal.style.animation = 'fadeOut 0.2s ease-out forwards';
        setTimeout(() => {
          document.body.removeChild(modal);
          document.head.removeChild(styleElement);
        }, 200);
      };
      
      closeButton.addEventListener('click', closeModal);
      cancelButton.addEventListener('click', closeModal);
      
      // Add fade-out animation
      styleElement.textContent += `
        @keyframes fadeOut {
          from { opacity: 1; transform: translateY(0); }
          to { opacity: 0; transform: translateY(10px); }
        }
      `;
      
    } catch (error) {
      console.error('Error creating share modal:', error);
      setShareError('Failed to load chat channels');
    }
  };

  return (
    <button
      onClick={handleShareFilters}
      disabled={isSharing}
      className="flex items-center justify-center h-8 px-3 bg-white/90 hover:bg-white text-gray-700 rounded-full shadow-sm hover:shadow transition-all border border-gray-200 group"
      title="Share this view with others"
      aria-label="Share filters"
    >
      <Share size={15} className="mr-1.5 text-orange-500 group-hover:text-orange-600" />
      <span className="text-sm font-medium">Share</span>
      {isSharing && (
        <span className="ml-1.5 animate-spin h-3 w-3">
          <svg className="text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </span>
      )}
      
      {shareError && (
        <div className="absolute top-full right-0 mt-2 text-xs bg-red-50 text-red-600 px-2 py-1 rounded shadow-sm whitespace-nowrap">
          {shareError}
        </div>
      )}
    </button>
  );
};

export default ReportFilterShare; 