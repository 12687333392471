import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as pbi from 'powerbi-client';
import { getDashboards, getOrgConfig, getEmbedToken } from "../utils/api";
import { useContainerSize } from "../hooks/useContainerSize";
import { useLoading } from "../context/LoadingContext";
import { useUserSettingsContext } from "../context/UserSettingsContext";

import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer 
} from 'recharts';
import { 
  Calendar, 
  ChevronDown, 
  Home,
  BarChart2,
  Users,
  Settings,
  Menu,
  X,
  Bell,
  Search,
  HelpCircle
} from 'lucide-react';

const getReportIdFromUrl = (embedUrl) => {
  try {
    const url = new URL(embedUrl);
    const reportId = url.searchParams.get('reportId');
    return reportId;
  } catch (error) {
    console.error('Error parsing report URL:', error);
    return null;
  }
};

const roomData = [
  { date: 'Nov 2024', revenue: 24000 },
  { date: 'Dec 2024', revenue: 25000 },
  { date: 'Jan 2025', revenue: 23000 }
];

const payrollData = [
  { date: 'Nov 03', expense: 2000 },
  { date: 'Nov 17', expense: 2500 },
  { date: 'Dec 01', expense: 1800 },
  { date: 'Dec 15', expense: 2200 }
];

const Sidebar = ({ isOpen, toggleSidebar }) => (
  <div className={`fixed left-0 top-0 h-full bg-white/80 backdrop-blur-sm border-r border-gray-200/50 transition-all duration-300 ${isOpen ? 'w-64' : 'w-20'}`}>
    {/* Logo */}
    <div className="h-16 flex items-center justify-between px-4 border-b border-gray-200">
      {isOpen && <span className="text-xl font-semibold text-gray-800">lodgeic</span>}
      <button onClick={toggleSidebar} className="p-2 hover:bg-gray-100 rounded-lg">
        {isOpen ? <X size={20} /> : <Menu size={20} />}
      </button>
    </div>
    
    {/* Navigation Items */}
    <nav className="p-4 space-y-2">
      <NavItem icon={Home} label="Dashboard" isOpen={isOpen} active={false} />
      <NavItem icon={BarChart2} label="Operations" isOpen={isOpen} active={true} />
      <NavItem icon={Users} label="Staff" isOpen={isOpen} active={false} />
      <NavItem icon={Settings} label="Settings" isOpen={isOpen} active={false} />
    </nav>
  </div>
);

const NavItem = ({ icon: Icon, label, isOpen, active }) => (
  <div className={`flex items-center p-3 rounded-lg cursor-pointer ${active ? 'bg-orange-50 text-orange-500' : 'hover:bg-gray-100'}`}>
    <Icon size={20} />
    {isOpen && <span className="ml-3">{label}</span>}
  </div>
);

const Navbar = () => (
  <div className="h-16 bg-white/80 backdrop-blur-sm border-b border-gray-200/50 flex items-center justify-between px-6">
    <div className="flex items-center flex-1">
      <div className="max-w-md w-full">
        <div className="relative">
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400 dark:text-gray-500" />
          <input
            type="text"
            placeholder="Search..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 dark:bg-gray-700/50 dark:text-gray-200 dark:placeholder-gray-400 rounded-lg focus:outline-none focus:border-orange-500"
          />
        </div>
      </div>
    </div>
    
    <div className="flex items-center space-x-4">
      <button className="p-2 hover:bg-gray-100 rounded-lg">
        <HelpCircle size={20} className="text-gray-600" />
      </button>
      <button className="p-2 hover:bg-gray-100 rounded-lg relative">
        <Bell size={20} className="text-gray-600" />
        <span className="absolute top-1.5 right-1.5 w-2 h-2 bg-orange-500 rounded-full"></span>
      </button>
      <div className="h-8 w-8 bg-gray-200 rounded-full"></div>
    </div>
  </div>
);

function Dashboard() {
  const { startLoading, stopLoading } = useLoading();
  console.log('Dashboard component rendering');
  const { dashboardId } = useParams();
  const navigate = useNavigate();
  const [workspaceId, setWorkspaceId] = useState(null);
  const [error, setError] = useState(null);
  const { height, width } = useContainerSize();
  const [isLoaded, setIsLoaded] = useState(false);
  const { userSettings } = useUserSettingsContext();

  useEffect(() => {
    const loadId = 'dashboard-load';
    startLoading(loadId);
    
    async function fetchWorkspaceId() {
      try {
        // Get the current organization ID and workspaceId from localStorage
        const currentOrgId = localStorage.getItem('currentOrgId');
        let workspaceId = localStorage.getItem('currentOrgWorkspaceId');
        
        if (!currentOrgId || !userSettings || !userSettings.orgAccess) {
          console.error('No current organization ID or user settings available');
          stopLoading(loadId);
          setError("No organization selected or user settings not available");
          return;
        }
        
        // Check if the current org exists in the user's orgAccess
        if (!userSettings.orgAccess[currentOrgId]) {
          console.error('Current organization not found in user access');
          stopLoading(loadId);
          setError("Current organization not found in user access");
          return;
        }
        
        // Get the organization's access details
        const orgAccess = userSettings.orgAccess[currentOrgId];
        
        // Check if the user has access to dashboards
        if (!orgAccess.dashboardIds || orgAccess.dashboardIds.length === 0) {
          console.error('No dashboards available for this organization');
          stopLoading(loadId);
          setError("No dashboards available for this organization");
          return;
        }
        
        // If workspaceId is not in localStorage, fetch it from orgConfig
        if (!workspaceId) {
          const orgConfig = await getOrgConfig();
          
          // Find the matching organization in orgConfig using orgId field
          const selectedOrgConfig = orgConfig.find(config => config.orgId === currentOrgId);
          
          if (!selectedOrgConfig) {
            console.error(`Organization ${currentOrgId} not found in orgConfig`);
            stopLoading(loadId);
            setError(`Organization ${currentOrgId} not found in configuration`);
            return;
          }
          
          workspaceId = selectedOrgConfig.workspaceId;
          
          // Store the workspaceId for future use
          localStorage.setItem('currentOrgWorkspaceId', workspaceId);
        }
        
        setWorkspaceId(workspaceId);
      } catch (error) {
        console.error("Error in initial setup:", error);
        setError("Failed to fetch workspace ID");
        stopLoading(loadId);
      }
    }
    
    fetchWorkspaceId();
    
    return () => stopLoading(loadId);
  }, [userSettings]);

  useEffect(() => {
    if (isLoaded) {
      stopLoading('dashboard-load');
    }
  }, [isLoaded]);

  useEffect(() => {
    async function fetchDashboard() {
      if (!workspaceId) return;

      try {
        const dashboards = await getDashboards(workspaceId);
        const dashboard = dashboardId 
          ? dashboards.value.find(d => d.id === dashboardId)
          : dashboards.value[0];
        
        if (!dashboard) {
          throw new Error(dashboardId 
            ? `Dashboard with ID ${dashboardId} not found`
            : 'No dashboards available');
        }

        const embedToken = await getEmbedToken(dashboard.id, workspaceId, {
          embedDetails: { accessLevel: 'View' },
          reportId: dashboard.id,
          workspaceId: workspaceId,
          type: 'dashboards'
        });

        const config = {
          type: 'dashboard',
          tokenType: pbi.models.TokenType.Embed,
          accessToken: embedToken,
          embedUrl: dashboard.embedUrl,
          id: dashboard.id,
          permissions: pbi.models.Permissions.All,
          ...(width <= 768 && { pageView: "oneColumn" }),
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: false,
            background: pbi.models.BackgroundType.Transparent,
          }
        };

        const reportContainer = document.getElementById('reportContainer');
        if (reportContainer) {
          const powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
          );

          const dashboardEmbed = powerbi.embed(reportContainer, config);

          dashboardEmbed.on('tileClicked', (event) => {
            const reportId = getReportIdFromUrl(event.detail.reportEmbedUrl);
            if (reportId) {
              navigate(`/report/${reportId}`);
            }
          });
        }
        setIsLoaded(true);
      } catch (error) {
        console.error("Error embedding Power BI dashboard:", error);
        setError(error.message);
      }
    }

    fetchDashboard();
  }, [workspaceId, dashboardId, navigate, height, width]);

  if (error) {
    return (
      <div className="tw-p-6">
        <div className="tw-bg-red-50 tw-border tw-border-red-200 tw-rounded-xl tw-p-4">
          <h3 className="tw-text-red-800 tw-font-semibold">Error loading dashboard</h3>
          <p className="tw-text-red-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div 
      id="reportContainer" 
      className="report-container report-content"
      style={{ 
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        visibility: isLoaded ? 'visible' : 'hidden'
      }}
    />
  );
}

export default Dashboard;