import React, { useState, useEffect } from 'react';
import { useLocation, Link, Outlet, Navigate } from 'react-router-dom';
import { Users, Building, FileText, Shield, Settings, ArrowLeft, Menu, X } from 'lucide-react';
import { useUserSettingsContext } from '../../context/UserSettingsContext';

const AdminLayout = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { userSettings } = useUserSettingsContext();
  
  // Check if user is admin
  const isAdmin = userSettings && userSettings.isAdmin === 1;
  
  // Check if device is mobile
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (mobile) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call on initial render
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // If not admin, redirect to dashboard
  if (!isAdmin) {
    return <Navigate to="/dashboard" replace />;
  }

  const navItems = [
    {
      label: 'User Management',
      icon: Users,
      to: '/admin/users',
      active: location.pathname === '/admin/users'
    },
    {
      label: 'Organizations',
      icon: Building,
      to: '/admin/organizations',
      active: location.pathname === '/admin/organizations'
    },
    {
      label: 'Reports',
      icon: FileText,
      to: '/admin/reports',
      active: location.pathname === '/admin/reports'
    },
    {
      label: 'Permissions',
      icon: Shield,
      to: '/admin/permissions',
      active: location.pathname === '/admin/permissions'
    },
    {
      label: 'Settings',
      icon: Settings,
      to: '/admin/settings',
      active: location.pathname === '/admin/settings'
    }
  ];

  // Mobile navigation component
  const MobileNavigation = () => (
    <>
      {/* Mobile Header */}
      <div className="fixed top-0 left-0 right-0 h-16 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between px-4 z-30">
        <div className="flex items-center">
          <button
            onClick={() => setMobileMenuOpen(true)}
            className="p-2 rounded-md text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <Menu size={24} />
          </button>
          <h1 className="ml-3 text-lg font-semibold text-gray-800 dark:text-white">Admin Console</h1>
        </div>
        <Link
          to="/dashboard"
          className="flex items-center text-gray-600 dark:text-gray-300 hover:text-orange-500 dark:hover:text-orange-400"
        >
          <ArrowLeft size={20} className="mr-1" />
          <span>Dashboard</span>
        </Link>
      </div>

      {/* Mobile Sidebar Overlay */}
      {mobileMenuOpen && (
        <div className="fixed inset-0 bg-black/50 z-40" onClick={() => setMobileMenuOpen(false)} />
      )}

      {/* Mobile Sidebar */}
      <div className={`fixed top-0 left-0 bottom-0 w-64 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 z-50 transform transition-transform duration-300 ${mobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}>
        <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
          <h2 className="font-semibold text-xl text-gray-800 dark:text-white">Admin Console</h2>
          <button
            onClick={() => setMobileMenuOpen(false)}
            className="p-2 rounded-md text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <X size={20} />
          </button>
        </div>
        
        {/* Back to Dashboard Link */}
        <Link
          to="/dashboard"
          className="flex items-center px-4 py-3 text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 border-b border-gray-200 dark:border-gray-700"
          onClick={() => setMobileMenuOpen(false)}
        >
          <ArrowLeft size={20} className="flex-shrink-0" />
          <span className="ml-3">Back to Dashboard</span>
        </Link>
        
        <nav className="mt-4">
          {navItems.map((item) => (
            <Link
              key={item.label}
              to={item.to}
              className={`flex items-center px-4 py-3 ${
                item.active
                  ? 'bg-orange-50 text-orange-600 border-r-2 border-orange-500 dark:bg-gray-700 dark:text-orange-400'
                  : 'text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700'
              }`}
              onClick={() => setMobileMenuOpen(false)}
            >
              <item.icon size={20} className="flex-shrink-0" />
              <span className="ml-3">{item.label}</span>
            </Link>
          ))}
        </nav>
      </div>
    </>
  );

  return (
    <div className="flex h-full">
      {isMobile ? (
        <>
          <MobileNavigation />
          {/* Mobile Main Content */}
          <div className="flex-1 overflow-auto bg-gray-50 dark:bg-gray-900 pt-16">
            <div className="p-4">
              {/* Breadcrumb navigation for mobile */}
              <div className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                {location.pathname !== '/admin' && location.pathname !== '/admin/' && (
                  <span className="text-gray-700 dark:text-gray-300 font-medium">
                    {location.pathname.split('/').pop().charAt(0).toUpperCase() + location.pathname.split('/').pop().slice(1)}
                  </span>
                )}
              </div>
              
              <Outlet />
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Desktop Sidebar */}
          <div className={`bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 ${isOpen ? 'w-64' : 'w-20'} transition-all duration-300 flex-shrink-0`}>
            <div className="p-4">
              <h2 className={`font-semibold text-gray-800 dark:text-white ${isOpen ? 'text-xl' : 'text-center text-sm'}`}>
                {isOpen ? 'Admin Console' : 'Admin'}
              </h2>
              <p className={`text-sm text-gray-500 dark:text-gray-400 mt-1 ${isOpen ? '' : 'hidden'}`}>
                Manage your application
              </p>
            </div>
            
            {/* Back to Dashboard Link */}
            <Link
              to="/dashboard"
              className="flex items-center px-4 py-3 text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 border-b border-gray-200 dark:border-gray-700"
            >
              <ArrowLeft size={20} className="flex-shrink-0" />
              {isOpen && <span className="ml-3">Back to Dashboard</span>}
            </Link>
            
            <nav className="mt-4">
              {navItems.map((item) => (
                <Link
                  key={item.label}
                  to={item.to}
                  className={`flex items-center px-4 py-3 ${
                    item.active
                      ? 'bg-orange-50 text-orange-600 border-r-2 border-orange-500 dark:bg-gray-700 dark:text-orange-400'
                      : 'text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700'
                  }`}
                >
                  <item.icon size={20} className="flex-shrink-0" />
                  {isOpen && <span className="ml-3">{item.label}</span>}
                </Link>
              ))}
            </nav>
            
            {/* Toggle Sidebar Button */}
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="absolute bottom-4 left-0 right-0 mx-auto w-8 h-8 flex items-center justify-center bg-gray-200 dark:bg-gray-700 rounded-full text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
            >
              {isOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              )}
            </button>
          </div>
          
          {/* Desktop Main content */}
          <div className="flex-1 overflow-auto bg-gray-50 dark:bg-gray-900">
            <div className="p-6">
              {/* Breadcrumb navigation */}
              <div className="mb-6 flex items-center text-sm text-gray-500 dark:text-gray-400">
                <Link to="/dashboard" className="hover:text-orange-500 dark:hover:text-orange-400">Dashboard</Link>
                <span className="mx-2">/</span>
                <Link to="/admin" className="hover:text-orange-500 dark:hover:text-orange-400">Admin</Link>
                {location.pathname !== '/admin' && location.pathname !== '/admin/' && (
                  <>
                    <span className="mx-2">/</span>
                    <span className="text-gray-700 dark:text-gray-300">
                      {location.pathname.split('/').pop().charAt(0).toUpperCase() + location.pathname.split('/').pop().slice(1)}
                    </span>
                  </>
                )}
              </div>
              
              <Outlet />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminLayout; 