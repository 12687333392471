import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import MobileNavigation from './MobileNavigation';
import TopLoadingBar from '../TopLoadingBar';
import { getReports, getOrgConfig, getReportPages } from '../../utils/api';
import { useUserSettingsContext } from '../../context/UserSettingsContext';
import { useSendbird } from '../../context/SendbirdContext';
import { Search, MessageCircle } from 'lucide-react';
import ChatBox from '../Chat/ChatBox';

const MainLayout = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const location = useLocation();
  const isMobile = window.innerWidth <= 768;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();
  const { userSettings, organizations } = useUserSettingsContext();
  const { initializeChat } = useSendbird();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [chatInitialized, setChatInitialized] = useState(false);

  // Get current user's ID for chat (preferring Cognito ID if available)
  const getUserIdForChat = useCallback(() => {
    // First try to get the cognitoId from userSettings if available
    if (userSettings) {
      // If the user has a cognitoId in their settings, use that
      if (userSettings.cognitoId) {
        console.log('Using cognitoId from userSettings:', userSettings.cognitoId);
        return userSettings.cognitoId;
      }
      
      // If they have cognitoUsername but no cognitoId, use that
      if (userSettings.cognitoUsername) {
        console.log('Using cognitoUsername from userSettings:', userSettings.cognitoUsername);
        return userSettings.cognitoUsername;
      }
      
      // Fallback to email
      if (userSettings.email) {
        console.log('Falling back to email from userSettings:', userSettings.email);
        return userSettings.email;
      }
    }
    
    // Find user ID from local storage as fallback
    const userPoolId = localStorage.getItem('amplify-signin-with-webui');
    if (userPoolId) {
      const userData = JSON.parse(localStorage.getItem(userPoolId));
      if (userData && userData.username) {
        console.log('Using username from localStorage:', userData.username);
        return userData.username;
      }
    }
    
    console.log('No user ID found for chat');
    return null;
  }, [userSettings]);
  
  // Memoize the userId to prevent recalculation on every render
  const userId = useMemo(() => getUserIdForChat(), [getUserIdForChat]);
  
  // Initialize chat connection when component mounts
  useEffect(() => {
    if (userId && !chatInitialized) {
      console.log('[MainLayout] Initializing chat connection on app load');
      initializeChat(userId);
      setChatInitialized(true);
    }
  }, [userId, initializeChat, chatInitialized]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the current organization ID and workspaceId from localStorage
        const currentOrgId = localStorage.getItem('currentOrgId');
        let workspaceId = localStorage.getItem('currentOrgWorkspaceId');
        
        if (!currentOrgId || !userSettings || !userSettings.orgAccess) {
          console.error('No current organization ID or user settings available');
          return;
        }
        
        // Check if the current org exists in the user's orgAccess
        if (!userSettings.orgAccess[currentOrgId]) {
          console.error('Current organization not found in user access');
          return;
        }
        
        // Get the organization's access details
        const orgAccess = userSettings.orgAccess[currentOrgId];
        
        // Get reports for this organization
        const reportIds = Object.keys(orgAccess.reportIds || {});
        
        if (reportIds.length === 0) {
          console.log('No reports available for this organization');
          setReports([]);
          return;
        }
        
        // If workspaceId is not in localStorage, fetch it from orgConfig
        if (!workspaceId) {
          const orgConfig = await getOrgConfig();
          
          // Find the matching organization in orgConfig using orgId field
          const selectedOrgConfig = orgConfig.find(config => config.orgId === currentOrgId);
          
          if (!selectedOrgConfig) {
            console.error(`Organization ${currentOrgId} not found in orgConfig`);
            return;
          }
          
          workspaceId = selectedOrgConfig.workspaceId;
          
          // Store the workspaceId for future use
          localStorage.setItem('currentOrgWorkspaceId', workspaceId);
        }
        
        const reportsResponse = await getReports(workspaceId);
        
        // Filter reports by the ones the user has access to
        const filteredReports = reportsResponse.value.filter(report => 
          reportIds.includes(report.id)
        );
        
        const reportsWithPages = await Promise.all(
          filteredReports.map(async (report) => {
            try {
              const pagesResponse = await getReportPages(report.id, workspaceId);
              return {
                ...report,
                pages: pagesResponse.value
              };
            } catch (error) {
              console.error(`Error fetching pages for report ${report.id}:`, error);
              return {
                ...report,
                pages: []
              };
            }
          })
        );
        setReports(reportsWithPages);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    fetchData();
  }, [userSettings]);

  useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [location.pathname]);

  const handleSearch = (value) => {
    setSearchTerm(value);
    if (!value.trim()) {
      setSearchResults([]);
      setShowResults(false);
      return;
    }

    const results = reports.flatMap(report => {
      const reportMatch = report.name.toLowerCase().includes(value.toLowerCase())
        ? [{
            id: report.id,
            type: 'report',
            name: report.name,
            path: `/report/${report.id}/page/${report.pages[0]?.name}`
          }]
        : [];

      const pageMatches = report.pages
        .filter(page => page.displayName.toLowerCase().includes(value.toLowerCase()))
        .map(page => ({
          id: `${report.id}-${page.name}`,
          type: 'page',
          name: page.displayName,
          reportName: report.name,
          path: `/report/${report.id}/page/${page.name}`
        }));

      return [...reportMatch, ...pageMatches];
    });

    setSearchResults(results);
    setShowResults(true);
  };

  // Toggle chat window with debounce
  const toggleChat = useCallback(() => {
    if (!isButtonDisabled) {
      setIsChatOpen(!isChatOpen);
      
      // Disable button temporarily to prevent rapid clicks
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 1000);
    }
  }, [isChatOpen, isButtonDisabled]);

  // Adjust main content when chat is open
  const mainContentClasses = `
    pt-16 overflow-auto transition-all duration-300
    ${isMobile ? 'ml-0 pb-20' : isSidebarCollapsed ? 'ml-20' : 'ml-64'}
    ${isChatOpen ? 'mr-[40%] lg:mr-[35%] xl:mr-[30%]' : 'mr-0'}
  `;

  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 via-blue-50 to-blue-100 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900/90">
      <TopLoadingBar isLoading={isLoading} />
      
      {/* Hide sidebar and regular navbar on mobile */}
      <div className="hidden md:block">
        <Sidebar 
          isCollapsed={isSidebarCollapsed} 
          onToggle={() => setIsSidebarCollapsed(!isSidebarCollapsed)} 
        />
        <Navbar 
          isSidebarCollapsed={isSidebarCollapsed} 
          toggleChat={toggleChat}
        />
      </div>

      {/* Mobile header with search */}
      <div className="md:hidden fixed top-0 left-0 right-0 z-20">
        <div className="h-16 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between px-4">
          <div className="flex items-center">
            <span className="text-xl font-semibold">lodgeic</span>
            <div className="flex-1 max-w-xs ml-4">
              <div className="relative">
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  onFocus={() => setShowResults(true)}
                  placeholder="Search..."
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:border-orange-500 dark:bg-gray-700 dark:text-white text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <main className={mainContentClasses}>
        <div className="h-full">
          <Outlet />
        </div>
      </main>

      {isMobile && <MobileNavigation reports={reports} />}

      {/* Search Results Overlay */}
      {showResults && searchResults.length > 0 && (
        <div className="md:hidden fixed inset-x-0 top-28 mx-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700">
          {searchResults.map((result) => (
            <button
              key={result.id}
              onClick={() => {
                navigate(result.path);
                setShowResults(false);
                setSearchTerm('');
              }}
              className="w-full text-left p-3 hover:bg-gray-100 dark:hover:bg-gray-700 border-b border-gray-200 dark:border-gray-700 last:border-0"
            >
              <div className="text-sm font-medium text-gray-900 dark:text-white">
                {result.name}
              </div>
              {result.type === 'page' && (
                <div className="text-xs text-gray-500 dark:text-gray-400">
                  {result.reportName}
                </div>
              )}
            </button>
          ))}
        </div>
      )}
      
      {/* Chat button */}
      {userId && (
        <>
          {/* Only show floating button on mobile */}
          {isMobile && (
            <button
              className={`fixed right-6 bottom-20 bg-blue-600 text-white rounded-full w-12 h-12 flex items-center justify-center shadow-lg transition-all z-40 ${
                isButtonDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
              }`}
              onClick={toggleChat}
              disabled={isButtonDisabled}
            >
              <MessageCircle size={24} />
            </button>
          )}
          
          {/* Chat component */}
          <ChatBox isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} userId={userId} />
        </>
      )}
    </div>
  );
};

export default MainLayout;