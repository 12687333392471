import axios from 'axios';
import API_ENDPOINTS, { BASE_URL } from '../config/apiConfig';
import api from './api';  // Import the pre-configured API instance with interceptors

// Replace with a function that gets the public key from the server
let publicVapidKey = null;

// Helper function to get auth token
const getAuthToken = () => {
  // Check if using Cognito auth
  try {
    // Find the access token using the same method as api.js
    const accessTokenKey = Object.keys(localStorage)
      .find(key => key.endsWith('.accessToken'));
    
    if (accessTokenKey) {
      const token = localStorage.getItem(accessTokenKey);
      if (token) {
        return `Bearer ${token}`;
      }
    }
    
    // Fallbacks for various token storage patterns
    // Try id_token first (preferred for user identity)
    const idToken = localStorage.getItem('id_token');
    if (idToken) {
      return `Bearer ${idToken}`;
    }
    
    // Try access_token as fallback
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      return `Bearer ${accessToken}`;
    }
    
    // Look for other common token storage patterns
    const jwtToken = localStorage.getItem('jwt_token');
    if (jwtToken) {
      return `Bearer ${jwtToken}`;
    }
    
    // Look for token in sessionStorage as well
    const sessionIdToken = sessionStorage.getItem('id_token');
    if (sessionIdToken) {
      return `Bearer ${sessionIdToken}`;
    }
    
    return null;
  } catch (e) {
    console.error('Error retrieving auth token:', e);
    return null;
  }
};

// Get the public VAPID key from the server
export const getPublicVapidKey = async () => {
  if (publicVapidKey) {
    return publicVapidKey;
  }
  
  try {
    const response = await api.get(`${BASE_URL}/push/key`);
    if (response.data && response.data.publicKey) {
      publicVapidKey = response.data.publicKey;
      return publicVapidKey;
    }
    
    // Fallback to environment variable if server doesn't provide it
    return process.env.REACT_APP_VAPID_PUBLIC_KEY;
  } catch (error) {
    console.error('Error fetching VAPID key:', error);
    // Fallback to environment variable
    return process.env.REACT_APP_VAPID_PUBLIC_KEY;
  }
};

// Check if the browser supports service workers and Push API
export const isPushNotificationSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

// Register the service worker
export const registerServiceWorker = async () => {
  if (!isPushNotificationSupported()) {
    console.warn('Push notifications are not supported in this browser');
    return false;
  }

  try {
    // Check if service worker is already registered and active
    const registrations = await navigator.serviceWorker.getRegistrations();
    const existingServiceWorker = registrations.find(reg => 
      reg.scope.includes(window.location.origin) && reg.active
    );
    
    if (existingServiceWorker) {
      console.log('Using existing service worker registration');
      return existingServiceWorker;
    }
    
    // Register new service worker
    console.log('Registering new service worker');
    const registration = await navigator.serviceWorker.register('/service-worker.js', {
      scope: '/'
    });
    
    // Wait for the service worker to be ready if it's installing
    if (registration.installing) {
      console.log('Service worker installing');
      return new Promise((resolve) => {
        registration.installing.addEventListener('statechange', (event) => {
          if (event.target.state === 'activated') {
            console.log('Service worker activated');
            resolve(registration);
          }
        });
        
        // Fallback in case the event listener doesn't fire
        setTimeout(() => {
          console.log('Service worker registration timeout - resolving anyway');
          resolve(registration);
        }, 3000);
      });
    }
    
    return registration;
  } catch (error) {
    console.error('Service Worker registration failed:', error);
    return false;
  }
};

// Request permission and subscribe to push notifications
export const subscribeToPushNotifications = async () => {
  if (!isPushNotificationSupported()) {
    console.warn('Push notifications are not supported in this browser');
    return false;
  }

  try {
    // Request permission
    const permission = await Notification.requestPermission();
    
    if (permission !== 'granted') {
      console.warn('Notification permission not granted');
      return false;
    }

    // Get the service worker registration
    const registration = await navigator.serviceWorker.ready;
    
    if (!registration) {
      console.error('Service worker not ready');
      return false;
    }
    
    // Get the public key
    const applicationServerKey = await getPublicVapidKey();
    
    if (!applicationServerKey) {
      console.error('No VAPID public key available');
      return false;
    }
    
    // Convert VAPID key to Uint8Array for applicationServerKey
    const applicationServerKeyArray = urlBase64ToUint8Array(applicationServerKey);

    // Subscribe to push notifications
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKeyArray
    });

    // Send the subscription to your server
    await sendSubscriptionToServer(subscription);
    return true;
  } catch (error) {
    console.error('Error subscribing to push notifications:', error);
    return false;
  }
};

// Check if user is already subscribed
export const checkPushSubscription = async () => {
  if (!isPushNotificationSupported()) {
    return false;
  }

  try {
    // Check for existing registrations
    const registrations = await navigator.serviceWorker.getRegistrations();
    if (registrations.length === 0) {
      return false;
    }
    
    // Wait for service worker to be ready
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    
    return !!subscription;
  } catch (error) {
    console.error('Error checking subscription:', error);
    return false;
  }
};

// Unsubscribe from push notifications
export const unsubscribeFromPushNotifications = async () => {
  if (!isPushNotificationSupported()) {
    return false;
  }

  try {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    
    if (!subscription) {
      console.log('No active subscription found to unsubscribe');
      return true; // Already unsubscribed
    }
    
    // Remove subscription from server first
    try {
      await removeSubscriptionFromServer(subscription);
    } catch (serverError) {
      console.error('Failed to remove subscription from server:', serverError);
      // Continue with local unsubscribe even if server removal fails
      console.log('Continuing with local unsubscribe despite server error');
    }
    
    // Then unsubscribe locally
    try {
      const result = await subscription.unsubscribe();
      return result;
    } catch (unsubError) {
      console.error('Error unsubscribing locally:', unsubError);
      
      // If we can't unsubscribe locally, it might be an invalid subscription
      // Force clear it if possible
      try {
        // Try to get all registrations and clear subscriptions
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const reg of registrations) {
          const subs = await reg.pushManager.getSubscription();
          if (subs) {
            await subs.unsubscribe();
          }
        }
        return true;
      } catch (clearError) {
        console.error('Failed to force clear subscriptions:', clearError);
        throw new Error('Could not unsubscribe. Please try resetting the service worker.');
      }
    }
  } catch (error) {
    console.error('Error in unsubscribeFromPushNotifications:', error);
    throw error;
  }
};

// Send a test notification
export const sendTestNotification = async () => {
  try {
    const response = await api.post(`${BASE_URL}/push/test`, {});
    return response.data;
  } catch (error) {
    console.error('Error sending test notification:', error);
    
    // Check for specific error responses from the server
    if (error.response) {
      if (error.response.status === 404 && error.response.data?.error === 'No active subscriptions found') {
        throw new Error('No active subscriptions found. Please try re-enabling notifications.');
      }
      if (error.response.status === 401) {
        throw new Error('Authentication error. Please refresh the page and try again.');
      }
    }
    
    throw error;
  }
};

// Utility to convert base64 string to Uint8Array
// (required for applicationServerKey)
const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  
  return outputArray;
};

// Send subscription to server
const sendSubscriptionToServer = async (subscription) => {
  try {
    const response = await api.post(`${BASE_URL}/push/subscribe`, {
      subscription: subscription
    }, {
      timeout: 10000 // 10 second timeout
    });
    
    return response.data;
  } catch (error) {
    console.error('Error sending subscription to server:', error);
    
    if (error.response) {
      console.error('Response status:', error.response.status);
      console.error('Response data:', error.response.data);
      
      if (error.response.status === 429) {
        throw new Error('Too many subscriptions. Please try again later or remove inactive subscriptions.');
      }
      if (error.response.status === 401) {
        throw new Error('Authentication error. Please refresh the page and try again.');
      }
    } else if (error.request) {
      console.error('No response received:', error.request);
      throw new Error('No response from server. Please check your network connection.');
    } else {
      console.error('Request setup error:', error.message);
    }
    
    throw error;
  }
};

// Remove subscription from server
const removeSubscriptionFromServer = async (subscription) => {
  try {
    const response = await api.post(`${BASE_URL}/push/unsubscribe`, {
      subscription: subscription
    });
    
    return response.data;
  } catch (error) {
    console.error('Error removing subscription from server:', error);
    
    // If we get a 404, the subscription was already removed
    if (error.response && error.response.status === 404) {
      console.log('Subscription was already removed from the server');
      return { success: true, message: 'Subscription already removed' };
    }
    
    // If we get a 500 with "entity with specified id does not exist", it's actually a success case
    if (error.response && 
        error.response.status === 500 && 
        error.response.data?.details?.includes('Entity with the specified id does not exist')) {
      console.log('Subscription does not exist on server (CosmosDB 404)');
      return { success: true, message: 'Subscription not found on server' };
    }
    
    throw error;
  }
};

// Reset service worker for troubleshooting
export const resetServiceWorker = async () => {
  try {
    // Unregister all service workers
    const registrations = await navigator.serviceWorker.getRegistrations();
    
    for (const registration of registrations) {
      await registration.unregister();
    }
    
    // Clear service worker caches
    const cacheNames = await caches.keys();
    for (const cacheName of cacheNames) {
      await caches.delete(cacheName);
    }
    
    // Reset stored public key
    publicVapidKey = null;
    
    // Reload the page to complete the reset
    window.location.reload(true);
    return true;
  } catch (error) {
    console.error('Failed to reset service worker:', error);
    return false;
  }
}; 